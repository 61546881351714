import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Reportdefinitions from './reportdefinitions/Reportdefinitions';
import Reportstatuses from './reportstatuses/Reportstatuses';

export default function Reports({
  citysettingdefinitions,
  reportdefinitions,
  reports,
  reportstatuses,
  onSetReportdefinitions,
  onSetReportstatuses,
}) {
  return (
    <Routes>
      <Route
        path="definitions/*"
        element={(
          <Reportdefinitions
            citysettingdefinitions={citysettingdefinitions}
            reportdefinitions={reportdefinitions}
            reports={reports}
            onSetReportdefinitions={onSetReportdefinitions}
          />
        )}
      />
      <Route
        path="statuses/*"
        element={(
          <Reportstatuses
            reports={reports}
            reportstatuses={reportstatuses}
            onSetReportstatuses={onSetReportstatuses}
          />
        )}
      />
      <Route path="*" element={<Navigate to="definitions" />} />
    </Routes>
  );
}

Reports.propTypes = {
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  reportdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  reports: PropTypes.arrayOf(PropTypes.shape({})),
  reportstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  onSetReportdefinitions: PropTypes.func.isRequired,
  onSetReportstatuses: PropTypes.func.isRequired,
};

Reports.defaultProps = {
  citysettingdefinitions: undefined,
  reportdefinitions: undefined,
  reports: undefined,
  reportstatuses: undefined,
};

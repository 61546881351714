export default function OfficialtypesTableRowsMapper(local, officialtypes) {
  let rows;
  if (officialtypes) {
    rows = officialtypes.map((row) => ({
      id: row.id,
      name: row.name,
    }));
  }
  return rows;
}

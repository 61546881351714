export default function ServicestatusesTableRowsMapper(local, servicestatuses) {
  let rows;
  if (servicestatuses) {
    rows = servicestatuses.map((row) => ({
      id: row.id,
      name: row.name,
    }));
  }
  return rows;
}

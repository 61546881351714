export default {
  de: {
    alternate: 'Ersatzmitglied',
    cashier: 'Kassier*in',
    cityCouncil: 'Stadtrat*rätin',
    council: 'Gemeinderat*rätin',
    councilBoard: 'Gemeindevorstand',
    executiveCouncil: 'Geschäftsführende*r Gemeinderat*rätin',
    fraction: 'Fraktionsvorsitzende*r',
    mayor: 'Bürgermeister*in',
    peoplesmayor: 'Volksbürgermeister*in',
    title: 'Gemeinderat',
    vicemayor: 'Vizebürgermeister*in',
    vicemayor1: '1. Vizebürgermeister*in',
    vicemayor2: '2. Vizebürgermeister*in',
  },
};

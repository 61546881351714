export default function AgreementstatusesTableRowsMapper(local, logs, cities, districts) {
  let rows;
  if (logs && cities && districts) {
    rows = logs.map((row) => {
      const currentCity = cities.find((city) => city.id === row.city);
      const currentDistrict = currentCity ? districts.find((district) => district.id === currentCity.district) : undefined;
      return {
        id: row.id,
        district: currentDistrict ? currentDistrict.name : '',
        city: currentCity ? currentCity.name : '',
        service: row.service,
        level: row.level,
        createdAt: row.createdAt,
        message: row.message,
      };
    });
  }
  return rows;
}

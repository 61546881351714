export default {
  de: {
    agreements: 'Ziele',
    cities: 'Gemeinden',
    districts: 'Bezirke',
    elections: 'Wahlen',
    electionresults: 'Wahl Ergebnisse',
    info: 'Info',
    messages: ' Notizen',
    officials: 'Funktionäre',
    reports: 'Berichte',
    services: 'Leistungen',
    swing: 'Swing-Index',
    timeline: 'Historie',
    files: 'Dateien',
    calendar: 'Kalender',
  },
};

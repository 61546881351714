export default {
  de: {
    group: 'Parteien gruppieren',
    year: 'Jahr',
    citizens: 'Einwohner',
    members: 'Mitglieder',
    membersAverageAge: 'Mitglieder Ø-Alter',
    officials: 'SPÖ Gemeinderäte*rätinnen',
    officialsMale: 'SPÖ Gemeinderäte',
    officialsFemale: 'SPÖ Gemeinderätin',
    officialsAverageAge: 'SPÖ Gemeinderat*rätin Ø-Alter',
  },
};

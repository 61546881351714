export default function AgreementcategoriesTableRowsMapper(local, agreementcategories, agreements) {
  let rows;
  if (agreementcategories && agreements) {
    rows = agreementcategories.map((row) => {
      let activeAgreements = 0;
      let inactiveAgreements = 0;
      agreements.forEach((item) => {
        if (item.category === row.id) {
          if (item.active) {
            activeAgreements += 1;
          } else {
            inactiveAgreements += 1;
          }
        }
      });
      return {
        id: row.id,
        name: row.name,
        description: row.description,
        editor: row.editor,
        active: row.active,
        updatedAt: row.updatedAt,
        activeAgreements,
        inactiveAgreements,
      };
    });
  }
  return rows;
}

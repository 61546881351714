import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
// import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import GavelIcon from '@mui/icons-material/Gavel';
import PollIcon from '@mui/icons-material/Poll';
import DescriptionIcon from '@mui/icons-material/Description';

import { LocalizationContext } from '../../../AppContext';
import localization from './SettingsMenu.local';

export default function SettingsMenu({ sx }) {
  const local = localization[useContext(LocalizationContext)];
  const location = useLocation().pathname.split('/');
  const navigate = useNavigate();

  const onChange = (e, value) => navigate(`/settings/${value}`);

  return (
    <Paper sx={sx}>
      <Tabs
        scrollButtons
        allowScrollButtonsMobile
        value={location[2] || 'users'}
        onChange={onChange}
        variant="scrollable"
        indicatorColor="primary"
        textColor="primary"
        sx={{ width: '100%' }}
      >
        <Tab value="users" label={local.users} icon={<GroupIcon />} />
        <Tab value="citysettingdefinitions" label={local.citysettingdefinitions} icon={<HomeIcon />} />
        <Tab value="citysettings" label={local.citysettings} icon={<HomeIcon />} />
        <Tab value="reports" label={local.reports} icon={<AssignmentIcon />} />
        <Tab value="agreements" label={local.agreements} icon={<AssignmentTurnedInIcon />} />
        {/* <Tab value="services" label={local.services} icon={<EventAvailableIcon />} /> */}
        <Tab value="officials" label={local.officials} icon={<GavelIcon />} />
        <Tab value="elections" label={local.elections} icon={<PollIcon />} />
        <Tab value="logs" label={local.logs} icon={<DescriptionIcon />} />
      </Tabs>
    </Paper>
  );
}

SettingsMenu.propTypes = {
  sx: PropTypes.shape({}),
};

SettingsMenu.defaultProps = {
  sx: undefined,
};

export default {
  de: {
    title: 'Datei bearbeiten',
    name: 'Name',
    category: 'Kategorie',
    description: 'Beschreibung',
    createdAt: 'Erstellt am ',
    from: 'von',
    updatedAt: 'Zuletzt verändert am ',
    destroy: 'Löschen',
    back: 'Zurück',
    destroyTitle: 'Möchten Sie das Ziel wirklich löschen?',
    destroyDescription: '',
    agree: 'Löschen',
    download: 'Download',
    disagree: 'Abbrechen',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

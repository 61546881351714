import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext } from '../../../../AppContext';
import CustomToolbar from '../../../../components/CustomToolbar';
import ColumnDefinition from './DistrictsColumnDefinition';
import RowsMapper from './DistrictsRowsMapper';
import localization from './Districts.local';

export default function Districts({
  cities,
  districts,
  elections,
  officials,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setColumns(ColumnDefinition(local));
  }, []);
  useEffect(() => {
    if (cities && districts && elections && officials) {
      setRows(RowsMapper(local, cities, districts, elections, officials));
    }
  }, [cities, districts, elections, officials]);

  return (
    <Paper sx={{ height: '100%' }}>
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%', width: '100%' }}
          rows={rows}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          onRowClick={(row) => navigate(`/${row.id}/-`)}
        />
      )}
    </Paper>
  );
}

Districts.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  officials: PropTypes.arrayOf(PropTypes.shape({})),
};

Districts.defaultProps = {
  cities: undefined,
  districts: undefined,
  district: undefined,
  elections: undefined,
  officials: undefined,
};

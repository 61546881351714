import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Chart from 'react-google-charts';

export default function InfoStatisticLine({
  title, labels, data, data1, data2,
}) {
  return (
    <Paper sx={{ width: '100%', p: 1, pb: 2 }}>
      <Typography variant="h6">{title}</Typography>
      <Box sx={{ maxWidth: 800 }}>
        <Chart
          key={title}
          chartType="LineChart"
          height="100%"
          width="500px"
          data={[
            labels,
            ...Object.keys(data)
              .sort((a, b) => b - a)
              .slice(0, 5)
              .sort((a, b) => a - b)
              .map((key) => {
                const row = [key, data[key]];
                if (data1) {
                  row.push(data1[key]);
                }
                if (data2) {
                  row.push(data2[key]);
                }
                return row;
              }),
          ]}
          loader={<Skeleton variant="rect" style={{ height: '100%', width: '100%' }} />}
          options={{
          // enableInteractivity: false,
            colors: ['#ff0000', 'lightblue', 'pink'],
            legend: 'none',
            // tooltip: { position: 'none' },
            backgroundColor: 'transparent',
          }}
        />
      </Box>
    </Paper>
  );
}

InfoStatisticLine.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.shape({}),
  data1: PropTypes.shape({}),
  data2: PropTypes.shape({}),
};

InfoStatisticLine.defaultProps = {
  title: undefined,
  labels: undefined,
  data: undefined,
  data1: undefined,
  data2: undefined,
};

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import HelpIcon from '@mui/icons-material/Help';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { SocketContext, LocalizationContext } from '../../AppContext';
import Copyright from '../../components/Copyright';
import Snackbar from '../../components/Snackbar';

import localization from './PasswordForgotten.local';

export default function PasswordForgotten() {
  const socket = useContext(SocketContext);
  const local = localization[useContext(LocalizationContext)];
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    socket.emit('passwordForgotten', { email }, ({ error, payload }) => setAlert(error || payload.code));
  };

  return (
    <Container component="main" maxWidth="sm">
      <Snackbar alert={alert} alertProps={{ email }} local={local.alerts} onClose={() => setAlert()} />
      <Paper sx={{ mt: 16, p: 4 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar sx={{ margin: 1, backgroundColor: 'primary.main' }}><HelpIcon /></Avatar>
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>{local.passwordForgotten}</Typography>
          <Box component="form" onSubmit={onSubmit}>
            <TextField
              fullWidth
              id="email"
              type="email"
              name="email"
              color="secondary"
              margin="dense"
              label={local.email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
              required
            />
            <Typography component="p" sx={{ mt: 2, mb: 2 }}>{local.description}</Typography>
            <Button fullWidth sx={{ mt: 2 }} color="primary" variant="contained" type="submit">{local.send}</Button>
            <Button fullWidth sx={{ mt: 1 }} color="primary" onClick={() => navigate('/')}>{local.back}</Button>
          </Box>
        </Box>
      </Paper>
      <Copyright />
    </Container>
  );
}

import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Confirm from '../../../../components/Confirm';
import Snackbar from '../../../../components/Snackbar';
import localization from './FilesPatch.local';

export default function FilesPatch({
  files,
  users,
  onBack,
  onSetFiles,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const { fileid } = useParams();
  const [file, setFile] = useState();
  const [filedata, setFiledata] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [creator, setCreator] = useState();
  const [editor, setEditor] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [alert, setAlert] = useState();
  const [openConfirm, setOpenConfirm] = useState();

  useEffect(() => {
    if (users && files && fileid) {
      const foundFile = files.find((item) => item.id === parseInt(fileid, 10));
      if (foundFile) {
        setFile(foundFile);
        setName(foundFile.name);
        setCategory(foundFile.category);
        setDescription(foundFile.description);
        setCreator(users.find((item) => item.id === foundFile.creator));
        setEditor(users.find((item) => item.id === foundFile.editor));
        socket.emit('files.getFile', { id: foundFile.id }, ({ error, payload }) => (error ? console.log(error) : setFiledata(payload)));
      } else {
        onBack();
      }
    }
  }, [files, fileid]);

  const acknowledgeChange = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetFiles(files ? files.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
    }
  };

  const onChange = (field, value) => {
    const params = {
      id: file.id,
      city: file.city,
      district: file.district,
      field,
      value,
    };
    socket.emit('files.patch', params, acknowledgeChange);
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  const acknowledgeDestroy = ({ error, payload }) => {
    if (error) {
      setAlert(error);
    } else {
      onSetFiles(files ? files.filter((item) => (item.id !== payload.id)) : []);
      onBack();
    }
  };

  const onDestroy = () => {
    const params = {
      id: file.id,
      city: file.city,
      district: file.district,
    };
    socket.emit('files.destroy', params, acknowledgeDestroy);
  };

  return (
    <Paper sx={{ height: '100%', p: 5 }}>
      <Confirm
        title={local.destroyTitle}
        description={local.destroyDescription}
        agree={local.agree}
        disagree={local.disagree}
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={onDestroy}
      />
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h4">{local.title}</Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.name}
                value={name}
                onChange={(e) => onTextChange(setName, 'name', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.category}
                value={category}
                onChange={(e) => onTextChange(setCategory, 'category', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.description}
                value={description}
                onChange={(e) => onTextChange(setDescription, 'description', e.target.value)}
              />
            </Grid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            { file && creator && (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={7}>
                  <Typography>{`${local.createdAt} ${moment(file.createdAt).format('DD.MM.YYYY')} ${local.from}`}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box display="flex">
                    <Avatar src={creator.avatar} alt={`${creator.firstname} ${creator.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
                    <Typography>{`${creator.firstname} ${creator.lastname}`}</Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
            { file && editor && (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={7}>
                  <Typography>{`${local.updatedAt} ${moment(file.updatedAt).format('DD.MM.YYYY')} ${local.from}`}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box display="flex">
                    <Avatar src={editor.avatar} alt={`${editor.firstname} ${editor.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
                    <Typography>{`${editor.firstname} ${editor.lastname}`}</Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item sx={12} md={7}>
          { filedata && file && ['image/png', 'image/jpeg'].includes(file.type) && (
            <img src={Buffer.from(filedata)} alt={file.name} width="100%" height="100%" />
          )}
        </Grid>
      </Grid>
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        { file && filedata && (
        <Fab
          variant="extended"
          color="primary"
          size="small"
          onClick={() => {
            const dec = new TextDecoder();
            const link = document.createElement('a');
            link.href = dec.decode(filedata);
            link.download = file.name;
            link.click();
          }}
          sx={{ justifyContent: 'flex-start' }}
        >
          <DownloadIcon sx={{ mr: 1 }} />
          <Typography>{local.download}</Typography>
        </Fab>
        )}
        { file && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => setOpenConfirm(true)}
            sx={{ justifyContent: 'flex-start' }}
          >
            <DeleteIcon sx={{ mr: 1 }} />
            <Typography>{local.destroy}</Typography>
          </Fab>
        )}
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

FilesPatch.propTypes = {
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  files: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSetFiles: PropTypes.func.isRequired,
};

FilesPatch.defaultProps = {
  city: undefined,
  district: undefined,
  files: undefined,
  users: undefined,
};

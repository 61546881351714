import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import CancelIcon from '@mui/icons-material/Cancel';
// import DeleteIcon from '@mui/icons-material/Delete';

import { LocalizationContext } from '../../../../AppContext';
// import Confirm from '../../../../components/Confirm';
// import Snackbar from '../../../../components/Snackbar';
import localization from './ElectionresultsPatch.local';

export default function ElectionresultsPatch({
  elections,
  electiontypes,
  parties,
  // presidents,
  onBack,
}) {
  const local = localization[useContext(LocalizationContext)];
  // const socket = useContext(SocketContext);
  const { electionresultid } = useParams();
  const [electiontypesActive, setElectiontypesActive] = useState();
  const [electionresult, setElectionresult] = useState();
  const [absolute, setAbsolute] = useState();
  const [type, setType] = useState();
  const [date, setDate] = useState();
  const [eligible, setEligible] = useState(0);
  const [votes, setVotes] = useState(0);
  const [valid, setValid] = useState(0);
  const [invalid, setInvalid] = useState(0);
  const [electionparties, setElectionparties] = useState();
  // const [typingTimeout, setTypingTimeout] = useState();
  // const [alert, setAlert] = useState();
  // const [openConfirm, setOpenConfirm] = useState();

  useEffect(() => {
    if (elections && electionresultid) {
      const foundElectionresult = elections.find((item) => item.id === parseInt(electionresultid, 10));
      if (foundElectionresult) {
        setElectionresult(foundElectionresult);
      } else {
        onBack();
      }
    }
  }, [elections, electionresultid]);

  useEffect(() => {
    if (electionresult) {
      setType(electionresult.type);
      setDate(electionresult.date);
      setEligible(electionresult.eligible);
      setVotes(electionresult.votes);
      setValid(electionresult.valid);
      setInvalid(electionresult.invalid);
      setElectionparties(electionresult.parties);
    }
  }, [electionresult]);

  useEffect(() => (electiontypes ? setElectiontypesActive(electiontypes.filter((item) => item.active)) : null), [electiontypes]);

  return (
    <Paper sx={{ height: '100%', p: 5 }}>
      <Grid container spacing={2}>
        <Grid container item xs={6}>
          <Grid item xs={12}>
            <Button color="primary" variant={absolute ? 'contained' : 'outlined'} onClick={() => setAbsolute(!absolute)}>
              {local.absolute}
            </Button>
          </Grid>
          <Grid item xs={12}>
            { parties && electionparties && (
            <Chart
              key={local.electionresult}
              chartType="ColumnChart"
              height="100%"
              width="100%"
              data={[
                [local.party, local.votes, { role: 'annotation' }, { role: 'style' }],
                ...electionparties
                  .map((item) => {
                    const party = parties.find((entry) => entry.id === item.party);
                    const value = absolute ? item.votes : Math.round((item.votes / valid) * 10000) / 100;
                    return [party.name, value, absolute ? value : `${value}%`, `color: ${party.color}`];
                  }).sort((a, b) => b[1] - a[1]),
              ]}
              loader={<Skeleton variant="rect" style={{ height: '100%', width: '100%' }} />}
              options={{
                legend: 'none',
                backgroundColor: 'transparent',
                annotations: {
                  alwaysOutside: true,
                },
                vAxis: {
                  ticks: absolute ? undefined : [0, 25, 50, 75, 100],
                  maxValue: absolute ? votes : 100,
                  minValue: 0,
                  // viewWindow: {
                  //   max: absolute ? votes : 100,
                  // },
                },
              }}
            />
            )}
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={12}>
            <Autocomplete
              disableClearable
              disabled
              value={electiontypesActive && type ? electiontypesActive.find((item) => item.id === type) : null}
              options={electiontypesActive}
              // onChange={(e, selected) => onChange('status', selected.id)}
              getOptionLabel={((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.type}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              type="date"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.date}
              value={moment(date).format('YYYY-MM-DD')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.eligible}
              value={eligible}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.votes}
              value={votes}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.valid}
              value={valid}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.invalid}
              value={invalid}
            />
          </Grid>
        </Grid>
      </Grid>
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        {/* { electionresult && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => setOpenConfirm(true)}
            sx={{ justifyContent: 'flex-start' }}
          >
            <DeleteIcon sx={{ mr: 1 }} />
            <Typography>{local.destroy}</Typography>
          </Fab>
        )} */}
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

ElectionresultsPatch.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  electiontypes: PropTypes.arrayOf(PropTypes.shape({})),
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  presidents: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
};

ElectionresultsPatch.defaultProps = {
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
  elections: undefined,
  electiontypes: undefined,
  parties: undefined,
  presidents: undefined,
};

import moment from 'moment';

import hasSteeringAccess from '../../../../util/hasSteeringAccess';

const ELECTION_TYPE_COUNCIL = 2;
const calcMandates = (city, elections, parties) => {
  const councilElections = elections.filter((election) => election.city === city.id && election.type === ELECTION_TYPE_COUNCIL);
  const latestCouncilElection = councilElections.sort((a, b) => moment(b.date).diff(moment(a.date)))[0];
  let mandates = 0;
  latestCouncilElection.parties.forEach((item) => { mandates += item.mandates; });
  let absolute;
  let mandatesSpo = 0;
  let mandatesOvp = 0;
  let mandatesFpo = 0;
  latestCouncilElection.parties.forEach((item) => {
    if (item.mandates / mandates > 0.5) {
      absolute = parties.find((party) => party.id === item.party).name;
    }
    if (item.party === 1) {
      mandatesSpo = item.mandates;
    }
    if (item.party === 2) {
      mandatesOvp = item.mandates;
    }
    if (item.party === 3) {
      mandatesFpo = item.mandates;
    }
  });
  return {
    mandates, absolute, mandatesSpo, mandatesOvp, mandatesFpo,
  };
};

const getLatest = (items, empty) => (items
  ? (items[Object.keys(items).sort((a, b) => b - a)[0]] || empty)
  : empty);

export default function CitiesRowsMapper(
  local,
  cities,
  citysettingdefinitions,
  citysettings,
  districts,
  district,
  elections,
  officials,
  parties,
  user,
) {
  let rows;
  if (cities && citysettingdefinitions && citysettings && districts && elections && officials && parties && user) {
    const filtered = district ? cities.filter((item) => item.district === district.id) : cities;
    rows = filtered.map((city) => {
      const cityDistrict = district || districts.find((item) => item.id === city.district);
      const {
        mandates, absolute, mandatesSpo, mandatesOvp, mandatesFpo,
      } = calcMandates(city, elections, parties);
      const highestOffice = getLatest(city.highestOffice, '');
      const officialsMaleFemale = getLatest(city.officials, 0);
      const officialsFemale = getLatest(city.officialsFemale, 0);
      const citizens = getLatest(city.citizens, 0);
      const members = getLatest(city.members, 0);
      const membersAverageAge = getLatest(city.membersAverageAge, 0);
      const officialsAverageAge = getLatest(city.officialsAverageAge, 0);
      let mayorName = '';
      let mayorParty = '';
      let mayorBirthdate = '';
      let mayorEmail = '';
      let mayorPhone = '';
      let mayorMail = '';
      let mayorSince = '';
      const mayor = officials.find((official) => official.city === city.id && official.type === 1);
      if (mayor) {
        mayorName = `${mayor.firstname} ${mayor.lastname}`;
        mayorParty = parties.find((item) => item.id === mayor.party).name;
        mayorBirthdate = mayor.birthdate;
        mayorEmail = mayor.email;
        mayorPhone = mayor.phone;
        mayorMail = mayor.post;
        mayorSince = mayor.since;
      }
      let vicemayorName = '';
      let vicemayorParty = '';
      let vicemayorBirthdate = '';
      let vicemayorEmail = '';
      let vicemayorPhone = '';
      let vicemayorMail = '';
      let vicemayorSince = '';
      const vicemayor = officials.find((official) => official.city === city.id && official.type === 2);
      if (vicemayor) {
        vicemayorName = `${vicemayor.firstname} ${vicemayor.lastname}`;
        vicemayorParty = parties.find((item) => item.id === vicemayor.party).name;
        vicemayorBirthdate = vicemayor.birthdate;
        vicemayorEmail = vicemayor.email;
        vicemayorPhone = vicemayor.phone;
        vicemayorMail = vicemayor.post;
        vicemayorSince = vicemayor.since;
      }
      const row = {
        id: city.id,
        gkz: city.gkz,
        districtid: cityDistrict.id,
        district: cityDistrict.name,
        name: city.name,
        status: city.status,
        highestOffice,
        absolute,
        mandates,
        mandatesSpo,
        mandatesSpoPercent: mandates > 0 ? Math.round((mandatesSpo / mandates) * 10000) / 100 : 0,
        mandatesOvp,
        mandatesOvpPercent: mandates > 0 ? Math.round((mandatesOvp / mandates) * 10000) / 100 : 0,
        mandatesFpo,
        mandatesFpoPercent: mandates > 0 ? Math.round((mandatesFpo / mandates) * 10000) / 100 : 0,
        officials: officialsMaleFemale,
        officialsFemale,
        officialsFemalePercent: officialsMaleFemale > 0 ? Math.round((officialsFemale / officialsMaleFemale) * 10000) / 100 : 0,
        citizens,
        members,
        membersPercent: citizens > 0 ? Math.round((members / citizens) * 10000) / 100 : 0,
        membersAverageAge,
        officialsAverageAge,
        mayorName,
        mayorParty,
        mayorBirthdate,
        mayorEmail,
        mayorPhone,
        mayorMail,
        mayorSince,
        vicemayorName,
        vicemayorParty,
        vicemayorBirthdate,
        vicemayorEmail,
        vicemayorPhone,
        vicemayorMail,
        vicemayorSince,
      };
      if (user.control || (district && hasSteeringAccess(user, district.id))) {
        citysettingdefinitions.forEach((citysettingdefinition) => {
          if (citysettingdefinition.active) {
            const citySetting = citysettings.find((item) => item.definition === citysettingdefinition.id && item.city === city.id);
            if (citySetting) {
              if (['TEXT', 'NUMBER', 'BOOLEAN'].includes(citysettingdefinition.type)) {
                row[citysettingdefinition.name] = citySetting.value;
              } else if (citysettingdefinition.type === 'DROPDOWN') {
                const option = citysettingdefinition.options.find((item) => item.id.toString() === citySetting.value);
                row[citysettingdefinition.name] = option ? option.name : undefined;
              } else if (citysettingdefinition.type === 'DATE') {
                row[citysettingdefinition.name] = moment(citySetting.value).format('YYYY-MM-DD');
              }
            }
          }
        });
      }
      return row;
    });
  }
  return rows;
}

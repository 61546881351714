import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import AgreementstatusesPost from './AgreementstatusesPost';
import AgreementstatusesPatch from './AgreementstatusesPatch';
import AgreementstatusesTable from './AgreementstatusesTable';

export default function Agreementstatuses({
  agreements,
  agreementstatuses,
  onSetAgreementstatuses,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <AgreementstatusesPost
            agreements={agreements}
            agreementstatuses={agreementstatuses}
            onBack={() => navigate('/settings/agreements/statuses')}
            onSetAgreementstatuses={onSetAgreementstatuses}
          />
        )}
      />
      <Route
        path=":agreementstatusid"
        element={(
          <AgreementstatusesPatch
            agreements={agreements}
            agreementstatuses={agreementstatuses}
            onBack={() => navigate('/settings/agreements/statuses')}
            onSetAgreementstatuses={onSetAgreementstatuses}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <AgreementstatusesTable
            agreements={agreements}
            agreementstatuses={agreementstatuses}
            onRowClick={(id) => navigate(`/settings/agreements/statuses/${id}`)}
            onAddClick={() => navigate('/settings/agreements/statuses/post')}
            onCategoriesClick={() => navigate('/settings/agreements/categories')}
          />
        )}
      />
    </Routes>
  );
}

Agreementstatuses.propTypes = {
  agreements: PropTypes.arrayOf(PropTypes.shape({})),
  agreementstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  onSetAgreementstatuses: PropTypes.func.isRequired,
};

Agreementstatuses.defaultProps = {
  agreements: undefined,
  agreementstatuses: undefined,
};

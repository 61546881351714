import React from 'react';
import PropTypes from 'prop-types';
import MuiSnackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Severity = {
  100: 'info',
  400: 'error',
  401: 'error',
  403: 'error',
  404: 'error',
  409: 'error',
  500: 'error',
};

export default function Snackbar({
  alert,
  alertProps,
  autoHideDuration,
  horizontal,
  local,
  vertical,
  onClose,
}) {
  return (
    <MuiSnackbar open={alert} autoHideDuration={autoHideDuration} onClose={onClose} anchorOrigin={{ vertical, horizontal }}>
      { alert && (
        <Alert onClose={onClose} severity={Severity[alert]}>
          {local && local[alert](alertProps)}
        </Alert>
      )}
    </MuiSnackbar>
  );
}

Snackbar.propTypes = {
  alert: PropTypes.number,
  alertProps: PropTypes.shape({}),
  autoHideDuration: PropTypes.number,
  horizontal: PropTypes.string,
  local: PropTypes.shape({}),
  vertical: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

Snackbar.defaultProps = {
  alert: undefined,
  alertProps: undefined,
  local: undefined,
  autoHideDuration: 6000,
  vertical: 'top',
  horizontal: 'center',
};

export default {
  de: {
    name: 'Bezirk',
    gkz: 'GKZ',
    cities: 'Gemeinden',
    absolutes: 'Absolute SPÖ Mehrheitgemeinden',
    absolutesPercent: 'Absolute SPÖ Mehrheitsgemeinden %',
    mandates: 'Mandate',
    mandatesSpo: 'SPÖ Mandate',
    mandatesSpoPercent: 'SPÖ Mandate %',
    mandatesOvp: 'ÖVP Mandate',
    mandatesOvpPercent: 'ÖVP Mandate %',
    mandatesFpo: 'FPÖ Mandate',
    mandatesFpoPercent: 'FPÖ Mandate %',
    officials: 'SPÖ GR',
    officialsFemale: 'SPÖ GR Frauen',
    officialsFemalePercent: 'GR Frauen %',
    citizens: 'Bevölkerung',
    members: 'Mitglieder',
    membersPercent: 'Mitglieder %',
    membersAverageAge: 'Mitglieder Ø-Alter',
    officialsAverageAge: 'GR*Innen Ø-Alter',
    mayors: 'BGM*Innen',
    mayorsPercent: 'BGM*Innen %',
  },
};

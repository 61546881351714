import React, { useState, useContext } from 'react';
import {
  useNavigate, Routes, Route, Navigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';

import { LocalizationContext } from '../../../AppContext';
import Copyright from '../../../components/Copyright';

import Agreements from './agreements/Agreements';
import Citysettingdefinitions from './citysettingdefinitions/Citysettingdefinitions';
import Citysettings from './citysettings/Citysettings';
import Elections from './elections/Elections';
import Officials from './officials/Officials';
import Reports from './reports/Reports';
import Services from './services/Services';
import Users from './users/Users';
import SettingsMenu from './SettingsMenu';
import SettingsNavigator from './SettingsNavigator';
import Logs from './logs/Logs';
import localization from './Settings.local';

export default function Settings({
  agreementcategories,
  agreements,
  agreementstatuses,
  cities,
  citysettingdefinitions,
  citysettings,
  districts,
  elections,
  electiontypes,
  officialcategories,
  officials,
  officialtypes,
  parties,
  presidents,
  reportdefinitions,
  reports,
  reportstatuses,
  servicecategories,
  services,
  servicestatuses,
  logs,
  onSetAgreementcategories,
  onSetAgreementstatuses,
  onSetCitysettingdefinitions,
  onSetCitysettings,
  onSetElectiontypes,
  onSetOfficialcategories,
  onSetOfficialtypes,
  onSetParties,
  onSetPresidents,
  onSetReportdefinitions,
  onSetReportstatuses,
  onSetServicecategories,
  onSetServicestatuses,
  onUpdateUser,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [drawerToggle, toggleDrawer] = useState(false);
  const navigate = useNavigate();

  return (
    <Box display="flex">
      <Hidden lgDown>
        <Drawer
          anchor="left"
          variant="permanent"
          sx={{
            width: 250,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 250,
              boxSizing: 'border-box',
            },
          }}
        >
          <Toolbar />
          <SettingsNavigator cities={cities} districts={districts} />
        </Drawer>
      </Hidden>
      <Hidden xlUp>
        <SwipeableDrawer
          open={drawerToggle}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
          sx={{
            width: 250,
            '& .MuiDrawer-paper': { width: 250, boxSizing: 'border-box' },
          }}
        >
          <Toolbar />
          <SettingsNavigator cities={cities} districts={districts} />
        </SwipeableDrawer>
      </Hidden>
      <Container sx={{ pt: 10, height: '100vh', display: 'table' }} maxWidth={false}>
        <Box display="flex" alignItems="center">
          <Hidden lgUp>
            <IconButton onClick={() => toggleDrawer(true)}><MenuIcon /></IconButton>
            <Divider orientation="vertical" flexItem sx={{ mt: 1, mr: 1, mb: 1 }} />
          </Hidden>
          <Breadcrumbs>
            <Button onClick={() => navigate('/-/-')} size="small" color="primary">{process.env.REACT_APP_NAME}</Button>
            <Button onClick={() => navigate('/settings')} size="small" color="primary">{local.settings}</Button>
          </Breadcrumbs>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <SettingsMenu sx={{ display: 'table-row', height: 70 }} />
        <Box sx={{ display: 'table-row', height: '100%', mt: 1 }}>
          <Box sx={{ height: '100%', pt: 1 }}>
            <Routes>
              <Route
                path="users/*"
                element={<Users cities={cities} districts={districts} onUpdateUser={onUpdateUser} />}
              />
              <Route
                path="agreements/*"
                element={(
                  <Agreements
                    agreementcategories={agreementcategories}
                    agreements={agreements}
                    agreementstatuses={agreementstatuses}
                    onSetAgreementcategories={onSetAgreementcategories}
                    onSetAgreementstatuses={onSetAgreementstatuses}
                  />
                )}
              />
              <Route
                path="citysettings/*"
                element={(
                  <Citysettings
                    cities={cities}
                    citysettingdefinitions={citysettingdefinitions}
                    citysettings={citysettings}
                    districts={districts}
                    onSetCitysettings={onSetCitysettings}
                  />
                )}
              />
              <Route
                path="citysettingdefinitions/*"
                element={(
                  <Citysettingdefinitions
                    citysettingdefinitions={citysettingdefinitions}
                    citysettings={citysettings}
                    onSetCitysettingdefinitions={onSetCitysettingdefinitions}
                  />
                )}
              />
              <Route
                path="elections/*"
                element={(
                  <Elections
                    elections={elections}
                    electiontypes={electiontypes}
                    parties={parties}
                    presidents={presidents}
                    onSetElectiontypes={onSetElectiontypes}
                    onSetParties={onSetParties}
                    onSetPresidents={onSetPresidents}
                  />
                )}
              />
              <Route
                path="officials/*"
                element={(
                  <Officials
                    officialcategories={officialcategories}
                    officialtypes={officialtypes}
                    officials={officials}
                    onSetOfficialcategories={onSetOfficialcategories}
                    onSetOfficialtypes={onSetOfficialtypes}
                  />
                )}
              />
              <Route
                path="reports/*"
                element={(
                  <Reports
                    citysettingdefinitions={citysettingdefinitions}
                    reportdefinitions={reportdefinitions}
                    reports={reports}
                    reportstatuses={reportstatuses}
                    onSetReportdefinitions={onSetReportdefinitions}
                    onSetReportstatuses={onSetReportstatuses}
                  />
                )}
              />
              <Route
                path="services/*"
                element={(
                  <Services
                    servicestatuses={servicestatuses}
                    servicecategories={servicecategories}
                    services={services}
                    onSetServicecategories={onSetServicecategories}
                    onSetServicestatuses={onSetServicestatuses}
                  />
                )}
              />
              <Route
                path="logs/*"
                element={(
                  <Logs
                    cities={cities}
                    districts={districts}
                    logs={logs}
                  />
                )}
              />
              <Route path="*" element={<Navigate to="users" />} />
            </Routes>
          </Box>
        </Box>
        <Copyright sx={{ display: 'table-row', height: 36 }} />
      </Container>
    </Box>
  );
}

Settings.propTypes = {
  agreementcategories: PropTypes.arrayOf(PropTypes.shape({})),
  agreements: PropTypes.arrayOf(PropTypes.shape({})),
  agreementstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  citysettings: PropTypes.arrayOf(PropTypes.shape({})),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  electiontypes: PropTypes.arrayOf(PropTypes.shape({})),
  officialcategories: PropTypes.arrayOf(PropTypes.shape({})),
  officials: PropTypes.arrayOf(PropTypes.shape({})),
  officialtypes: PropTypes.arrayOf(PropTypes.shape({})),
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  presidents: PropTypes.arrayOf(PropTypes.shape({})),
  reportdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  reports: PropTypes.arrayOf(PropTypes.shape({})),
  reportstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  servicecategories: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  servicestatuses: PropTypes.arrayOf(PropTypes.shape({})),
  logs: PropTypes.arrayOf(PropTypes.shape({})),
  onSetAgreementcategories: PropTypes.func.isRequired,
  onSetAgreementstatuses: PropTypes.func.isRequired,
  onSetCitysettingdefinitions: PropTypes.func.isRequired,
  onSetCitysettings: PropTypes.func.isRequired,
  onSetElectiontypes: PropTypes.func.isRequired,
  onSetOfficialcategories: PropTypes.func.isRequired,
  onSetOfficialtypes: PropTypes.func.isRequired,
  onSetParties: PropTypes.func.isRequired,
  onSetPresidents: PropTypes.func.isRequired,
  onSetReportdefinitions: PropTypes.func.isRequired,
  onSetReportstatuses: PropTypes.func.isRequired,
  onSetServicecategories: PropTypes.func.isRequired,
  onSetServicestatuses: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
};

Settings.defaultProps = {
  agreementcategories: undefined,
  agreements: undefined,
  agreementstatuses: undefined,
  cities: undefined,
  citysettingdefinitions: undefined,
  citysettings: undefined,
  districts: undefined,
  elections: undefined,
  electiontypes: undefined,
  officialcategories: undefined,
  officials: undefined,
  officialtypes: undefined,
  parties: undefined,
  presidents: undefined,
  reportdefinitions: undefined,
  reports: undefined,
  reportstatuses: undefined,
  servicecategories: undefined,
  services: undefined,
  servicestatuses: undefined,
  logs: undefined,
};

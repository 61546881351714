export default {
  de: {
    title: 'Mandate',
    absolute: 'Absolute Mehrheit',
    noAbsolute: '-',
    mandatesTotal: 'Gesamtmandate',
    mandates: 'Mandate',
    party: 'Partei',
    noCouncilParties: 'Mandate unbekannt',
    coalition: ' Koalition',
    cancel: 'Benutzer-Ansicht',
    edit: 'Bearbeiten',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

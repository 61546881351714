import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
// import Avatar from '@mui/material/Avatar';
import BlockIcon from '@mui/icons-material/Block';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CancelIcon from '@mui/icons-material/Cancel';

import { LocalizationContext, SocketContext, UserContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import localization from './UsersPatch.local';

export default function UsersPatch({
  cities,
  districts,
  users,
  onBack,
  onSetUsers,
  onUpdateUser,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const user = useContext(UserContext);
  const { userid } = useParams();
  const [userPatch, setUserPatch] = useState();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [typingTimeout, setTypingTimeout] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (users && userid) {
      const foundUser = users.find((item) => item.id === parseInt(userid, 10));
      if (foundUser) {
        setUserPatch(foundUser);
      } else {
        onBack();
      }
    }
  }, [users, userid]);

  useEffect(() => {
    if (userPatch) {
      setFirstname(userPatch.firstname);
      setLastname(userPatch.lastname);
      setEmail(userPatch.email);
    }
  }, [userPatch]);

  const acknowledgeChange = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetUsers(users ? users.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
      if (user.id === payload.id) {
        onUpdateUser(payload);
      }
    }
  };

  const onChange = (field, value) => {
    const params = {
      id: userPatch.id,
      field,
      value,
    };
    socket.emit('users.patch', params, acknowledgeChange);
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  return (
    <Paper sx={{ height: '100%', p: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h4">{local.title}</Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      { userPatch && cities && districts ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.firstname}
              value={firstname}
              onChange={(e) => onTextChange(setFirstname, 'firstname', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.lastname}
              value={lastname}
              onChange={(e) => onTextChange(setLastname, 'lastname', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="email"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.email}
              value={email}
              onChange={(e) => onTextChange(setEmail, 'email', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControlLabel
              sx={{ p: 1, pt: 2 }}
              label={local.admin}
              control={(
                <Checkbox
                  checked={userPatch.admin}
                  onChange={(e) => onChange('admin', e.target.checked)}
                />
            )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControlLabel
              sx={{ p: 1, pt: 2 }}
              label={local.control}
              control={(
                <Checkbox
                  checked={userPatch.control}
                  onChange={(e) => onChange('control', e.target.checked)}
                />
            )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControlLabel
              sx={{ p: 1, pt: 2 }}
              label={local.observer}
              control={(
                <Checkbox
                  checked={userPatch.observer}
                  onChange={(e) => onChange('observer', e.target.checked)}
                />
            )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControlLabel
              sx={{ p: 1, pt: 2 }}
              label={local.services}
              control={(
                <Checkbox
                  checked={userPatch.services}
                  onChange={(e) => onChange('services', e.target.checked)}
                />
            )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              value={districts.filter((district) => userPatch.steering.includes(district.id))}
              options={districts}
              onChange={(e, selected) => onChange('steering', selected.map((item) => item.id))}
              getOptionLabel={((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.steering}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              value={cities.filter((city) => userPatch.coaching.includes(city.id))}
              options={cities}
              onChange={(e, selected) => onChange('coaching', selected.map((item) => item.id))}
              getOptionLabel={((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.coaching}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              value={cities.filter((city) => userPatch.watching.includes(city.id))}
              options={cities}
              onChange={(e, selected) => onChange('watching', selected.map((item) => item.id))}
              getOptionLabel={((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.watching}
                />
              )}
            />
          </Grid>
        </Grid>
      ) : (
        <Skeleton variant="rect" height="60%" width="100%" />
      )}
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        { userPatch && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => onChange('active', !userPatch.active)}
            sx={{ justifyContent: 'flex-start' }}
          >
            {userPatch.active ? <BlockIcon sx={{ mr: 1 }} /> : <VerifiedUserIcon sx={{ mr: 1 }} /> }
            <Typography>{userPatch.active ? local.lockUser : local.unlockUser}</Typography>
          </Fab>
        )}
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

UsersPatch.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSetUsers: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
};

UsersPatch.defaultProps = {
  cities: undefined,
  districts: undefined,
  users: undefined,
};

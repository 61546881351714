export default {
  de: {
    add: 'Benutzer hinzufügen',
    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'Email',
    avatar: 'Avatar',
    active: 'Aktiv',
    admin: 'Administrator',
    control: 'Steuerung',
    services: 'Leistungen',
    steering: 'Lenkung',
    coaching: 'Coaching',
    watching: 'Beobachtung',
  },
};

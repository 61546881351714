import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Masonry from '@mui/lab/Masonry';

import { LocalizationContext } from '../../../../AppContext';

import InfoCityAgreements from './InfoCityAgreements';
import InfoCityCouncil from './InfoCityCouncil';
import InfoCityDetails from './InfoCityDetails';
import InfoCityMandates from './InfoCityMandates';
import InfoCityReports from './InfoCityReports';
import InfoCitySettings from './InfoCitySettings';
import InfoCityUsers from './InfoCityUsers';
import InfoCityStatistic from './InfoStatisticLine';
import localization from './InfoCity.local';

export default function InfoCity({
  cityInfo,
  citysettingdefinitions,
  citysettings,
  district,
  elections,
  group,
  users,
  onSetCitysettings,
  onSetElections,
  onSetGroup,
}) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Button size="small" variant="text" color={group ? 'primary' : 'secondary'} onClick={() => onSetGroup(!group)}>
        {local.group}
      </Button>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Masonry columns={1} sx={{ width: '100%' }}>
            <InfoCityDetails
              citizens={cityInfo.citizens}
              city={cityInfo.name}
              district={district?.name}
              electiondate={cityInfo.electiondate}
              gkz={cityInfo.gkz}
              highestOffice={cityInfo.highestOffice}
              localparties={cityInfo.localparties}
              members={cityInfo.members}
              membersAverageAge={cityInfo.membersAverageAge}
              officials={cityInfo.officials}
              officialsAverageAge={cityInfo.officialsAverageAge}
              officialsFemale={cityInfo.officialsFemale}
              officialsMale={cityInfo.officialsMale}
              status={cityInfo.status}
            />
            <InfoCitySettings
              cityid={cityInfo.id}
              citysettingdefinitions={citysettingdefinitions}
              citysettings={citysettings}
              districtid={district?.id}
              users={users}
              onSetCitysettings={onSetCitysettings}
            />
            { cityInfo.agreements && <InfoCityAgreements infoAgreements={cityInfo.agreements} /> }
            { cityInfo.reports && <InfoCityReports infoReports={cityInfo.reports} /> }
            <InfoCityStatistic title={local.citizens} data={cityInfo.citizens} labels={[local.year, local.citizens]} />
            <InfoCityStatistic title={local.members} data={cityInfo.members} labels={[local.year, local.members]} />
            <InfoCityStatistic title={local.membersAverageAge} data={cityInfo.membersAverageAge} labels={[local.year, local.membersAverageAge]} />
          </Masonry>
        </Grid>
        <Grid item xs={12} md={6}>
          <Masonry columns={1} sx={{ width: '100%' }}>
            { users && <InfoCityUsers city={cityInfo.id} district={district?.id} users={users} />}
            <InfoCityMandates
              absolute={cityInfo.absolute}
              cityid={cityInfo.id}
              councilParties={cityInfo.councilParties}
              councilElection={cityInfo.councilElection}
              districtid={district?.id}
              elections={elections}
              group={group}
              mandates={cityInfo.mandates}
              onSetGroup={onSetGroup}
              onSetElections={onSetElections}
            />
            <InfoCityCouncil
              alternates={cityInfo.alternates}
              cashiers={cityInfo.cashiers}
              cityCouncils={cityInfo.cityCouncils}
              councils={cityInfo.councils}
              councilsBoards={cityInfo.councilsBoards}
              fractions={cityInfo.fractions}
              mayor={cityInfo.mayor}
              vicemayors={cityInfo.vicemayors}
            />
            <InfoCityStatistic
              title={local.officials}
              data={cityInfo.officials}
              data1={cityInfo.officialsMale}
              data2={cityInfo.officialsFemale}
              labels={[local.year, local.officials, local.officialsMale, local.officialsFemale]}
            />
            <InfoCityStatistic title={local.officialsAverageAge} data={cityInfo.officialsAverageAge} labels={[local.year, local.officialsAverageAge]} />
          </Masonry>
        </Grid>
      </Grid>
    </Box>
  );
}

InfoCity.propTypes = {
  cityInfo: PropTypes.shape({
    absolute: PropTypes.shape({}),
    agreements: PropTypes.arrayOf(PropTypes.shape({})),
    alternates: PropTypes.arrayOf(PropTypes.shape({})),
    citizens: PropTypes.shape({}),
    cashiers: PropTypes.arrayOf(PropTypes.shape({})),
    cityCouncils: PropTypes.arrayOf(PropTypes.shape({})),
    councils: PropTypes.arrayOf(PropTypes.shape({})),
    councilsBoards: PropTypes.arrayOf(PropTypes.shape({})),
    councilElection: PropTypes.shape({}),
    councilParties: PropTypes.arrayOf(PropTypes.shape({})),
    electiondate: PropTypes.string,
    fractions: PropTypes.arrayOf(PropTypes.shape({})),
    gkz: PropTypes.number,
    highestOffice: PropTypes.shape({}),
    id: PropTypes.number,
    localparties: PropTypes.arrayOf(PropTypes.shape({})),
    mandates: PropTypes.number,
    mayor: PropTypes.shape({}),
    members: PropTypes.shape({}),
    membersAverageAge: PropTypes.shape({}),
    name: PropTypes.string,
    officials: PropTypes.shape({}),
    officialsAverageAge: PropTypes.shape({}),
    officialsFemale: PropTypes.shape({}),
    officialsMale: PropTypes.shape({}),
    reports: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.string,
    vicemayors: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  citysettings: PropTypes.arrayOf(PropTypes.shape({})),
  district: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  group: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onSetCitysettings: PropTypes.func.isRequired,
  onSetElections: PropTypes.func.isRequired,
  onSetGroup: PropTypes.func.isRequired,
};

InfoCity.defaultProps = {
  citysettingdefinitions: undefined,
  citysettings: undefined,
  district: undefined,
  elections: undefined,
  users: undefined,
};

export default function ReportstatusesTableRowsMapper(local, reportstatuses) {
  let rows;
  if (reportstatuses) {
    rows = reportstatuses.map((row) => ({
      id: row.id,
      name: row.name,
    }));
  }
  return rows;
}

export default {
  de: {
    title: 'Einstellungen',
    cancel: 'Benutzer-Ansicht',
    edit: 'Bearbeiten',
    true: 'Ja',
    false: 'Nein',
    report: 'Bericht',
    manual: 'Einstellungen',
    updatedBy: 'Bearbeitet von',
    updatedAt: 'am',
    by: 'durch',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import { BOOLEAN } from '../../../../config/citysettingtypes';
import Toolbar from './CitysettingdefinitionsTableToolbar';
import ColumnDefinition from './CitysettingdefinitionsTableColumnDefinition';
import RowsMapper from './CitysettingdefinitionsTableRowsMapper';
import localization from './CitysettingdefinitionsTable.local';

export default function CitysettingdefinitionsTable({
  citysettingdefinitions,
  onRowClick,
  onSetCitysettingdefinitions,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => setColumns(ColumnDefinition(local)), []);
  useEffect(() => setRows(RowsMapper(local, citysettingdefinitions)), [citysettingdefinitions]);

  const onAddClick = () => {
    socket.emit('citysettingdefinitions.post', { name: local.newDefinition, type: BOOLEAN }, ({ error, payload }) => {
      setAlert(error || 200);
      if (!error) {
        onSetCitysettingdefinitions([...citysettingdefinitions, payload]);
      }
    });
  };

  return (
    <Paper sx={{ height: '100%' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar }}
          componentsProps={{ toolbar: { local, onAddClick } }}
        />
      )}
    </Paper>
  );
}

CitysettingdefinitionsTable.propTypes = {
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func.isRequired,
  onSetCitysettingdefinitions: PropTypes.func.isRequired,
};

CitysettingdefinitionsTable.defaultProps = {
  citysettingdefinitions: undefined,
};

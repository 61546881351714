import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { uuid } from 'uuidv4';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Confirm from '../../../../components/Confirm';
import Snackbar from '../../../../components/Snackbar';
import {
  BOOLEAN, DATE, DROPDOWN, NUMBER, TEXT,
} from '../../../../config/citysettingtypes';
import localization from './CitysettingdefinitionsPatch.local';

export default function CitysettingdefinitionsPatch({
  citysettingdefinitions,
  onBack,
  onSetCitysettingdefinitions,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const { citysettingdefinitionid } = useParams();
  const [citysettingdefinition, setCitysettingdefinition] = useState();
  const [name, setName] = useState('');
  const [sort, setSort] = useState(0);
  const [options, setOptions] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [alert, setAlert] = useState();
  const [openConfirm, setOpenConfirm] = useState();

  useEffect(() => {
    if (citysettingdefinitions && citysettingdefinitionid) {
      const foundCitysettingdefinition = citysettingdefinitions.find((item) => item.id === parseInt(citysettingdefinitionid, 10));
      if (foundCitysettingdefinition) {
        setCitysettingdefinition(foundCitysettingdefinition);
      } else {
        onBack();
      }
    }
  }, [citysettingdefinitions, citysettingdefinitionid]);

  useEffect(() => {
    if (citysettingdefinition) {
      setName(citysettingdefinition.name);
      setSort(citysettingdefinition.sort);
      setOptions(citysettingdefinition.options);
    }
  }, [citysettingdefinition]);

  const acknowledgeChange = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetCitysettingdefinitions(citysettingdefinitions ? citysettingdefinitions.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
    }
  };

  const onChange = (field, value) => {
    const params = {
      id: citysettingdefinition.id,
      field,
      value,
    };
    socket.emit('citysettingdefinitions.patch', params, acknowledgeChange);
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  const acknowledgeDestroy = ({ error, payload }) => {
    if (error) {
      setAlert(error);
    } else {
      onSetCitysettingdefinitions(citysettingdefinitions ? citysettingdefinitions.filter((item) => (item.id !== payload.id)) : []);
      onBack();
    }
  };

  const onDestroy = () => {
    const params = { id: citysettingdefinition.id };
    socket.emit('citysettingdefinitions.destroy', params, acknowledgeDestroy);
  };

  return (
    <Paper sx={{ height: '100%', p: 5 }}>
      <Confirm
        title={local.destroyTitle}
        description={local.destroyDescription}
        agree={local.agree}
        disagree={local.disagree}
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={onDestroy}
      />
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h4">{local.title}</Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      { citysettingdefinition ? (
        <Grid container spacing={2}>
          <Grid item xs={5}>
            {local.placeholder}
          </Grid>
          <Grid item xs={7}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.name}
              value={name}
              onChange={(e) => onTextChange(setName, 'name', e.target.value)}
            />
          </Grid>
          <Grid item xs={5}>
            {local.placeholder}
          </Grid>
          <Grid item xs={7}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.sort}
              value={sort}
              onChange={(e) => onTextChange(setSort, 'sort', e.target.value)}
            />
          </Grid>
          <Grid item xs={5}>
            {local.placeholder}
          </Grid>
          <Grid item xs={7}>
            <FormControlLabel
              sx={{ p: 1, pt: 2 }}
              label={local.display}
              control={(
                <Checkbox
                  checked={citysettingdefinition.display}
                  onChange={(e) => onChange('display', e.target.checked)}
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            {local.placeholder}
          </Grid>
          <Grid item xs={7}>
            <FormControlLabel
              sx={{ p: 1, pt: 2 }}
              label={local.localparty}
              control={(
                <Checkbox
                  checked={citysettingdefinition.localparty}
                  onChange={(e) => onChange('localparty', e.target.checked)}
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            {local.placeholder}
          </Grid>
          <Grid item xs={7}>
            <Autocomplete
              disableClearable
              value={{ name: local[citysettingdefinition.type], value: citysettingdefinition.type }}
              options={[
                { name: local[BOOLEAN], value: BOOLEAN },
                { name: local[DATE], value: DATE },
                { name: local[DROPDOWN], value: DROPDOWN },
                { name: local[NUMBER], value: NUMBER },
                { name: local[TEXT], value: TEXT },
              ]}
              onChange={(e, selected) => onChange('type', selected.value)}
              getOptionLabel={((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.type}
                />
              )}
            />
          </Grid>
          { options && citysettingdefinition.type === DROPDOWN && options.map((option) => (
            <Grid container item xs={12} key={option.id}>
              <Grid item xs={5}>
                {local.placeholder}
              </Grid>
              <Grid item xs={7}>
                <Box display="flex">
                  <Box sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      label={local.option}
                      value={option.name}
                      onChange={(e) => onTextChange(setOptions, 'options', options.map((item) => (item.id === option.id ? { ...option, name: e.target.value } : item)))}
                    />
                  </Box>
                  <Button variant="filled" color="secondary" onClick={() => onChange('options', options.filter((item) => item.id !== option.id))}>
                    <DeleteIcon />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          ))}
          { citysettingdefinition.type === DROPDOWN && (
          <Grid container item xs={12}>
            <Grid item xs={5}>
              {local.placeholder}
            </Grid>
            <Grid item xs={7}>
              <Button variant="filled" color="secondary" onClick={() => onChange('options', [...options, { id: uuid(), name: local.newOption }])}>
                <AddIcon />
                <Typography sx={{ ml: 1 }}>{local.add}</Typography>
              </Button>
            </Grid>
          </Grid>
          )}
        </Grid>
      ) : (
        <Skeleton variant="rect" sx={{ height: '100%', width: '100%' }} />
      )}
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        { citysettingdefinition && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => setOpenConfirm(true)}
            sx={{ justifyContent: 'flex-start' }}
          >
            <DeleteIcon sx={{ mr: 1 }} />
            <Typography>{local.destroy}</Typography>
          </Fab>
        )}
        { citysettingdefinition && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => onChange('active', !citysettingdefinition.active)}
            sx={{ justifyContent: 'flex-start' }}
          >
            { citysettingdefinition.active ? <LockIcon /> : <LockOpenIcon /> }
            <Typography sx={{ ml: 1 }}>{citysettingdefinition.active ? local.lock : local.unlock}</Typography>
          </Fab>
        )}
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

CitysettingdefinitionsPatch.propTypes = {
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSetCitysettingdefinitions: PropTypes.func.isRequired,
};

CitysettingdefinitionsPatch.defaultProps = {
  citysettingdefinitions: undefined,
};

import React from 'react';
import PropTypes from 'prop-types';
import { createEvents } from 'ics';

export default function DownloadIcsButton({ events }) {
  const convertEventsToIcs = () => {
    const icsEvents = events.map((event) => {
      const startDate = new Date(event.startDate);
      const endDate = new Date(event.endDate);

      const start = [
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        startDate.getDate(),
        startDate.getHours(),
        startDate.getMinutes(),
      ];

      const end = [
        endDate.getFullYear(),
        endDate.getMonth() + 1,
        endDate.getDate(),
        endDate.getHours(),
        endDate.getMinutes(),
      ];

      return {
        start,
        end,
        title: event.title,
        uid: `${event.title}-${start.join('')}`,
        // description: `Erstellt von ${event.createdBy} am ${event.createdAt}`,
      };
    });

    return createEvents(icsEvents);
  };

  const downloadIcsFile = () => {
    const result = convertEventsToIcs();
    if (result.value) {
      const blob = new Blob([result.value], { type: 'text/plain;charset=utf-8' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'events.ics');
      document.body.appendChild(link);
      link.click();

      // Remember to revoke the blob URL to free up memory
      URL.revokeObjectURL(url);
    } else {
      console.error(result.error);
    }
  };

  return (
    <button type="button" onClick={downloadIcsFile}>
      Download ICS File
    </button>
  );
}

DownloadIcsButton.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

import React from 'react';

const SocketContext = React.createContext({});
const ThemeContext = React.createContext({});
const LocalizationContext = React.createContext({});
const UserContext = React.createContext({});

export {
  SocketContext,
  ThemeContext,
  LocalizationContext,
  UserContext,
};

import React, {
  useContext, useState, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Send from '@mui/icons-material/Send';
import Edit from '@mui/icons-material/Edit';
import Save from '@mui/icons-material/Save';
import Delete from '@mui/icons-material/Delete';
import Cancel from '@mui/icons-material/Cancel';

import { LocalizationContext, SocketContext, UserContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import hasWriteAccess from '../../../../util/hasWriteAccess';
import localization from './Messages.local';

const SX_BUTTON = {
  p: 0.25,
  mr: 2,
  '&:hover': {
    color: 'primary.main',
  },
};

export default function Messages({
  cities,
  city,
  district,
  messages,
  users,
  onSetMessages,
}) {
  const local = localization[useContext(LocalizationContext)];
  const user = useContext(UserContext);
  const socket = useContext(SocketContext);
  const [text, setText] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);
  const [alert, setAlert] = useState();
  const [scrollTop, setScrollTop] = useState(0);
  const [edit, setEdit] = useState();
  const [editText, setEditText] = useState('');
  const messageBoxEl = useRef();

  useEffect(() => {
    if (city) {
      setSelectedCity(city);
    }
  }, [city]);

  const onPost = () => {
    const message = {
      text,
      city: selectedCity.id,
      district: selectedCity.district,
      category: 1,
    };
    socket.emit('messages.post', message, ({ error, payload }) => {
      setAlert(error || 200);
      if (!error) {
        onSetMessages(messages ? [...messages, payload] : [payload]);
        setText('');
      }
    });
  };

  const setScrollPosition = () => {
    if (messageBoxEl.current && messageBoxEl.current.scrollHeight !== scrollTop) {
      messageBoxEl.current.scrollTop = messageBoxEl.current.scrollHeight;
      setScrollTop(messageBoxEl.current.scrollHeight);
    }
  };

  const onSave = (message) => {
    const patch = {
      id: message.id,
      district: selectedCity.district,
      city: selectedCity.id,
      field: 'text',
      value: editText,
    };
    socket.emit('messages.patch', patch, ({ error, payload }) => {
      setAlert(error || 200);
      if (!error) {
        onSetMessages(messages.map((item) => (item.id === payload.id ? payload : item)));
        setEditText('');
        setEdit();
      }
    });
  };
  const onCancel = () => {
    setEditText('');
    setEdit();
  };
  const onEdit = (message) => {
    setEdit(message.id);
    setEditText(message.text);
  };
  const onDelete = (message) => {
    socket.emit('messages.destroy', { id: message.id, district: message.district, city: message.city }, ({ error, payload }) => {
      setAlert(error || 200);
      if (!error) {
        onSetMessages(messages.filter((item) => (item.id !== payload.id)));
      }
    });
  };

  return (
    <Paper sx={{ height: '100%', width: '100%' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      { cities && messages ? (
        <Box>
          <Box sx={{ p: 1, height: 86 }}>
            { city ? (
              <Typography variant="h5">{`${city.status} ${city.name}`}</Typography>
            ) : (
              <Autocomplete
                value={selectedCity}
                options={cities.filter((item) => (district ? item.district === district.id : true))}
                onChange={(e, selected) => setSelectedCity(selected)}
                getOptionLabel={((option) => `${option.status} ${option.name}`)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="outlined"
                    color="secondary"
                    margin="dense"
                    label={local.city}
                  />
                )}
              />
            )}
          </Box>
          <Box ref={messageBoxEl} sx={{ overflowY: 'auto', height: 'calc(100vh - 390px);' }}>
            { selectedCity && users ? (
              messages
                .filter((item) => (item.city === selectedCity.id))
                .sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
                .map((message, index, array) => {
                  const messager = users.find((item) => item.id === message.creator);
                  return (
                    <Box
                      key={message.id}
                      display="flex"
                      justifyContent={message.creator === user.id ? 'flex-end' : 'flex-start'}
                      sx={{ p: 1 }}
                    >
                      <Box display="flex" flexDirection="column">
                        { messager.id === user.id && edit !== message.id && (
                          <Button color="secondary" onClick={() => onEdit(message)} sx={SX_BUTTON}>
                            <Edit sx={{ mr: 1 }} />
                            <Typography variant="caption">{local.edit}</Typography>
                            <Box sx={{ flexGrow: 1 }} />
                          </Button>
                        )}
                        { messager.id === user.id && edit !== message.id && (
                          <Button color="secondary" onClick={() => onDelete(message)} sx={SX_BUTTON}>
                            <Delete sx={{ mr: 1 }} />
                            <Typography variant="caption">{local.delete}</Typography>
                            <Box sx={{ flexGrow: 1 }} />
                          </Button>
                        )}
                        { messager.id === user.id && edit === message.id && (
                          <Button color="secondary" onClick={() => onSave(message)} sx={SX_BUTTON}>
                            <Save sx={{ mr: 1 }} />
                            <Typography variant="caption">{local.save}</Typography>
                            <Box sx={{ flexGrow: 1 }} />
                          </Button>
                        )}
                        { messager.id === user.id && edit === message.id && (
                          <Button color="secondary" onClick={() => onCancel(message)} sx={SX_BUTTON}>
                            <Cancel sx={{ mr: 1 }} />
                            <Typography variant="caption">{local.cancel}</Typography>
                            <Box sx={{ flexGrow: 1 }} />
                          </Button>
                        )}
                      </Box>
                      <Box sx={{ textAlign: message.creator === user.id ? 'right' : 'left' }}>
                        <Paper sx={{ p: 1 }}>
                          {edit === message.id ? (
                            <TextField multiline value={editText} onChange={(e) => setEditText(e.target.value)} />
                          ) : (
                            <Typography>{message.text}</Typography>
                          )}
                        </Paper>
                        <Typography variant="caption">
                          {`${messager ? `${messager.firstname} ${messager.lastname},` : ''} ${moment(message.createdAt).format('DD.MM.YYYY - HH:mm')}`}
                        </Typography>
                      </Box>
                      { (index + 1) === array.length && setScrollPosition() }
                    </Box>
                  );
                })
            ) : (
              <Typography sx={{ p: 2 }}>{local.noCitySelected}</Typography>
            )}
          </Box>
          <Box sx={{ p: 1 }}>
            <Grid container>
              <Grid item xs={12} md={10}>
                <TextField
                  fullWidth
                  color="secondary"
                  margin="dense"
                  label={local.message}
                  value={text}
                  disabled={!selectedCity || !hasWriteAccess(user, selectedCity.district, selectedCity.id)}
                  onChange={(e) => setText(e.target.value)}
                  sx={{ pl: 1, pr: 1 }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!selectedCity || !hasWriteAccess(user, selectedCity.district, selectedCity.id)}
                  onClick={onPost}
                  sx={{ mt: 1, height: 56 }}
                >
                  <Send sx={{ mr: 2 }} />
                  <Typography fontWeight={900}>{local.post}</Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Skeleton variant="rect" sx={{ height: '100%', width: '100%' }} />
      )}
    </Paper>
  );
}

Messages.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    name: PropTypes.string,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onSetMessages: PropTypes.func.isRequired,
};

Messages.defaultProps = {
  cities: undefined,
  city: undefined,
  district: undefined,
  messages: undefined,
  users: undefined,
};

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext } from '../../../../AppContext';

import ColumnDefinition from './ElectionresultsTableColumnDefinition';
import RowsMapper from './ElectionresultsTableRowsMapper';
import Toolbar from './ElectionresultsTableToolbar';
import localization from './ElectionresultsTable.local';

const ELECTIONTYPE_PRESIDENT = 5;

export default function ElectionresultsTable({
  cities,
  city,
  district,
  districts,
  elections,
  electiontypes,
  parties,
  presidents,
  onRowClick,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [group, setGroup] = useState();
  const [electionresultsActive, setElectionresultsActive] = useState();
  const [partyColumns, setPartyColumns] = useState();
  const [partyRows, setPartyRows] = useState();
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();

  useEffect(() => {
    if (elections && electiontypes && parties) {
      const newElectionresultsActive = elections.filter((item) => {
        const isCity = city ? city.id === item.city : true;
        const isDistrict = district ? district.id === item.district : true;
        const type = electiontypes.find((entry) => entry.id === item.type);
        return isCity && isDistrict && type.active;
      });
      const newPartyColumns = [];
      const newPartyRows = {};
      newElectionresultsActive.forEach((electionresult) => {
        if (!newPartyRows[electionresult.id]) {
          newPartyRows[electionresult.id] = {};
        }
        electionresult.parties.forEach((partyresult) => {
          let party;
          if (electionresult.type === ELECTIONTYPE_PRESIDENT) {
            const president = presidents.find((item) => item.id === partyresult.party);
            party = president.party ? parties.find((item) => item.id === president.party) : president;
          } else {
            party = parties.find((item) => item.id === partyresult.party);
          }
          const newParty = group && party.parent ? parties.find((item) => item.id === party.parent) : party;
          if (partyresult.votes > 0) {
            if (!newPartyColumns.includes(newParty.name)) {
              newPartyColumns.push(newParty.name);
            }
            if (newPartyRows[electionresult.id][newParty.name]) {
              newPartyRows[electionresult.id][newParty.name] += partyresult.votes;
            } else {
              newPartyRows[electionresult.id][newParty.name] = partyresult.votes;
            }
          }
        });
      });
      setElectionresultsActive(newElectionresultsActive);
      setPartyColumns(newPartyColumns);
      setPartyRows(newPartyRows);
    }
  }, [city, district, elections, electiontypes, group, parties]);

  useEffect(() => setColumns(ColumnDefinition(local, city, district, partyColumns)), [city, district, partyColumns]);
  useEffect(() => setRows(RowsMapper(
    local,
    cities,
    city,
    district,
    districts,
    electionresultsActive,
    electiontypes,
    partyRows,
  )), [
    cities,
    city,
    district,
    districts,
    electionresultsActive,
    electiontypes,
    partyRows,
  ]);

  return (
    <Paper sx={{ height: '100%' }}>
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar }}
          componentsProps={{ toolbar: { local, group, onSetGroup: setGroup } }}
        />
      )}
    </Paper>
  );
}

ElectionresultsTable.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  electiontypes: PropTypes.arrayOf(PropTypes.shape({})),
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  presidents: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func.isRequired,
};

ElectionresultsTable.defaultProps = {
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
  elections: undefined,
  electiontypes: undefined,
  parties: undefined,
  presidents: undefined,
};

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Officialcategories from './officialcategories/Officialcategories';
import Officialtypes from './officialtypes/Officialtypes';

export default function Officials({
  officialcategories,
  officials,
  officialtypes,
  onSetOfficialcategories,
  onSetOfficialtypes,
}) {
  return (
    <Routes>
      <Route
        path="categories/*"
        element={(
          <Officialcategories
            officialcategories={officialcategories}
            officials={officials}
            onSetOfficialcategories={onSetOfficialcategories}
          />
        )}
      />
      <Route
        path="types/*"
        element={(
          <Officialtypes
            officials={officials}
            officialtypes={officialtypes}
            onSetOfficialtypes={onSetOfficialtypes}
          />
        )}
      />
      <Route path="*" element={<Navigate to="types" />} />
    </Routes>
  );
}

Officials.propTypes = {
  officialcategories: PropTypes.arrayOf(PropTypes.shape({})),
  officials: PropTypes.arrayOf(PropTypes.shape({})),
  officialtypes: PropTypes.arrayOf(PropTypes.shape({})),
  onSetOfficialcategories: PropTypes.func.isRequired,
  onSetOfficialtypes: PropTypes.func.isRequired,
};

Officials.defaultProps = {
  officialcategories: undefined,
  officials: undefined,
  officialtypes: undefined,
};

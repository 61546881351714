import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ReportdefinitionsPatch from './ReportdefinitionsPatch';
import ReportdefinitionsTable from './ReportdefinitionsTable';

export default function Reportdefinitions({
  citysettingdefinitions,
  reportdefinitions,
  reports,
  onSetReportdefinitions,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path=":reportdefinitionid"
        element={(
          <ReportdefinitionsPatch
            citysettingdefinitions={citysettingdefinitions}
            reportdefinitions={reportdefinitions}
            reports={reports}
            onBack={() => navigate('/settings/reports/definitions')}
            onSetReportdefinitions={onSetReportdefinitions}
          />
            )}
      />
      <Route
        path="/"
        element={(
          <ReportdefinitionsTable
            reportdefinitions={reportdefinitions}
            reports={reports}
            onRowClick={(id) => navigate(`/settings/reports/definitions/${id}`)}
            onAddClick={() => navigate('/settings/reports/definitions/post')}
            onSetReportdefinitions={onSetReportdefinitions}
            onStatusesClick={() => navigate('/settings/reports/statuses')}
          />
        )}
      />
    </Routes>
  );
}

Reportdefinitions.propTypes = {
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  reportdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  reports: PropTypes.arrayOf(PropTypes.shape({})),
  onSetReportdefinitions: PropTypes.func.isRequired,
};

Reportdefinitions.defaultProps = {
  citysettingdefinitions: undefined,
  reportdefinitions: undefined,
  reports: undefined,
};

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext } from '../../../../AppContext';
import Toolbar from './LogsToolbar';
import ColumnDefinition from './LogsColumnDefinition';
import RowsMapper from './LogsRowsMapper';
import localization from './Logs.local';

export default function Logs({
  cities,
  districts,
  logs,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();

  useEffect(() => setColumns(ColumnDefinition(local)), []);
  useEffect(() => setRows(RowsMapper(local, logs, cities, districts)), [logs, cities, districts]);

  return (
    <Paper sx={{ height: '100%' }}>
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          components={{ Toolbar }}
        />
      )}
    </Paper>
  );
}

Logs.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  logs: PropTypes.arrayOf(PropTypes.shape({})),
};

Logs.defaultProps = {
  cities: undefined,
  districts: undefined,
  logs: undefined,
};

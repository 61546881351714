import hasSteeringAccess from '../../../../util/hasSteeringAccess';
import {
  percentValueFormatter,
  numberValueFormatter,
  dateValueFormatter,
  booleanValueFormatter,
} from '../../../../util/formatter';

function officialColDef(local, official) {
  const def = [{
    type: 'string',
    field: `${official}Name`,
    headerName: local[`${official}Name`],
    minWidth: 250,
    flex: 2,
  }, {
    type: 'string',
    field: `${official}Party`,
    headerName: local[`${official}Party`],
    minWidth: 150,
    flex: 1,
  }, {
    type: 'date',
    field: `${official}Birthdate`,
    headerName: local[`${official}Birthdate`],
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: `${official}Email`,
    headerName: local[`${official}Email`],
    minWidth: 250,
    flex: 2,
  }, {
    type: 'string',
    field: `${official}Phone`,
    headerName: local[`${official}Phone`],
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: `${official}Mail`,
    headerName: local[`${official}Mail`],
    minWidth: 150,
    flex: 1,
  }, {
    type: 'date',
    field: `${official}Since`,
    headerName: local[`${official}Since`],
    minWidth: 150,
    flex: 1,
  }];
  return def;
}

export default function CitiesColumnDefinition(
  local,
  citysettingdefinitions,
  district,
  user,
) {
  let colDef;
  if (citysettingdefinitions && user) {
    colDef = [{
      type: 'string',
      field: 'name',
      headerName: local.name,
      minWidth: 400,
      flex: 3,
    }, {
      type: 'string',
      field: 'status',
      headerName: local.status,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'string',
      field: 'highestOffice',
      headerName: local.highestOffice,
      minWidth: 400,
      flex: 3,
    }];
    if (user.control || (district && hasSteeringAccess(user, district.id))) {
      const typeMapping = {
        TEXT: 'string',
        DROPDOWN: 'string',
        BOOLEAN: 'string',
        NUMBER: 'number',
        DATE: 'date',
      };
      const widthMapping = {
        TEXT: 400,
        DROPDOWN: 250,
        BOOLEAN: 150,
        NUMBER: 150,
        DATE: 150,
      };
      const formatterMapping = {
        TEXT: undefined,
        DROPDOWN: undefined,
        BOOLEAN: booleanValueFormatter,
        NUMBER: numberValueFormatter,
        DATE: dateValueFormatter,
      };
      colDef.push(...citysettingdefinitions.filter((item) => item.active).sort((a, b) => a.sort - b.sort).map((item) => ({
        type: typeMapping[item.type],
        field: item.name,
        headerName: item.name,
        minWidth: widthMapping[item.type],
        valueFormatter: formatterMapping[item.type],
        flex: 3,
      })));
    }
    colDef.push(...[{
      type: 'string',
      field: 'absolute',
      headerName: local.absolute,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'mandates',
      headerName: local.mandates,
      valueFormatter: numberValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'mandatesSpo',
      headerName: local.mandatesSpo,
      valueFormatter: numberValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'mandatesSpoPercent',
      headerName: local.mandatesSpoPercent,
      valueFormatter: percentValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'mandatesOvp',
      headerName: local.mandatesOvp,
      valueFormatter: numberValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'mandatesOvpPercent',
      headerName: local.mandatesOvpPercent,
      valueFormatter: percentValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'mandatesFpo',
      headerName: local.mandatesFpo,
      valueFormatter: numberValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'mandatesFpoPercent',
      headerName: local.mandatesFpoPercent,
      valueFormatter: percentValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'officials',
      headerName: local.officials,
      valueFormatter: numberValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'officialsFemale',
      valueFormatter: numberValueFormatter,
      headerName: local.officialsFemale,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'officialsFemalePercent',
      headerName: local.officialsFemalePercent,
      valueFormatter: percentValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'citizens',
      headerName: local.citizens,
      valueFormatter: numberValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'members',
      valueFormatter: numberValueFormatter,
      headerName: local.members,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'membersAverageAge',
      valueFormatter: numberValueFormatter,
      headerName: local.membersAverageAge,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'officialsAverageAge',
      valueFormatter: numberValueFormatter,
      headerName: local.officialsAverageAge,
      minWidth: 150,
      flex: 1,
    }]);
    colDef.push(...officialColDef(local, 'mayor'));
    colDef.push(...officialColDef(local, 'vicemayor'));
    if (!district) {
      colDef.unshift({
        type: 'string',
        field: 'district',
        headerName: local.district,
        minWidth: 180,
        flex: 1,
      });
    }
    colDef.unshift({
      type: 'string',
      field: 'gkz',
      headerName: local.gkz,
      minWidth: 60,
      flex: 1,
    });
  }
  return colDef;
}

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';

import App from './App';

import '@fontsource/roboto';

LicenseInfo.setLicenseKey('f53e8a2438001b8ce3bfdd5f9f8952d1Tz01NDc3NyxFPTE3MDA2NzQ1NTY1NDIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.querySelector('#root'),
);

import React from 'react';
import PropTypes from 'prop-types';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function ElectionresultsTableToolbar({ local, group, onSetGroup }) {
  return (
    <GridToolbarContainer>
      <Button
        variant="text"
        size="small"
        color={group ? 'primary' : 'secondary'}
        onClick={() => onSetGroup(!group)}
      >
        {local.group}
      </Button>
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{ delimiter: ';', utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}

ElectionresultsTableToolbar.propTypes = {
  local: PropTypes.shape({
    group: PropTypes.string,
  }).isRequired,
  group: PropTypes.bool,
  onSetGroup: PropTypes.func.isRequired,
};

ElectionresultsTableToolbar.defaultProps = {
  group: false,
};

import { dateValueFormatter, numberValueFormatter } from '../../../../../util/formatter';

export default function AgreementcategoriesTableColumnDefinition(local) {
  return [{
    type: 'string',
    field: 'name',
    headerName: local.name,
    minWidth: 200,
    flex: 2,
  }, {
    type: 'string',
    field: 'description',
    headerName: local.description,
    minWidth: 200,
    flex: 2,
  }, {
    type: 'boolean',
    field: 'active',
    headerName: local.active,
    minWidth: 100,
    flex: 1,
  }, {
    type: 'number',
    field: 'activeAgreements',
    headerName: local.activeAgreements,
    valueFormatter: numberValueFormatter,
    minWidth: 100,
    flex: 1,
  }, {
    type: 'number',
    field: 'inactiveAgreements',
    headerName: local.inactiveAgreements,
    valueFormatter: numberValueFormatter,
    minWidth: 100,
    flex: 1,
  }, {
    type: 'date',
    field: 'updatedAt',
    headerName: local.updatedAt,
    valueFormatter: dateValueFormatter,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'editor',
    headerName: local.editor,
    minWidth: 150,
    flex: 2,
  }];
}

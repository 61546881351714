import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ServicecategoriesPost from './ServicecategoriesPost';
import ServicecategoriesPatch from './ServicecategoriesPatch';
import ServicecategoriesTable from './ServicecategoriesTable';

export default function Servicecategories({
  elections,
  servicecategories,
  onSetServicecategories,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <ServicecategoriesPost
            elections={elections}
            servicecategories={servicecategories}
            onBack={() => navigate('/settings/services/categories')}
            onSetServicecategories={onSetServicecategories}
          />
        )}
      />
      <Route
        path=":servicecategoryid"
        element={(
          <ServicecategoriesPatch
            elections={elections}
            servicecategories={servicecategories}
            onBack={() => navigate('/settings/services/categories')}
            onSetServicecategories={onSetServicecategories}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <ServicecategoriesTable
            elections={elections}
            servicecategories={servicecategories}
            onRowClick={(id) => navigate(`/settings/services/categories/${id}`)}
            onAddClick={() => navigate('/settings/services/categories/post')}
            onStatusesClick={() => navigate('/settings/services/statues')}
          />
        )}
      />
    </Routes>
  );
}

Servicecategories.propTypes = {
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  servicecategories: PropTypes.arrayOf(PropTypes.shape({})),
  onSetServicecategories: PropTypes.func.isRequired,
};

Servicecategories.defaultProps = {
  elections: undefined,
  servicecategories: undefined,
};

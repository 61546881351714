import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';

export default function Citysetting({
  city,
  initialCitysetting,
  citySettingDefinition,
  onChange,
}) {
  const [citySetting, setCitySetting] = useState(initialCitysetting ? initialCitysetting.value : '');
  const [typingTimeout, setTypingTimeout] = useState();

  useEffect(() => setCitySetting(initialCitysetting ? initialCitysetting.value : ''), [initialCitysetting]);

  const onTextChange = (e) => {
    clearTimeout(typingTimeout);
    setCitySetting(e.target.value);
    setTypingTimeout(setTimeout(() => onChange(city, initialCitysetting, e.target.value), 1000));
  };

  if (citySettingDefinition.type === 'BOOLEAN') {
    return (
      <FormControlLabel
        sx={{ p: 1, pt: 2 }}
        label={city.name}
        control={(
          <Checkbox
            checked={parseInt(citySetting, 10) === 1}
            onChange={(e) => onChange(city, initialCitysetting, e.target.checked ? '1' : '0')}
          />
    )}
      />
    );
  }
  if (citySettingDefinition.type === 'DROPDOWN') {
    return (
      <Autocomplete
        value={citySetting ? citySettingDefinition.options.find((item) => item.id.toString() === citySetting.toString()) : null}
        options={citySettingDefinition.options}
        onChange={(e, selected) => onChange(city, citySettingDefinition, selected.id)}
        getOptionLabel={((option) => option.name)}
        renderInput={(params) => (
          <TextField
            fullWidth
        // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            color="secondary"
            margin="dense"
            label={city.name}
          />
        )}
      />
    );
  }
  if (citySettingDefinition.type === 'NUMBER') {
    return (
      <TextField
        fullWidth
        variant="outlined"
        color="secondary"
        margin="dense"
        type={citySettingDefinition.type}
        label={city.name}
        value={citySetting ? parseInt(citySetting, 10) : ''}
        onChange={onTextChange}
      />
    );
  }
  return (
    <TextField
      fullWidth
      variant="outlined"
      color="secondary"
      margin="dense"
      type={citySettingDefinition.type}
      label={city.name}
      value={citySetting}
      onChange={onTextChange}
    />
  );
}

Citysetting.propTypes = {
  city: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  initialCitysetting: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  citySettingDefinition: PropTypes.shape({
    type: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

Citysetting.defaultProps = {
  initialCitysetting: undefined,
};

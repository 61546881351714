import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uuid } from 'uuidv4';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { LocalizationContext } from '../../../../../AppContext';
import {
  BOOLEAN, DATE, DROPDOWN, NUMBER, TEXT, SETTING,
} from '../../../../../config/reportelementtypes';
import localization from './ReportdefinitionsPatchViewValues.local';

export default function ReportdefinitionsPatchViewValues({
  citysettingdefinitions,
  expanded,
  question,
  onChange,
  onDestroy,
  onSetExpanded,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [text, setText] = useState('');
  const [list, setList] = useState();
  const [type, setType] = useState(null);
  const [definition, setDefinition] = useState(null);
  const [options, setOptions] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState();

  useEffect(() => {
    if (question) {
      setText(question.text);
      setType(question.type);
      setDefinition(question.definition);
      if (question.list) {
        const listValues = {};
        question.list.forEach((listitem, index) => {
          listValues[index] = listitem;
        });
        setList(listValues);
      }
      if (question.options) {
        const optionValues = {};
        question.options.forEach((option) => {
          optionValues[option.id] = option.name;
        });
        setOptions(optionValues);
      }
    }
  }, [question]);

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange({ ...question, [field]: value }), 1000));
  };

  const onListChange = (index, value) => {
    setList({ ...list, [index]: value });
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(
      { ...question, list: question.list.map((item, itemindex) => (itemindex === index ? value : item)) },
    ), 1000));
  };

  const onOptionChange = (id, value) => {
    setOptions({ ...options, [id]: value });
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(
      { ...question, options: question.options.map((item) => (item.id === id ? { ...item, name: value } : item)) },
    ), 1000));
  };

  return (
    <Accordion
      expanded={question && question.key === expanded}
      onChange={(e, isExpanded) => (isExpanded ? onSetExpanded(question.key) : onSetExpanded())}
    >
      <AccordionSummary>
        <Typography>{text && text !== '' ? text : local.noText}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="secondary" onClick={() => onDestroy(question)} sx={{ m: 0 }}><DeleteIcon /></Button>
      </AccordionSummary>
      <AccordionDetails>
        { question && question.key === expanded && (
          <Box>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.text}
              value={text}
              onChange={(e) => onTextChange(setText, 'text', e.target.value)}
            />
            {list && question.list && question.list.map((listitem, index) => (
              <Box display="flex" key={listitem}>
                <Box
                  sx={{
                    mr: 1, mt: 4, height: 5, width: 5, borderRadius: '25px', backgroundColor: 'primary.main',
                  }}
                />
                <TextField
                  fullWidth
                  multiline
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  sx={{ flexGrow: 1 }}
                  label={local.listitem}
                  value={list[index]}
                  onChange={(e) => onListChange(index, e.target.value)}
                />
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => onChange({ ...question, list: question.list.filter((item) => item !== listitem) })}
                >
                  <DeleteIcon />
                </Button>
              </Box>
            ))}
            <Box display="flex">
              <Box
                sx={{
                  mr: 1,
                  mt: 3,
                  height: 5,
                  width: 5,
                  borderRadius: '25px',
                  backgroundColor: 'primary.main',
                }}
              />
              <Box sx={{ flexGrow: 1 }}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  sx={{ mt: 1, mb: 1 }}
                  onClick={() => onChange({
                    ...question,
                    list: question.list
                      ? [...question.list, `${local.listitem} ${question.list.length + 1}`]
                      : [`${local.listitem} ${question.list.length + 1}`],
                  })}
                >
                  <AddIcon />
                  <Typography sx={{ ml: 1 }}>{local.addListitem}</Typography>
                </Button>
              </Box>
            </Box>
            <Autocomplete
              disableClearable
              value={type}
              options={[BOOLEAN, DATE, DROPDOWN, NUMBER, TEXT, SETTING]}
              getOptionLabel={((option) => local[option])}
              onChange={(e, selected) => onChange({ ...question, type: selected })}
              renderInput={(params) => (
                <TextField
                  fullWidth
                        // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.type}
                />
              )}
            />
            { type === DROPDOWN && options && question.options && question.options.map((option) => (
              <Box display="flex" key={option.id}>
                <TextField
                  fullWidth
                  multiline
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  sx={{ flexGrow: 1 }}
                  label={local.option}
                  value={options[option.id]}
                  onChange={(e) => onOptionChange(option.id, e.target.value)}
                />
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => onChange({ ...question, options: question.options.filter((item) => item.id !== option.id) })}
                >
                  <DeleteIcon />
                </Button>
              </Box>
            ))}
            { type === DROPDOWN && (
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ mt: 1, mb: 1 }}
                onClick={() => onChange({
                  ...question,
                  options: question.options
                    ? [...question.options, { id: uuid(), name: local.newOption }]
                    : [{ id: uuid(), name: local.newOption }],
                })}
              >
                <AddIcon />
                <Typography sx={{ ml: 1 }}>{local.addOption}</Typography>
              </Button>
            )}
            { type === SETTING && citysettingdefinitions && (
              <Autocomplete
                disableClearable
                value={definition ? citysettingdefinitions.find((item) => item.id === parseInt(definition, 10)) : null}
                options={citysettingdefinitions}
                getOptionLabel={((option) => option.name)}
                onChange={(e, selected) => onChange({ ...question, definition: selected.id })}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                        // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="outlined"
                    color="secondary"
                    margin="dense"
                    label={local.definition}
                  />
                )}
              />
            )}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

ReportdefinitionsPatchViewValues.propTypes = {
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  expanded: PropTypes.string,
  question: PropTypes.shape({
    key: PropTypes.string,
    text: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.shape({})),
    type: PropTypes.string,
    definition: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onSetExpanded: PropTypes.func.isRequired,
};

ReportdefinitionsPatchViewValues.defaultProps = {
  citysettingdefinitions: undefined,
  expanded: undefined,
  question: undefined,
};

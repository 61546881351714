import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Avatar from '@mui/material/Avatar';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

import { UserContext, LocalizationContext } from '../../AppContext';
import localization from './AuthenticatedBar.local';

export default function AuthenticatedBar({ theme, onLogout, onThemeChange }) {
  const user = useContext(UserContext);
  const local = localization[useContext(LocalizationContext)];
  const [userMenuAnchor, setUserMenuAnchor] = useState();
  const navigate = useNavigate();

  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton sx={{ color: 'white' }} onClick={() => navigate('/')} edge="start">
          <Avatar
            variant="square"
            sx={{ width: parseInt(process.env.REACT_APP_LOGO_WIDTH, 10) * 9, height: parseInt(process.env.REACT_APP_LOGO_HEIGHT, 10) * 9 }}
            src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_LOGO}`}
          />
        </IconButton>
        <Hidden xsDown>
          <Button sx={{ color: 'white', fontWeight: 900 }} onClick={() => navigate('/')}>{process.env.REACT_APP_NAME}</Button>
        </Hidden>
        <div style={{ flexGrow: 1 }} />
        <Button sx={{ color: 'white', fontWeight: 900 }} onClick={onThemeChange}>
          { theme === 'light' ? <LightModeIcon /> : <DarkModeIcon /> }
          <Typography sx={{ ml: 1 }}>{local[theme]}</Typography>
        </Button>
        { user.admin && (
          <Button sx={{ color: 'white', fontWeight: 900, mr: 1 }} onClick={() => navigate('/settings')}>
            <SettingsIcon />
            <Typography sx={{ ml: 1 }}>{local.settings}</Typography>
          </Button>
        )}
        <Button sx={{ textTransform: 'unset' }} onClick={(e) => setUserMenuAnchor(e.currentTarget)}>
          <Avatar src={user.avatar} alt={`${user.firstname} ${user.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
          <Typography sx={{ color: 'white' }}>{`${user.firstname} ${user.lastname}`}</Typography>
          <ExpandMoreIcon sx={{ color: 'white' }} />
        </Button>
        <Menu
          getContentAnchorEl={null}
          anchorEl={userMenuAnchor}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(userMenuAnchor)}
          onClose={() => setUserMenuAnchor()}
        >
          <MenuItem disabled>
            <Typography>{`${user.firstname} ${user.lastname}`}</Typography>
          </MenuItem>
          <MenuItem disabled divider>
            <Typography>{user.email}</Typography>
          </MenuItem>
          <MenuItem divider onClick={() => navigate('/profile')}>
            <PersonIcon />
            <Typography sx={{ ml: 1 }}>{local.profile}</Typography>
          </MenuItem>
          <MenuItem onClick={onLogout}>
            <LogoutIcon />
            <Typography sx={{ ml: 1 }}>{local.logout}</Typography>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

AuthenticatedBar.propTypes = {
  theme: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  onThemeChange: PropTypes.func.isRequired,
};

export default {
  de: {
    title: 'Bericht bearbeiten',
    status: 'Status',
    definition: 'Berichtart',
    true: 'Ja',
    false: 'Nein',
    date: 'Datum',
    submit: 'Ziel erstellen',
    createdAt: 'Erstellt am ',
    creator: 'erstellt von',
    editor: 'zuletzt bearbeitet von',
    from: 'von',
    updatedAt: 'Zuletzt verändert am ',
    destroy: 'Löschen',
    archive: 'Archivieren',
    restore: 'Wiederherstellen',
    back: 'Zurück',
    destroyTitle: 'Möchten Sie den Bericht wirklich löschen?',
    destroyDescription: '',
    agree: 'Löschen',
    disagree: 'Abbrechen',
    pdfview: 'PDF-Ansicht',
    webview: 'WEB-Ansicht',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

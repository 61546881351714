import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ReportsPost from './ReportsPost';
import ReportsPatch from './ReportsPatch';
import ReportsTable from './ReportsTable';

export default function Reports({
  cities,
  city,
  citysettingdefinitions,
  citysettings,
  district,
  districts,
  reportdefinitions,
  reports,
  reportstatuses,
  users,
  onSetCitysettings,
  onSetReports,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <ReportsPost
            city={city}
            reportdefinitions={reportdefinitions}
            reports={reports}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/reports`)}
            onSetReports={onSetReports}
          />
        )}
      />
      <Route
        path=":reportid"
        element={(
          <ReportsPatch
            cities={cities}
            city={city}
            citysettingdefinitions={citysettingdefinitions}
            citysettings={citysettings}
            district={district}
            districts={districts}
            reportdefinitions={reportdefinitions}
            reports={reports}
            reportstatuses={reportstatuses}
            users={users}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/reports`)}
            onSetCitysettings={onSetCitysettings}
            onSetReports={onSetReports}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <ReportsTable
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            reportdefinitions={reportdefinitions}
            reports={reports}
            reportstatuses={reportstatuses}
            users={users}
            onRowClick={(id) => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/reports/${id}`)}
            onAddClick={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/reports/post`)}
          />
        )}
      />
    </Routes>
  );
}

Reports.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  citysettings: PropTypes.arrayOf(PropTypes.shape({})),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  reportdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  reports: PropTypes.arrayOf(PropTypes.shape({})),
  reportstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onSetCitysettings: PropTypes.func.isRequired,
  onSetReports: PropTypes.func.isRequired,
};

Reports.defaultProps = {
  cities: undefined,
  city: undefined,
  citysettingdefinitions: undefined,
  citysettings: undefined,
  district: undefined,
  districts: undefined,
  reportdefinitions: undefined,
  reports: undefined,
  reportstatuses: undefined,
  users: undefined,
};

export default function AgreementsTableRowsMapper(
  local,
  cities,
  city,
  district,
  districts,
  electionresults,
  electiontypes,
  partyRows,
) {
  let rows;
  if (cities && districts && electionresults && electiontypes && partyRows) {
    rows = electionresults.map((row) => {
      const rowDistrict = district || districts.find((item) => item.id === row.district);
      const rowCity = city || cities.find((item) => item.id === row.city);
      const rowType = electiontypes.find((item) => item.id === row.type);
      const result = {
        id: row.id,
        district: rowDistrict ? rowDistrict.name : '',
        city: rowCity ? rowCity.name : '',
        type: rowType ? rowType.name : '',
        date: row.date,
        eligible: row.eligible,
        votes: row.votes,
        valid: row.valid,
        invalid: row.invalid,
      };
      Object.keys(partyRows[row.id]).forEach((key) => {
        result[key] = partyRows[row.id][key];
        result[`${key}-percent`] = Math.round((partyRows[row.id][key] / row.valid) * 10000) / 100;
      });
      return result;
    });
  }
  return rows;
}

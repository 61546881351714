import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import localization from './UsersPost.local';

export default function UsersPost({
  cities,
  districts,
  users,
  onBack,
  onSetUsers,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [admin, setAdmin] = useState(false);
  const [control, setControl] = useState(false);
  const [services, setServices] = useState(false);
  const [steering, setSteering] = useState([]);
  const [coaching, setCoaching] = useState([]);
  const [watching, setWatching] = useState([]);
  const [alert, setAlert] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    const user = {
      firstname, lastname, email, admin, control, services, steering, coaching, watching,
    };
    socket.emit('users.post', user, ({ error, payload }) => {
      setAlert(error || 200);
      if (!error) {
        onSetUsers(users ? [...users, payload] : [payload]);
        onBack();
      }
    });
  };

  return (
    <Paper component="form" sx={{ height: '100%', p: 5 }} onSubmit={onSubmit}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h4">{local.title}</Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      { cities && districts ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.firstname}
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.lastname}
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type="email"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              sx={{ p: 1, pt: 2 }}
              label={local.admin}
              control={(
                <Checkbox
                  checked={admin}
                  onChange={(e) => setAdmin(e.target.checked)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              sx={{ p: 1, pt: 2 }}
              label={local.control}
              control={(
                <Checkbox
                  checked={control}
                  onChange={(e) => setControl(e.target.checked)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              sx={{ p: 1, pt: 2 }}
              label={local.services}
              control={(
                <Checkbox
                  checked={services}
                  onChange={(e) => setServices(e.target.checked)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={districts}
              getOptionLabel={((option) => option.name)}
              value={districts.filter((district) => steering.includes(district.id))}
              onChange={(e, selected) => setSteering(selected.map((item) => item.id))}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.steering}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={cities}
              getOptionLabel={((option) => option.name)}
              value={cities.filter((city) => coaching.includes(city.id))}
              onChange={(e, selected) => setCoaching(selected.map((item) => item.id))}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.coaching}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={cities}
              getOptionLabel={((option) => option.name)}
              value={cities.filter((city) => watching.includes(city.id))}
              onChange={(e, selected) => setWatching(selected.map((item) => item.id))}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.watching}
                />
              )}
            />
          </Grid>
        </Grid>
      ) : (
        <Skeleton variant="rect" height="60%" width="100%" />
      )}
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        <Fab
          type="submit"
          variant="extended"
          color="primary"
          size="small"
          sx={{ justifyContent: 'flex-start' }}
        >
          <SaveIcon sx={{ mr: 1 }} />
          <Typography>{local.submit}</Typography>
        </Fab>
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

UsersPost.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSetUsers: PropTypes.func.isRequired,
};

UsersPost.defaultProps = {
  cities: undefined,
  districts: undefined,
  users: undefined,
};

export default {
  de: {
    title: 'Neue Partei hinzufügen',
    name: 'Name',
    submit: 'Partei erstellen',
    back: 'Zurück',
    alerts: {
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import PartiesPost from './PartiesPost';
import PartiesPatch from './PartiesPatch';
import PartiesTable from './PartiesTable';

export default function Parties({
  elections,
  parties,
  onSetParties,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <PartiesPost
            elections={elections}
            parties={parties}
            onBack={() => navigate('/settings/elections/parties')}
            onSetParties={onSetParties}
          />
        )}
      />
      <Route
        path=":partyid"
        element={(
          <PartiesPatch
            elections={elections}
            parties={parties}
            onBack={() => navigate('/settings/elections/parties')}
            onSetParties={onSetParties}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <PartiesTable
            elections={elections}
            parties={parties}
            onRowClick={(id) => navigate(`/settings/elections/parties/${id}`)}
            onAddClick={() => navigate('/settings/elections/parties/post')}
            onTypesClick={() => navigate('/settings/elections/types')}
            onPresidentsClick={() => navigate('/settings/elections/presidents')}
          />
        )}
      />
    </Routes>
  );
}

Parties.propTypes = {
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  onSetParties: PropTypes.func.isRequired,
};

Parties.defaultProps = {
  elections: undefined,
  parties: undefined,
};

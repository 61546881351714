import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import { SocketContext, UserContext, LocalizationContext } from '../../../AppContext';
import ImageUploader from '../../../components/ImageUploader';
import localization from './Profile.local';

export default function Profile({ onUpdateUser }) {
  const socket = useContext(SocketContext);
  const user = useContext(UserContext);
  const local = localization[useContext(LocalizationContext)];
  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState('');
  const [passwordVerify, setPasswordVerify] = useState('');
  const [avatar, setAvatar] = useState(user.avatar);
  const [typingTimeout, setTypingTimeout] = useState();

  const onAlert = (alert) => console.log(alert);
  const acknowledge = ({ error, payload }) => (error ? onAlert(error) : onUpdateUser(payload));
  const onChange = (field, value) => socket.emit('users.profile', { field, value }, acknowledge);

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  return (
    <Container sx={{ mt: 15 }}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h1" sx={{ mb: 2 }}>{local.title}</Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.firstname}
                value={firstname}
                onChange={(e) => onTextChange(setFirstname, 'firstname', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.lastname}
                value={lastname}
                onChange={(e) => onTextChange(setLastname, 'lastname', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="email"
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.email}
                value={email}
                onChange={(e) => onTextChange(setEmail, 'email', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.password}
                value={password}
                error={password !== passwordVerify}
                helperText={password !== passwordVerify ? local.passwordMustMatch : ''}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.passwordVerify}
                value={passwordVerify}
                error={password !== passwordVerify}
                helperText={password !== passwordVerify ? local.passwordMustMatch : ''}
                onChange={(e) => setPasswordVerify(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                sx={{ mb: 2, mt: 1 }}
                onClick={() => onChange('password', password)}
                disabled={password === '' || password !== passwordVerify}
              >
                {local.passwordChange}
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Avatar
                  src={avatar}
                  alt={`${firstname} ${lastname}`}
                  sx={{ height: 330, width: 330, mt: 2 }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                sx={{ mb: 2, mt: 5 }}
                onClick={() => onChange('avatar', avatar)}
                disabled={avatar === user.avatar}
              >
                {local.saveAvatar}
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6}>
            <ImageUploader
              type="base64"
              shape="round"
              showGrid={false}
              label={local.uploadImage}
              onError={(error) => onAlert(error)}
              onReturn={(base64) => {
                setAvatar(base64);
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

Profile.propTypes = {
  onUpdateUser: PropTypes.func.isRequired,
};

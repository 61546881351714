import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext } from '../../../../../AppContext';
import Toolbar from './ElectiontypesTableToolbar';
import ColumnDefinition from './ElectiontypesTableColumnDefinition';
import RowsMapper from './ElectiontypesTableRowsMapper';
import localization from './ElectiontypesTable.local';

export default function ElectiontypesTable({
  elections,
  electiontypes,
  onRowClick,
  onAddClick,
  onPartiesClick,
  onPresidentsClick,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();

  useEffect(() => setColumns(ColumnDefinition(local)), []);
  useEffect(() => setRows(RowsMapper(local, elections, electiontypes)), [elections, electiontypes]);

  return (
    <Paper sx={{ height: '100%' }}>
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar }}
          componentsProps={{
            toolbar: {
              local,
              onAddClick,
              onPartiesClick,
              onPresidentsClick,
            },
          }}
        />
      )}
    </Paper>
  );
}

ElectiontypesTable.propTypes = {
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  electiontypes: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onPartiesClick: PropTypes.func.isRequired,
  onPresidentsClick: PropTypes.func.isRequired,
};

ElectiontypesTable.defaultProps = {
  elections: undefined,
  electiontypes: undefined,
};

export default {
  de: {
    group: 'Parteien gruppieren',
    party: 'Partei',
    absolutes: 'Abs. Mehrheitsgemeinden',
    mayors: 'Bürgermeister*innen',
    vicemayors: 'Vizebürgermeister*innen',
    mandates: 'Mandate',
    cities: 'Gemeinden',
    citizens: 'Einwohner',
    members: 'Mitglieder',
    membersAverageAge: 'Mitglieder Ø-Alter',
    officials: 'SPÖ Gemeinderäte*rätinnen',
    officialsMale: 'SPÖ Gemeinderäte',
    officialsFemale: 'SPÖ Gemeinderätin',
    officialsAverageAge: 'SPÖ Gemeinderat*rätin Ø-Alter',
  },
};

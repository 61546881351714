import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext } from '../../../../../AppContext';
import Toolbar from './OfficialtypesTableToolbar';
import ColumnDefinition from './OfficialtypesTableColumnDefinition';
import RowsMapper from './OfficialtypesTableRowsMapper';
import localization from './OfficialtypesTable.local';

export default function OfficialtypesTable({
  officialtypes,
  // onRowClick,
  onAddClick,
  onCategoriesClick,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();

  useEffect(() => setColumns(ColumnDefinition(local)), []);
  useEffect(() => setRows(RowsMapper(local, officialtypes)), [officialtypes]);

  return (
    <Paper sx={{ height: '100%' }}>
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          // onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar }}
          componentsProps={{ toolbar: { local, onAddClick, onCategoriesClick } }}
        />
      )}
    </Paper>
  );
}

OfficialtypesTable.propTypes = {
  officialtypes: PropTypes.arrayOf(PropTypes.shape({})),
  // onRowClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onCategoriesClick: PropTypes.func.isRequired,
};

OfficialtypesTable.defaultProps = {
  officialtypes: undefined,
};

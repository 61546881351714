export default {
  de: {
    title: 'Berichtstatus bearbeiten',
    name: 'Name',
    destroy: 'Löschen',
    back: 'Zurück',
    destroyTitle: 'Möchten Sie diesen Berichtstatus wirklich löschen?',
    destroyDescription: '',
    agree: 'Löschen',
    disagree: 'Abbrechen',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

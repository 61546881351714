import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import CancelIcon from '@mui/icons-material/Cancel';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { LocalizationContext, SocketContext } from '../../../../../AppContext';
import Snackbar from '../../../../../components/Snackbar';
import localization from './AgreementcategoriesPatch.local';

export default function AgreementcategoriesPatch({
  agreementcategories,
  onBack,
  onSetAgreementcategories,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const { agreementcategoryid } = useParams();
  const [agreementcategory, setAgreementcategory] = useState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [editor, setEditor] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (agreementcategories && agreementcategoryid) {
      const foundAgreementcategory = agreementcategories.find((item) => item.id === parseInt(agreementcategoryid, 10));
      if (foundAgreementcategory) {
        setAgreementcategory(foundAgreementcategory);
      } else {
        onBack();
      }
    }
  }, [agreementcategories, agreementcategoryid]);

  useEffect(() => {
    if (agreementcategory) {
      setName(agreementcategory.name);
      setDescription(agreementcategory.description);
      socket.emit('users.getOneById', { id: agreementcategory.editor }, ({ error, payload }) => (error ? setAlert(error) : setEditor(payload)));
    }
  }, [agreementcategory]);

  const acknowledgeChange = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetAgreementcategories(agreementcategories ? agreementcategories.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
    }
  };

  const onChange = (field, value) => {
    const params = {
      id: agreementcategory.id,
      field,
      value,
    };
    socket.emit('agreementcategories.patch', params, acknowledgeChange);
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  return (
    <Paper sx={{ height: '100%', p: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h4">{local.title}</Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography>{local.placeholder}</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <TextField
            fullWidth
            variant="outlined"
            color="secondary"
            margin="dense"
            label={local.name}
            value={name}
            onChange={(e) => onTextChange(setName, 'name', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography>{local.placeholder}</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <TextField
            fullWidth
            multiline
            variant="outlined"
            color="secondary"
            margin="dense"
            label={local.description}
            value={description}
            onChange={(e) => onTextChange(setDescription, 'description', e.target.value)}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography>{local.updatedAt}</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography>{agreementcategory ? moment(agreementcategory.updatedAt).format('DD.MM.YYYY') : ''}</Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography>{local.updatedFrom}</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          { editor && (
          <Box display="flex">
            <Avatar src={editor.avatar} alt={`${editor.firstname} ${editor.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
            <Typography>{`${editor.firstname} ${editor.lastname}`}</Typography>
          </Box>
          )}
        </Grid>
      </Grid>
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        { agreementcategory && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => onChange('active', !agreementcategory.active)}
            sx={{ justifyContent: 'flex-start' }}
          >
            { agreementcategory.active ? <LockIcon /> : <LockOpenIcon /> }
            <Typography sx={{ ml: 1, mr: 1 }}>{agreementcategory.active ? local.lock : local.unlock}</Typography>
          </Fab>
        )}
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

AgreementcategoriesPatch.propTypes = {
  agreementcategories: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSetAgreementcategories: PropTypes.func.isRequired,
};

AgreementcategoriesPatch.defaultProps = {
  agreementcategories: undefined,
};

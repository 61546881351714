import moment from 'moment';

const ELECTION_TYPE_COUNCIL = 2;

export default function DistrictsRowsMapper(
  local,
  cities,
  districts,
  elections,
  officials,
) {
  let rows;
  if (cities && districts && elections && officials) {
    rows = districts
      .sort((a, b) => a.sort - b.sort)
      .map((district) => {
        const filtered = cities.filter((item) => item.district === district.id);

        let officialsMaleFemale = 0;
        let officialsFemale = 0;
        let citizens = 0;
        let members = 0;
        let membersAverageAge = 0;
        let officialsAverageAge = 0;

        let mandates = 0;
        let absolutes = 0;
        let mandatesSpo = 0;
        let mandatesOvp = 0;
        let mandatesFpo = 0;

        filtered.forEach((city) => {
          officialsMaleFemale += (city.officials ? (city.officials[Object.keys(city.officials).sort((a, b) => b - a)[0]] || 0) : 0);
          officialsFemale += (city.officialsFemale ? (city.officialsFemale[Object.keys(city.officialsFemale).sort((a, b) => b - a)[0]] || 0) : 0);
          citizens += (city.citizens ? (city.citizens[Object.keys(city.citizens).sort((a, b) => b - a)[0]] || 0) : 0);
          members += (city.members ? (city.members[Object.keys(city.members).sort((a, b) => b - a)[0]] || 0) : 0);
          membersAverageAge += (city.membersAverageAge ? (city.membersAverageAge[Object.keys(city.membersAverageAge).sort((a, b) => b - a)[0]] || 0) : 0);
          officialsAverageAge += (city.officialsAverageAge ? (city.officialsAverageAge[Object.keys(city.officialsAverageAge).sort((a, b) => b - a)[0]] || 0) : 0);

          const latestCouncilElection = elections
            .filter((election) => election.city === city.id && election.type === ELECTION_TYPE_COUNCIL)
            .sort((a, b) => moment(a).diff(moment(b)))[0];

          let cityMandates = 0;
          latestCouncilElection.parties.forEach((item) => { cityMandates += item.mandates; });
          latestCouncilElection.parties.forEach((item) => {
            if (item.party === 1 && item.mandates / cityMandates > 0.5) {
              absolutes += 1;
            }
            if (item.party === 1) {
              mandatesSpo += item.mandates;
            }
            if (item.party === 2) {
              mandatesOvp += item.mandates;
            }
            if (item.party === 3) {
              mandatesFpo += item.mandates;
            }
          });

          mandates += cityMandates;
        });

        membersAverageAge = Math.round((membersAverageAge / filtered.length) * 100) / 100;
        officialsAverageAge = Math.round((officialsAverageAge / filtered.length) * 100) / 100;

        const mayors = officials.filter((official) => official.district === district.id && official.type === 1 && official.party === 1);

        const row = {
          id: district.id,
          gkz: district.gkz,
          name: district.name,
          cities: filtered.length,
          mayors: mayors.length,
          mayorsPercent: Math.round((mayors.length / filtered.length) * 10000) / 100,
          absolutes,
          absolutesPercent: Math.round((absolutes / filtered.length) * 10000) / 100,
          mandates,
          mandatesSpo,
          mandatesSpoPercent: mandates > 0 ? Math.round((mandatesSpo / mandates) * 10000) / 100 : 0,
          mandatesOvp,
          mandatesOvpPercent: mandates > 0 ? Math.round((mandatesOvp / mandates) * 10000) / 100 : 0,
          mandatesFpo,
          mandatesFpoPercent: mandates > 0 ? Math.round((mandatesFpo / mandates) * 10000) / 100 : 0,
          officials: officialsMaleFemale,
          officialsFemale,
          officialsFemalePercent: officialsMaleFemale > 0 ? Math.round((officialsFemale / officialsMaleFemale) * 10000) / 100 : 0,
          citizens,
          members,
          membersPercent: citizens > 0 ? Math.round((members / citizens) * 10000) / 100 : 0,
          membersAverageAge,
          officialsAverageAge,
        };
        return row;
      });
  }
  return rows;
}

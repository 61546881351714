import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import packageJson from '../../package.json';

export default function Copyright({ sx }) {
  return (
    <Box sx={sx}>
      <Box display="flex" justifyContent="center" sx={{ pt: 1, pb: 1 }}>
        <Typography variant="caption" color="textSecondary" align="center">
          {`GeMo v${packageJson.version} Copyright © `}
          <Link color="inherit" href={process.env.REACT_APP_COPYRIGHT_HREF} target="_blank">
            {process.env.REACT_APP_COPYRIGHT}
          </Link>
          {` ${new Date().getFullYear()}`}
        </Typography>
      </Box>
    </Box>
  );
}

Copyright.propTypes = {
  sx: PropTypes.shape({}),
};

Copyright.defaultProps = {
  sx: undefined,
};

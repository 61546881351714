const namespaceLocal = {
  de: {
    users: 'Benutzer',
  },
};

export default {
  de: {
    alerts: {
      101: ({ namespace, payload }) => `${namespaceLocal.de[namespace]} ${payload.firstname} ${payload.lastname} wurde neu angelegt!`,
      102: ({ namespace, payload }) => `${namespaceLocal.de[namespace]} ${payload.firstname} ${payload.lastname} hat ein update erhalten!`,
      103: ({ namespace, payload }) => `${namespaceLocal.de[namespace]} ${payload.firstname} ${payload.lastname} wurde gelöscht!`,
    },
  },
};

export default {
  de: {
    add: 'Bereich hinzufügen',
    agree: 'Löschen',
    back: 'Zurück',
    destroy: 'Löschen',
    destroyTitle: 'Möchten Sie diese Berichtdefinition wirklich löschen?',
    destroyDescription: '',
    disagree: 'Abbrechen',
    lock: 'Sperren',
    name: 'Name',
    new: 'Neuer Bereich',
    title: 'Berichtdefinition bearbeiten',
    unlock: 'Entsperren',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

import { dateValueFormatter, numberValueFormatter, percentValueFormatter } from '../../../../util/formatter';

export default function ElectionresultsTableColumnDefinition(
  local,
  city,
  district,
  partiesColumns,
) {
  let colDef;
  if (partiesColumns) {
    colDef = [{
      type: 'string',
      field: 'type',
      headerName: local.type,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'date',
      field: 'date',
      headerName: local.date,
      valueFormatter: dateValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'eligible',
      headerName: local.eligible,
      valueFormatter: numberValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'votes',
      headerName: local.votes,
      valueFormatter: numberValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'valid',
      headerName: local.valid,
      valueFormatter: numberValueFormatter,
      minWidth: 150,
      flex: 1,
    }, {
      type: 'number',
      field: 'invalid',
      headerName: local.invalid,
      valueFormatter: numberValueFormatter,
      minWidth: 150,
      flex: 1,
    }];
    partiesColumns.forEach((party) => {
      colDef.push({
        type: 'number',
        field: party,
        headerName: party,
        valueFormatter: numberValueFormatter,
        minWidth: 180,
        flex: 1,
      }, {
        type: 'number',
        field: `${party}-percent`,
        headerName: `${party} %`,
        valueFormatter: percentValueFormatter,
        minWidth: 180,
        flex: 1,
      });
    });
    if (!city) {
      colDef.unshift({
        field: 'city',
        type: 'string',
        headerName: local.city,
        minWidth: 180,
        flex: 1,
      });
    }
    if (!district) {
      colDef.unshift({
        field: 'district',
        type: 'string',
        headerName: local.district,
        minWidth: 180,
        flex: 1,
      });
    }
  }
  return colDef;
}

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Masonry from '@mui/lab/Masonry';

import { LocalizationContext } from '../../../../AppContext';

import InfoCityAgreements from './InfoCityAgreements';
import InfoCityReports from './InfoCityReports';
import InfoStatisticColumn from './InfoStatisticColumn';
import InfoSummarySettings from './InfoSummarySettings';
import localization from './InfoSummary.local';

export default function InfoCity({
  // cities,
  citiesInfo,
  // citysettingdefinitions,
  // citysettings,
  // district,
  group,
  // onSetCitysettings,
  onSetGroup,
}) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Button size="small" variant="text" color={group ? 'primary' : 'secondary'} onClick={() => onSetGroup(!group)}>
        {local.group}
      </Button>
      <Masonry columns={{ xs: 1, md: 2 }} sx={{ width: '100%' }}>
        { citiesInfo && <InfoStatisticColumn title={local.absolutes} data={citiesInfo.absolutes} labels={[local.party, local.absolutes]} />}
        { citiesInfo && <InfoStatisticColumn title={local.mandates} data={citiesInfo.mandates} labels={[local.party, local.mandates]} />}
        { citiesInfo && <InfoStatisticColumn title={local.mayors} data={citiesInfo.mayors} labels={[local.party, local.mayors]} />}
        { citiesInfo && <InfoStatisticColumn title={local.vicemayors} data={citiesInfo.vicemayors} labels={[local.party, local.vicemayors]} />}
        { citiesInfo && citiesInfo.citysettingdefinitions && <InfoSummarySettings citysettingdefinitions={citiesInfo.citysettingdefinitions} /> }
        { citiesInfo && citiesInfo.agreements && <InfoCityAgreements infoAgreements={citiesInfo.agreements} /> }
        { citiesInfo && citiesInfo.reports && <InfoCityReports infoReports={citiesInfo.reports} /> }
      </Masonry>
    </Box>
  );
}

InfoCity.propTypes = {
  // cities: PropTypes.arrayOf(PropTypes.shape({})),
  citiesInfo: PropTypes.arrayOf(PropTypes.shape({})),
  // citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  // citysettings: PropTypes.arrayOf(PropTypes.shape({})),
  district: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  group: PropTypes.bool.isRequired,
  onSetGroup: PropTypes.func.isRequired,
};

InfoCity.defaultProps = {
  // cities: undefined,
  citiesInfo: undefined,
  // citysettingdefinitions: undefined,
  // citysettings: undefined,
  district: undefined,
};

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext } from '../../../../AppContext';
import OfficialsToolbar from './OfficialsToolbar';
import ColumnDefinition from './OfficialsColumnDefinition';
import RowsMapper from './OfficialsRowsMapper';
import localization from './Officials.local';

export default function Officials({
  cities,
  city,
  district,
  districts,
  officialcategories,
  officials,
  officialtypes,
  parties,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [officialcategory, setOfficialcategory] = useState(1);

  useEffect(() => setColumns(ColumnDefinition(local, city, district)), [city, district]);
  useEffect(() => setRows(RowsMapper(
    local,
    cities,
    city,
    districts,
    district,
    officialcategory,
    officials,
    officialtypes,
    parties,
  )), [cities, city, districts, district, officialcategory, officials, officialtypes, parties]);

  return (
    <Paper sx={{ height: '100%' }}>
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          components={{ Toolbar: OfficialsToolbar }}
          componentsProps={{ toolbar: { officialcategories, officialcategory, onSetOfficialcategory: setOfficialcategory } }}
        />
      )}
    </Paper>
  );
}

Officials.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  officialcategories: PropTypes.arrayOf(PropTypes.shape({})),
  officials: PropTypes.arrayOf(PropTypes.shape({})),
  officialtypes: PropTypes.arrayOf(PropTypes.shape({})),
  parties: PropTypes.arrayOf(PropTypes.shape({})),
};

Officials.defaultProps = {
  cities: undefined,
  city: undefined,
  districts: undefined,
  district: undefined,
  officialcategories: undefined,
  officials: undefined,
  officialtypes: undefined,
  parties: undefined,
};

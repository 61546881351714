export default {
  de: {
    add: 'Leistung hinzufügen',
    name: 'Name',
    description: 'Beschreibung',
    date: 'Datum',
    category: 'Kategory',
    creator: 'erstellt von',
    createdAt: 'erstellt am',
    editor: 'bearbeitet von',
    updatedAt: 'bearbeitet am',
    archive: 'Archiv',
    status: 'Status',
    alerts: {
      200: () => 'Neue Leistung hinzugefügt',
    },
  },
};

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import Toolbar from './ServicesTableToolbar';
import ColumnDefinition from './ServicesTableColumnDefinition';
import RowsMapper from './ServicesTableRowsMapper';
import localization from './ServicesTable.local';

export default function ServicesTable({
  cities,
  city,
  district,
  districts,
  servicecategories,
  services,
  servicestatuses,
  // onRowClick,
  onSetServices,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [archive, setArchive] = useState(false);
  const [alert, setAlert] = useState(false);

  useEffect(() => setColumns(ColumnDefinition(local)), []);
  useEffect(() => setRows(RowsMapper(
    local,
    archive,
    cities,
    city,
    district,
    districts,
    servicecategories,
    services,
    servicestatuses,
  )), [
    archive,
    cities,
    city,
    district,
    districts,
    servicecategories,
    services,
    servicestatuses,
  ]);

  const onAddClick = () => {
    const service = {
      name: 'Neue Leistung',
      description: 'Beschreibung der neuen Leistung',
      date: moment(),
    };
    socket.emit('services.post', service, ({ error, payload }) => {
      setAlert(error || 200);
      if (!error) {
        onSetServices(services ? [...services, payload] : [payload]);
      }
    });
  };

  return (
    <Paper sx={{ height: '100%' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          // onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar }}
          componentsProps={{
            toolbar: {
              local, archive, onAddClick, onSetArchive: setArchive,
            },
          }}
        />
      )}
    </Paper>
  );
}

ServicesTable.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  servicecategories: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  servicestatuses: PropTypes.arrayOf(PropTypes.shape({})),
  // onRowClick: PropTypes.func.isRequired,
  onSetServices: PropTypes.func.isRequired,
};

ServicesTable.defaultProps = {
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
  servicecategories: undefined,
  services: undefined,
  servicestatuses: undefined,
};

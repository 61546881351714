import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';

import { LocalizationContext, SocketContext } from '../../../../../AppContext';
import Confirm from '../../../../../components/Confirm';
import Snackbar from '../../../../../components/Snackbar';
import localization from './OfficialcategoriesPatch.local';

export default function OfficialcategoriesPatch({
  officialcategories,
  onBack,
  onSetOfficialcategories,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const { officialcategoryid } = useParams();
  const [officialcategory, setOfficialcategory] = useState();
  const [name, setName] = useState('');
  const [typingTimeout, setTypingTimeout] = useState();
  const [alert, setAlert] = useState();
  const [openConfirm, setOpenConfirm] = useState();

  useEffect(() => {
    if (officialcategories && officialcategoryid) {
      const foundUser = officialcategories.find((item) => item.id === parseInt(officialcategoryid, 10));
      if (foundUser) {
        setOfficialcategory(foundUser);
      } else {
        onBack();
      }
    }
  }, [officialcategories, officialcategoryid]);

  useEffect(() => {
    if (officialcategory) {
      setName(officialcategory.name);
    }
  }, [officialcategory]);

  const acknowledgeChange = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetOfficialcategories(officialcategories ? officialcategories.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
    }
  };

  const onChange = (field, value) => {
    const params = {
      id: officialcategories.id,
      field,
      value,
    };
    socket.emit('officialcategories.patch', params, acknowledgeChange);
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  const acknowledgeDestroy = ({ error, payload }) => {
    if (error) {
      setAlert(error);
    } else {
      onSetOfficialcategories(officialcategories ? officialcategories.filter((item) => (item.id !== payload.id)) : []);
      onBack();
    }
  };

  const onDestroy = () => {
    const params = { id: officialcategory.id };
    socket.emit('officialcategories.destroy', params, acknowledgeDestroy);
  };

  return (
    <Paper sx={{ height: '100%', p: 5 }}>
      <Confirm
        title={local.destroyTitle}
        description={local.destroyDescription}
        agree={local.agree}
        disagree={local.disagree}
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={onDestroy}
      />
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h4">{local.title}</Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={5}>
            {local.firstname}
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.name}
              value={name}
              onChange={(e) => onTextChange(setName, 'name', e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        { officialcategory && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => setOpenConfirm(true)}
            sx={{ justifyContent: 'flex-start' }}
          >
            <DeleteIcon sx={{ mr: 1 }} />
            <Typography>{local.destroy}</Typography>
          </Fab>
        )}
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

OfficialcategoriesPatch.propTypes = {
  officialcategories: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSetOfficialcategories: PropTypes.func.isRequired,
};

OfficialcategoriesPatch.defaultProps = {
  officialcategories: undefined,
};

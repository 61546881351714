export default (
  socket,
  onAlert,
  namespace,
  list,
  setList,
  event = 'getAll',
  params = undefined,
) => {
  socket.emit(
    `${namespace}.${event}`,
    params,
    ({ error, payload }) => (error ? onAlert(error) : setList(list ? [...list, ...payload] : payload)),
  );
};

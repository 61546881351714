import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Home from '@mui/icons-material/Home';
import Assignment from '@mui/icons-material/Assignment';
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn';
import EventAvailable from '@mui/icons-material/EventAvailable';
import ChatIcon from '@mui/icons-material/Chat';
import Archive from '@mui/icons-material/Archive';

import { LocalizationContext } from '../../../../AppContext';
import localization from './Timelines.local';

const SX_TIMELINEITEM = {};
const SX_TIMELINEITEM_HOVER = { cursor: 'pointer', backgroundColor: 'action.hover' };

export default function Timelines({
  agreements,
  cities,
  city,
  citysettingdefinitions,
  citysettings,
  district,
  districts,
  files,
  messages,
  reports,
  reportdefinitions,
  services,
  timelines,
  users,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [values, setValues] = useState([]);
  const [hover, setHover] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (agreements && cities && citysettingdefinitions && citysettings && districts && files && messages && reports && reportdefinitions && services && timelines && users) {
      setValues(
        timelines
          .filter((timeline) => (!district || district.id === timeline.district) && (!city || city.id === timeline.city))
          .sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt), 'seconds'))
          .map((timeline) => {
            let caption;
            let link;
            if (timeline.namespace === 'agreements') {
              const agreement = agreements.find((item) => item.id === timeline.item);
              caption = agreement ? agreement.description : undefined;
              link = agreement ? `/${agreement.district}/${agreement.city}/${timeline.namespace}/${agreement.id}` : undefined;
            } else if (timeline.namespace === 'citysettings') {
              const citysetting = citysettings.find((item) => item.id === timeline.item);
              if (citysetting) {
                const citysettingdefinition = citysettingdefinitions.find((item) => item.id === citysetting.definition);
                let { value } = citysetting;
                if (citysettingdefinition?.type === 'DROPDOWN') {
                  const option = citysettingdefinition.options.find((item) => item.id === parseInt(citysetting.value, 10));
                  value = option ? option.name : citysetting.value;
                }
                if (citysettingdefinition?.type === 'BOOLEAN') {
                  value = local[citysetting.value];
                }
                if (citysettingdefinition?.type === 'DATE') {
                  value = moment(citysetting.value).format('DD.MM.YYYY');
                }
                caption = `${citysettingdefinition?.name} - ${value}`;
                link = `/${citysetting.district}/${citysetting.city}/info`;
              }
            } else if (timeline.namespace === 'files') {
              const file = files.find((item) => item.id === timeline.item);
              if (file) {
                caption = file.name;
                link = `/${file.district}/${file.city}/${timeline.namespace}/${file.id}`;
              }
            } else if (timeline.namespace === 'messages') {
              const message = messages.find((item) => item.id === timeline.item);
              caption = message ? message.text : undefined;
              link = message ? `/${message.district}/${message.city}/${timeline.namespace}` : undefined;
            } else if (timeline.namespace === 'reports') {
              const report = reports.find((item) => item.id === timeline.item);
              if (report) {
                const reportDefinition = reportdefinitions.find((item) => item.id === report.definition);
                caption = reportDefinition?.name || 'Unbekannte Definition';
                link = `/${report.district}/${report.city}/${timeline.namespace}/${report.id}`;
              }
            }
            if (timeline.event === 'destroy') {
              caption = `${local[timeline.namespace]} ${local.destroy}`;
              link = `/${district ? district.id : '-'}/${city ? city.id : '-'}/${timeline.namespace}`;
            }
            if (!caption) {
              caption = `-${local.destroy}-`;
            }
            if (!link) {
              link = `/${district ? district.id : '-'}/${city ? city.id : '-'}/${timeline.namespace}`;
            }
            return {
              id: timeline.id,
              city: city || cities.find((item) => item.id === timeline.city),
              district: district || districts.find((item) => item.id === timeline.district),
              namespace: timeline.namespace,
              event: timeline.event,
              user: users.find((item) => item.id === timeline.user),
              caption,
              link,
              updatedAt: timeline.updatedAt,
            };
          }),
      );
    }
  }, [
    agreements,
    cities,
    city,
    citysettingdefinitions,
    citysettings,
    district,
    districts,
    files,
    messages,
    reports,
    reportdefinitions,
    services,
    timelines,
  ]);

  return (
    <Timeline position="right">
      { values.map((value) => (
        <TimelineItem
          key={value.id}
          onClick={() => navigate(value.link)}
          onMouseEnter={() => setHover(value.id)}
          onMouseLeave={() => setHover()}
          sx={hover === value.id ? SX_TIMELINEITEM_HOVER : SX_TIMELINEITEM}
        >
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <Typography>{`${value.user.firstname} ${value.user.lastname}`}</Typography>
            <Typography variant="caption" component="div">
              {`${district ? '' : `${value.district.name}, `}${city ? '' : `${value.city.name}`}`}
            </Typography>
            <Typography variant="caption">{moment(value.updatedAt).format('DD.MM.YYYY')}</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              { value.namespace === 'agreements' && (<AssignmentTurnedIn />)}
              { value.namespace === 'citysettings' && (<Home />)}
              { value.namespace === 'files' && (<Archive />)}
              { value.namespace === 'messages' && (<ChatIcon />)}
              { value.namespace === 'reports' && (<Assignment />)}
              { value.namespace === 'services' && (<EventAvailable />)}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" color="primary">
              {value.caption}
            </Typography>
            <Typography variant="caption">
              {`${local[value.namespace]} ${local[value.event]}`}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}

Timelines.propTypes = {
  agreements: PropTypes.arrayOf(PropTypes.shape({})),
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  citysettings: PropTypes.arrayOf(PropTypes.shape({})),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  files: PropTypes.arrayOf(PropTypes.shape({})),
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  reports: PropTypes.arrayOf(PropTypes.shape({})),
  reportdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  timelines: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
};

Timelines.defaultProps = {
  agreements: undefined,
  cities: undefined,
  city: undefined,
  citysettingdefinitions: undefined,
  citysettings: undefined,
  district: undefined,
  districts: undefined,
  files: undefined,
  messages: undefined,
  reports: undefined,
  reportdefinitions: undefined,
  services: undefined,
  timelines: undefined,
  users: undefined,
};

export default {
  de: {
    title: 'Gemeinde Einstellung bearbeiten',
    placeholder: '',
    name: 'Name',
    type: 'Typ',
    option: 'Option',
    sort: 'Sortierung',
    newOption: 'Neue Option',
    add: 'Option hinzufügen',
    display: 'Auf Info-Seite anzeigen?',
    localparty: 'Antwort auf Ortspartei-Ebene?',
    BOOLEAN: 'Ja/Nein',
    DATE: 'Datum',
    DROPDOWN: 'Auswahl',
    NUMBER: 'Zahl',
    TEXT: 'Text',
    destroy: 'Löschen',
    back: 'Zurück',
    destroyTitle: 'Möchten Sie die Gemeinde Einstellung wirklich löschen?',
    destroyDescription: '',
    agree: 'Löschen',
    disagree: 'Abbrechen',
    lock: 'Sperren',
    unlock: 'Entsperren',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

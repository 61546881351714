export default {
  de: {
    text: 'Fragestellung',
    noText: 'Keine Fragestellung definiert',
    listitem: 'Aufzählungspunkt',
    addListitem: 'Aufzählungspunkt hinzufügen',
    type: 'Typ',
    option: 'Option',
    definition: 'Gemeindeeinstellung',
    addOption: 'Option hinzufügen',
    newOption: 'Neue Option',
    destroy: 'Frage löschen',
    BOOLEAN: 'Ja/Nein',
    DATE: 'Datum',
    DROPDOWN: 'Auswahl',
    NUMBER: 'Zahl',
    TEXT: 'Text',
    SETTING: 'Gemeindeeinstellung',
  },
};

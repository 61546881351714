export default {
  de: {
    title: 'Neuen Bericht anlegen',
    definition: 'Berichtart',
    submit: 'Bericht erstellen',
    back: 'Zurück',
    alerts: {
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

export default {
  de: {
    add: 'Datei hinzufügen',
    district: 'Bezirk',
    city: 'Gemeinde',
    name: 'Name',
    description: 'Beschreibung',
    type: 'Dateityp',
    size: 'Dateigröße',
    creator: 'erstellt von',
    createdAt: 'erstellt am',
    editor: 'bearbeitet von',
    updatedAt: 'bearbeitet am',
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function OfficialsToolbar({ officialcategories, officialcategory, onSetOfficialcategory }) {
  return (
    <GridToolbarContainer>
      { officialcategories && officialcategories.map((item) => (
        <Button
          variant="text"
          size="small"
          color={officialcategory === item.id ? 'primary' : 'secondary'}
          key={item.id}
          onClick={() => onSetOfficialcategory(item.id)}
        >
          {item.name}
        </Button>
      ))}
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{ delimiter: ';', utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}

OfficialsToolbar.propTypes = {
  officialcategories: PropTypes.arrayOf(PropTypes.shape({})),
  officialcategory: PropTypes.number,
  onSetOfficialcategory: PropTypes.func.isRequired,
};

OfficialsToolbar.defaultProps = {
  officialcategories: undefined,
  officialcategory: undefined,
};

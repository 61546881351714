import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route } from 'react-router-dom';

import Login from './Login';
import PasswordForgotten from './PasswordForgotten';
import PasswordReset from './PasswordReset';

export default function Unauthenticated({ onLogin }) {
  return (
    <Routes>
      <Route exact path="/password/forgotten" element={<PasswordForgotten />} />
      <Route exact path="/password/reset/:token" element={<PasswordReset onLogin={onLogin} />} />
      <Route path="/" element={<Login onLogin={onLogin} />} />
      {/* { initialised && (<Route path="*" element={<Navigate to="/" />} />)} */}
    </Routes>
  );
}

Unauthenticated.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

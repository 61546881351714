import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Agreementcategories from './agreementcategories/Agreementcategories';
import Agreementstatuses from './agreementstatuses/Agreementstatuses';

export default function Agreements({
  agreementcategories,
  agreements,
  agreementstatuses,
  onSetAgreementcategories,
  onSetAgreementstatuses,
}) {
  return (
    <Routes>
      <Route
        path="categories/*"
        element={(
          <Agreementcategories
            agreementcategories={agreementcategories}
            agreements={agreements}
            onSetAgreementcategories={onSetAgreementcategories}
          />
        )}
      />
      <Route
        path="statuses/*"
        element={(
          <Agreementstatuses
            agreements={agreements}
            agreementstatuses={agreementstatuses}
            onSetAgreementstatuses={onSetAgreementstatuses}
          />
        )}
      />
      <Route path="*" element={<Navigate to="categories" />} />
    </Routes>
  );
}

Agreements.propTypes = {
  agreementcategories: PropTypes.arrayOf(PropTypes.shape({})),
  agreements: PropTypes.arrayOf(PropTypes.shape({})),
  agreementstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  onSetAgreementcategories: PropTypes.func.isRequired,
  onSetAgreementstatuses: PropTypes.func.isRequired,
};

Agreements.defaultProps = {
  agreementcategories: undefined,
  agreements: undefined,
  agreementstatuses: undefined,
};

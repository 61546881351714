import {
  percentValueFormatter,
  numberValueFormatter,
} from '../../../../util/formatter';

export default function DistrictsColumnDefinition(local) {
  return [{
    field: 'gkz',
    headerName: local.gkz,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'name',
    headerName: local.name,
    minWidth: 200,
    flex: 3,
  }, {
    field: 'cities',
    type: 'number',
    valueFormatter: numberValueFormatter,
    headerName: local.cities,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'mayors',
    type: 'number',
    valueFormatter: numberValueFormatter,
    headerName: local.mayors,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'mayorsPercent',
    type: 'number',
    headerName: local.mayorsPercent,
    valueFormatter: percentValueFormatter,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'absolutes',
    type: 'number',
    valueFormatter: numberValueFormatter,
    headerName: local.absolutes,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'absolutesPercent',
    type: 'number',
    headerName: local.absolutesPercent,
    valueFormatter: percentValueFormatter,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'mandates',
    type: 'number',
    valueFormatter: numberValueFormatter,
    headerName: local.mandates,
    minWidth: 75,
    flex: 1,
  }, {
    type: 'number',
    field: 'mandatesSpo',
    headerName: local.mandatesSpo,
    valueFormatter: numberValueFormatter,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'mandatesSpoPercent',
    headerName: local.mandatesSpoPercent,
    valueFormatter: percentValueFormatter,
    minWidth: 75,
    flex: 1,
  }, {
    type: 'number',
    field: 'mandatesOvp',
    headerName: local.mandatesOvp,
    valueFormatter: numberValueFormatter,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'mandatesOvpPercent',
    headerName: local.mandatesOvpPercent,
    valueFormatter: percentValueFormatter,
    minWidth: 75,
    flex: 1,
  }, {
    type: 'number',
    field: 'mandatesFpo',
    headerName: local.mandatesFpo,
    valueFormatter: numberValueFormatter,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'mandatesFpoPercent',
    headerName: local.mandatesFpoPercent,
    valueFormatter: percentValueFormatter,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'officials',
    headerName: local.officials,
    valueFormatter: numberValueFormatter,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'officialsFemale',
    headerName: local.officialsFemale,
    valueFormatter: numberValueFormatter,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'officialsFemalePercent',
    headerName: local.officialsFemalePercent,
    valueFormatter: percentValueFormatter,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'citizens',
    type: 'number',
    valueFormatter: numberValueFormatter,
    headerName: local.citizens,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'members',
    type: 'number',
    valueFormatter: numberValueFormatter,
    headerName: local.members,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'membersPercent',
    type: 'number',
    headerName: local.membersPercent,
    valueFormatter: percentValueFormatter,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'membersAverageAge',
    type: 'number',
    valueFormatter: numberValueFormatter,
    headerName: local.membersAverageAge,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'officialsAverageAge',
    type: 'number',
    valueFormatter: numberValueFormatter,
    headerName: local.officialsAverageAge,
    minWidth: 75,
    flex: 1,
  }];
}

export default function ServicecategoriesTableRowsMapper(local, servicecategories) {
  let rows;
  if (servicecategories) {
    rows = servicecategories.map((row) => ({
      id: row.id,
      name: row.name,
    }));
  }
  return rows;
}

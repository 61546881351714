import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ElectionresultsPatch from './ElectionresultsPatch';
import ElectionresultsTable from './ElectionresultsTable';

export default function Electionresults({
  cities,
  city,
  district,
  districts,
  elections,
  electiontypes,
  parties,
  presidents,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path=":electionresultid"
        element={(
          <ElectionresultsPatch
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            elections={elections}
            electiontypes={electiontypes}
            parties={parties}
            presidents={presidents}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/electionresults`)}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <ElectionresultsTable
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            elections={elections}
            electiontypes={electiontypes}
            parties={parties}
            presidents={presidents}
            onRowClick={(id) => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/electionresults/${id}`)}
          />
        )}
      />
    </Routes>
  );
}

Electionresults.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  electiontypes: PropTypes.arrayOf(PropTypes.shape({})),
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  presidents: PropTypes.arrayOf(PropTypes.shape({})),
};

Electionresults.defaultProps = {
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
  elections: undefined,
  electiontypes: undefined,
  parties: undefined,
  presidents: undefined,
};

export default {
  de: {
    add: 'Kategorie hinzufügen',
    categories: 'Kategorien',
    statuses: 'Status',
    name: 'Name',
    description: 'Beschreibung',
    active: 'Aktiv',
    activeAgreements: 'aktive Ziele',
    inactiveAgreements: 'archivierte Ziele',
    updatedAt: 'zuletzt bearbeitet am',
    editor: 'zuletzt bearbeitet von',
  },
};

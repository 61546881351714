import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Citysetting from './Citysetting';
import localization from './Citysettings.local';

export default function Citysettings({
  cities,
  citysettings,
  citysettingdefinitions,
  districts,
  onSetCitysettings,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [citySettingDefinition, setCitySettingDefinition] = useState();

  const acknowledgePatch = ({ error, payload }) => {
    if (!error) {
      onSetCitysettings(citysettings ? citysettings.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
    }
  };

  const acknowledgePost = ({ error, payload }) => {
    if (!error) {
      onSetCitysettings(citysettings ? [...citysettings, payload] : [payload]);
    }
  };

  const onChange = (city, citysetting, value) => {
    console.log({
      city, citysetting, value, citySettingDefinition,
    });
    const params = {
      definition: citySettingDefinition.id,
      id: citysetting?.id,
      city: city.id,
      district: city.district,
      type: 0,
      value,
    };
    if (citysetting) {
      socket.emit('citysettings.patch', params, acknowledgePatch);
    } else {
      socket.emit('citysettings.post', params, acknowledgePost);
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Paper sx={{ p: 1 }}>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <InputLabel id="citysettingdefinition-label">{local.citysetting}</InputLabel>
          <Select
            labelId="citysettingdefinition-label"
            id="citysettingdefinition"
            value={citySettingDefinition}
            label={local.citysetting}
            onChange={(event) => setCitySettingDefinition(event.target.value)}
          >
            {citysettingdefinitions?.map((item) => (
              <MenuItem key={item.id} value={item}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
      { citySettingDefinition && citysettings && districts.map((district) => (
        <Paper key={district.id} sx={{ mt: 1, p: 1 }}>
          <Typography color="primary">{district.name}</Typography>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={1}>
            { cities.filter((city) => city.district === district.id).map((city) => (
              <Grid item xs={12} md={4} lg={3} key={city.id}>
                <Citysetting
                  city={city}
                  initialCitysetting={citysettings.find((item) => item.city === city.id && item.definition === citySettingDefinition.id)}
                  citySettingDefinition={citySettingDefinition}
                  onChange={onChange}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      ))}
    </Container>
  );
}

Citysettings.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  citysettings: PropTypes.arrayOf(PropTypes.shape({})),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  onSetCitysettings: PropTypes.func.isRequired,
};

Citysettings.defaultProps = {
  cities: undefined,
  citysettingdefinitions: undefined,
  citysettings: undefined,
  districts: undefined,
};

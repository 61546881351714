import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ReportstatusesPost from './ReportstatusesPost';
import ReportstatusesPatch from './ReportstatusesPatch';
import ReportstatusesTable from './ReportstatusesTable';

export default function Reportstatuses({
  elections,
  reportstatuses,
  onSetReportstatuses,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <ReportstatusesPost
            elections={elections}
            reportstatuses={reportstatuses}
            onBack={() => navigate('/settings/reports/statuses')}
            onSetReportstatuses={onSetReportstatuses}
          />
        )}
      />
      <Route
        path=":reportstatusid"
        element={(
          <ReportstatusesPatch
            elections={elections}
            reportstatuses={reportstatuses}
            onBack={() => navigate('/settings/reports/statuses')}
            onSetReportstatuses={onSetReportstatuses}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <ReportstatusesTable
            elections={elections}
            reportstatuses={reportstatuses}
            onRowClick={(id) => navigate(`/settings/reports/statuses/${id}`)}
            onAddClick={() => navigate('/settings/reports/statuses/post')}
            onDefinitionsClick={() => navigate('/settings/reports/definitions')}
          />
        )}
      />
    </Routes>
  );
}

Reportstatuses.propTypes = {
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  reportstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  onSetReportstatuses: PropTypes.func.isRequired,
};

Reportstatuses.defaultProps = {
  elections: undefined,
  reportstatuses: undefined,
};

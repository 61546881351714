export default {
  de: {
    title: 'Neues Ziel hinzufügen',
    smart: 'Zieldefinition S.M.A.R.T',
    s: '- Spezifisch',
    m: '- Messbar',
    a: '- Attraktiv',
    r: '- Realisierbar',
    t: '- Terminiert',
    category: 'Kategory',
    until: 'Bis',
    description: 'Beschreibung',
    submit: 'Ziel erstellen',
    back: 'Zurück',
    alerts: {
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

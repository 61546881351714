import { numberValueFormatter, dateValueFormatter } from '../../../../util/formatter';

export default function CitysettingdefinitionsTableColumnDefinition(local) {
  return [{
    type: 'string',
    field: 'name',
    headerName: local.name,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'type',
    headerName: local.type,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'number',
    field: 'sort',
    headerName: local.sort,
    valueFormatter: numberValueFormatter,
    minWidth: 100,
    flex: 1,
  }, {
    type: 'boolean',
    field: 'active',
    headerName: local.active,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'boolean',
    field: 'display',
    headerName: local.display,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'boolean',
    field: 'localparty',
    headerName: local.localparty,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'date',
    field: 'updatedAt',
    headerName: local.updatedAt,
    valueFormatter: dateValueFormatter,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'editor',
    headerName: local.editor,
    minWidth: 150,
    flex: 1,
  }];
}

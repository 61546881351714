import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ElectiontypesPost from './ElectiontypesPost';
import ElectiontypesPatch from './ElectiontypesPatch';
import ElectiontypesTable from './ElectiontypesTable';

export default function Electiontypes({
  elections,
  electiontypes,
  onSetElectiontypes,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <ElectiontypesPost
            elections={elections}
            electiontypes={electiontypes}
            onBack={() => navigate('/settings/elections/types')}
            onSetElectiontypes={onSetElectiontypes}
          />
        )}
      />
      <Route
        path=":electiontypeid"
        element={(
          <ElectiontypesPatch
            elections={elections}
            electiontypes={electiontypes}
            onBack={() => navigate('/settings/elections/types')}
            onSetElectiontypes={onSetElectiontypes}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <ElectiontypesTable
            elections={elections}
            electiontypes={electiontypes}
            onRowClick={(id) => navigate(`/settings/elections/types/${id}`)}
            onAddClick={() => navigate('/settings/elections/types/post')}
            onPartiesClick={() => navigate('/settings/elections/parties')}
            onPresidentsClick={() => navigate('/settings/elections/presidents')}
          />
        )}
      />
    </Routes>
  );
}

Electiontypes.propTypes = {
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  electiontypes: PropTypes.arrayOf(PropTypes.shape({})),
  onSetElectiontypes: PropTypes.func.isRequired,
};

Electiontypes.defaultProps = {
  elections: undefined,
  electiontypes: undefined,
};

export default {
  de: {
    city: 'Gemeinde',
    message: 'Nachricht',
    post: 'Senden',
    noCitySelected: 'Keine Gemeinde ausgewählt!',
    save: 'Speichern',
    cancel: 'Abbrechen',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    alerts: {
      200: () => 'Nachricht gesendet!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

export default {
  de: {
    add: 'Gemeinde Einstellung hinzufügen',
    name: 'Name',
    type: 'Typ',
    active: 'Aktiv',
    sort: 'Sortierung',
    display: 'Anzeige auf Info-Seite',
    localparty: 'Ortsparteieinstellung',
    updatedAt: 'zuletzt bearbeitet am',
    editor: 'zuletzt bearbeitet von',
    DROPDOWN: 'Auswahl',
    TEXT: 'Text',
    BOOLEAN: 'Ja/Nein',
    NUMBER: 'Zahl',
    DATE: 'Datum',
    newDefinition: 'Neue Einstellung',
    alerts: {
      200: () => 'Änderung gespeichert!',
    },
  },
};

export default {
  de: {
    title: 'Profil',
    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'Email',
    password: 'Passwort',
    passwordVerify: 'Passwort bestätigen',
    passwordChange: 'Passwort ändern',
    uploadImage: 'Neues Bild hochladen',
    passwordMustMatch: 'Die Passwörter stimmen nicht überein!',
    saveAvatar: 'Profilbild speichern',
  },
};

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext } from '../../../../AppContext';
import ReportsToolbar from './ReportsToolbar';
import ColumnDefinition from './ReportsTableColumnDefinition';
import RowsMapper from './ReportsTableRowsMapper';
import localization from './ReportsTable.local';

export default function ReportsTable({
  cities,
  city,
  district,
  districts,
  reportdefinitions,
  reports,
  reportstatuses,
  users,
  onRowClick,
  onAddClick,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [archive, setArchive] = useState(false);

  useEffect(() => setColumns(ColumnDefinition(local, city, district)), [city, district]);
  useEffect(() => setRows(RowsMapper(
    local,
    archive,
    cities,
    city,
    district,
    districts,
    reportdefinitions,
    reports,
    reportstatuses,
    users,
  )), [
    archive,
    cities,
    city,
    district,
    districts,
    reportdefinitions,
    reports,
    reportstatuses,
    users,
  ]);

  return (
    <Paper sx={{ height: '100%' }}>
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar: ReportsToolbar }}
          componentsProps={{
            toolbar: {
              local,
              archive,
              city,
              onAddClick,
              onArchiveClick: setArchive,
            },
          }}
        />
      )}
    </Paper>
  );
}

ReportsTable.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  reportdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  reports: PropTypes.arrayOf(PropTypes.shape({})),
  reportstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
};

ReportsTable.defaultProps = {
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
  reportdefinitions: undefined,
  reports: undefined,
  reportstatuses: undefined,
  users: undefined,
};

import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import CitysettingdefinitionsPatch from './CitysettingdefinitionsPatch';
import CitysettingdefinitionsTable from './CitysettingdefinitionsTable';

export default function Citysettingdefinitions({
  citysettingdefinitions,
  onSetCitysettingdefinitions,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path=":citysettingdefinitionid"
        element={(
          <CitysettingdefinitionsPatch
            citysettingdefinitions={citysettingdefinitions}
            onBack={() => navigate('/settings/citysettingdefinitions')}
            onSetCitysettingdefinitions={onSetCitysettingdefinitions}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <CitysettingdefinitionsTable
            citysettingdefinitions={citysettingdefinitions}
            onRowClick={(id) => navigate(`/settings/citysettingdefinitions/${id}`)}
            onSetCitysettingdefinitions={onSetCitysettingdefinitions}
          />
        )}
      />
    </Routes>
  );
}

Citysettingdefinitions.propTypes = {
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  onSetCitysettingdefinitions: PropTypes.func.isRequired,
};

Citysettingdefinitions.defaultProps = {
  citysettingdefinitions: undefined,
};

import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export default function Confirm({
  title,
  description,
  agree,
  disagree,
  open,
  onClose,
  onConfirm,
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{disagree}</Button>
        <Button onClick={onConfirm} autoFocus>{agree}</Button>
      </DialogActions>
    </Dialog>
  );
}

Confirm.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  agree: PropTypes.string.isRequired,
  disagree: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

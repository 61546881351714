export default {
  de: {
    add: 'Wahltyp hinzufügen',
    types: 'Wahltypen',
    parties: 'Parteien',
    presidents: 'Bundespräsidenten',
    name: 'Name',
    key: 'Schlüsselwert',
    description: 'Beschreibung',
    electionresults: 'Wahlergebnisse',
    updatedAt: 'zuletzt bearbeitet am',
    editor: 'zuletzt bearbeitet von',
  },
};

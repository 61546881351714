import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import AgreementsPost from './AgreementsPost';
import AgreementsPatch from './AgreementsPatch';
import CalendarView from './ClaendarView';

export default function Agreements({
  agreementcategories,
  agreements,
  agreementstatuses,
  cities,
  city,
  district,
  districts,
  users,
  officials,
  onSetAgreements,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <AgreementsPost
            agreementcategories={agreementcategories}
            agreements={agreements}
            agreementstatuses={agreementstatuses}
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            users={users}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/agreements`)}
            onSetAgreements={onSetAgreements}
          />
        )}
      />
      <Route
        path=":calendarid"
        element={(
          <AgreementsPatch
            agreementcategories={agreementcategories}
            agreements={agreements}
            agreementstatuses={agreementstatuses}
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            users={users}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/agreements`)}
            onSetAgreements={onSetAgreements}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <CalendarView
            agreementcategories={agreementcategories}
            agreements={agreements}
            agreementstatuses={agreementstatuses}
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            users={users}
            officials={officials}
            onRowClick={(id) => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/calendar/${id}`)}
            onAddClick={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/calendar/post`)}
          />
        )}
      />
    </Routes>
  );
}

Agreements.propTypes = {
  agreementcategories: PropTypes.arrayOf(PropTypes.shape({})),
  agreements: PropTypes.arrayOf(PropTypes.shape({})),
  agreementstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  officials: PropTypes.arrayOf(PropTypes.shape({})),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onSetAgreements: PropTypes.func.isRequired,
};

Agreements.defaultProps = {
  agreementcategories: undefined,
  agreements: undefined,
  officials: undefined,
  agreementstatuses: undefined,
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
  users: undefined,
};

export default {
  de: {
    add: 'Ziel hinzufügen',
    district: 'Bezirk',
    city: 'Gemeinde',
    until: 'Bis',
    category: 'Kategorie',
    creator: 'erstellt von',
    createdAt: 'erstellt am',
    editor: 'bearbeitet von',
    updatedAt: 'bearbeitet am',
    description: 'Beschreibung',
    archive: 'Archiv',
    status: 'Status',
  },
};

export default {
  de: {
    title: 'Ziel Kategorie bearbeiten',
    placeholder: '',
    name: 'Name',
    description: 'Beschreibung',
    updatedAt: 'zuletzt bearbeitet am',
    updatedFrom: 'zuletzt bearbeitet von',
    destroy: 'Löschen',
    back: 'Zurück',
    lock: 'Sperren',
    unlock: 'Entsperren',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uuid } from 'uuidv4';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { LocalizationContext } from '../../../../../AppContext';
import { BOOLEAN } from '../../../../../config/citysettingtypes';
import ReportdefinitionsPatchViewValues from './ReportdefinitionsPatchViewValues';
import localization from './ReportdefinitionsPatchView.local';

export default function ReportdefinitionsPatchView({
  citysettingdefinitions,
  expanded,
  view,
  onChange,
  onDestroy,
  onSetExpanded,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [name, setName] = useState('');
  const [childExpanded, setChildExpanded] = useState();
  const [typingTimeout, setTypingTimeout] = useState();

  useEffect(() => {
    if (view) {
      setName(view.name);
    }
  }, [view]);

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange({ ...view, [field]: value }), 1000));
  };

  return (
    <Accordion
      expanded={view && view.key === expanded}
      onChange={(e, isExpanded) => (isExpanded ? onSetExpanded(view.key) : onSetExpanded())}
    >
      <AccordionSummary>
        <Typography sx={{ height: '100%' }}>{name}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="secondary" onClick={() => onDestroy(view)} sx={{ m: 0 }}><DeleteIcon /></Button>
      </AccordionSummary>
      <AccordionDetails>
        { view && view.key === expanded && (
          <Box>
            <TextField
              fullWidth
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.name}
              value={name}
              onChange={(e) => onTextChange(setName, 'name', e.target.value)}
            />
            { view.values && view.values.map((value) => (
              <ReportdefinitionsPatchViewValues
                key={value.key}
                citysettingdefinitions={citysettingdefinitions}
                expanded={childExpanded}
                question={value}
                onChange={(item) => onChange({ ...view, values: view.values.map((entry) => (entry.key === item.key ? item : entry)) })}
                onDestroy={(item) => onChange({ ...view, values: view.values.filter((entry) => entry.key !== item.key) })}
                onSetExpanded={setChildExpanded}
              />
            ))}
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              sx={{ mt: 1, mb: 1 }}
              onClick={() => onChange({
                ...view,
                values: view.values
                  ? [...view.values, { key: uuid(), list: [], type: BOOLEAN }]
                  : [{ key: uuid(), list: [], type: BOOLEAN }],
              })}
            >
              <AddIcon />
              <Typography sx={{ ml: 1 }}>{local.add}</Typography>
            </Button>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

ReportdefinitionsPatchView.propTypes = {
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  expanded: PropTypes.string,
  view: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onChange: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onSetExpanded: PropTypes.func.isRequired,
};

ReportdefinitionsPatchView.defaultProps = {
  citysettingdefinitions: undefined,
  expanded: undefined,
  view: undefined,
};

import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import OfficialtypesPost from './OfficialtypesPost';
import OfficialtypesPatch from './OfficialtypesPatch';
import OfficialtypesTable from './OfficialtypesTable';

export default function Officialtypes({
  elections,
  officialtypes,
  onSetOfficialtypes,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <OfficialtypesPost
            elections={elections}
            officialtypes={officialtypes}
            onBack={() => navigate('/settings/officials/types')}
            onSetOfficialtypes={onSetOfficialtypes}
          />
        )}
      />
      <Route
        path=":officialtypeid"
        element={(
          <OfficialtypesPatch
            elections={elections}
            officialtypes={officialtypes}
            onBack={() => navigate('/settings/officials/types')}
            onSetOfficialtypes={onSetOfficialtypes}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <OfficialtypesTable
            elections={elections}
            officialtypes={officialtypes}
            onRowClick={(id) => navigate(`/settings/officials/types/${id}`)}
            onAddClick={() => navigate('/settings/officials/types/post')}
            onCategoriesClick={() => navigate('/settings/officials/categories')}
          />
        )}
      />
    </Routes>
  );
}

Officialtypes.propTypes = {
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  officialtypes: PropTypes.arrayOf(PropTypes.shape({})),
  onSetOfficialtypes: PropTypes.func.isRequired,
};

Officialtypes.defaultProps = {
  elections: undefined,
  officialtypes: undefined,
};

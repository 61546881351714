export default function PartiesTableRowsMapper(local, parties) {
  let rows;
  if (parties) {
    rows = parties.map((row) => {
      const parent = row.parent ? parties.find((item) => item.id === row.parent) : undefined;
      return {
        id: row.id,
        name: row.name,
        parent: parent ? parent.name : undefined,
        color: row.color,
        updatedAt: row.updatedAt,
        editor: row.editor,
      };
    });
  }
  return rows;
}

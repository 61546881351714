import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { LocalizationContext } from '../../../../AppContext';
import Label from '../../../../components/Label';

import localization from './InfoCityAgreements.local';

export default function InfoCityAgreements({ infoAgreements }) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Paper sx={{ width: '100%', p: 1, pb: 2 }}>
      <Typography variant="h6">{local.title}</Typography>
      <Grid container spacing={2}>
        { infoAgreements.map((item) => (
          <Grid key={item.name} item xs={12} md={4}>
            <Label label={item.name} value={item.length} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

InfoCityAgreements.propTypes = {
  infoAgreements: PropTypes.arrayOf(PropTypes.shape({})),
};

InfoCityAgreements.defaultProps = {
  infoAgreements: undefined,
};

export default {
  de: {
    title: 'Benutzer bearbeiten',
    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'Email',
    lockUser: 'Sperren',
    unlockUser: 'Entsperren',
    admin: 'Administrator',
    control: 'Steuerung',
    observer: 'Beobachter',
    services: 'Leistungen',
    steering: 'Lenkung',
    coaching: 'Coaching',
    watching: 'Beobachtung',
    back: 'Zurück',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

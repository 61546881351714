import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext } from '../../../../AppContext';
import Toolbar from './FilesTableToolbar';
import ColumnDefinition from './FilesTableColumnDefinition';
import RowsMapper from './FilesTableRowsMapper';
import localization from './FilesTable.local';

export default function FilesTable({
  cities,
  city,
  district,
  districts,
  files,
  users,
  onRowClick,
  onAddClick,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();

  useEffect(() => setColumns(ColumnDefinition(local, city, district)), [city, district]);
  useEffect(() => setRows(RowsMapper(
    local,
    cities,
    city,
    district,
    districts,
    files,
    users,
  )), [
    cities,
    city,
    district,
    districts,
    files,
    users,
  ]);

  return (
    <Paper sx={{ height: '100%' }}>
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar }}
          componentsProps={{
            toolbar: {
              local, city, onAddClick,
            },
          }}
        />
      )}
    </Paper>
  );
}

FilesTable.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  files: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
};

FilesTable.defaultProps = {
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
  files: undefined,
  users: undefined,
};

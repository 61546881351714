import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import FilesPost from './FilesPost';
import FilesPatch from './FilesPatch';
import FilesTable from './FilesTable';

export default function Files({
  cities,
  city,
  district,
  districts,
  files,
  users,
  onSetFiles,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <FilesPost
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            files={files}
            users={users}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/files`)}
            onSetFiles={onSetFiles}
          />
        )}
      />
      <Route
        path=":fileid"
        element={(
          <FilesPatch
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            files={files}
            users={users}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/files`)}
            onSetFiles={onSetFiles}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <FilesTable
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            files={files}
            users={users}
            onRowClick={(id) => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/files/${id}`)}
            onAddClick={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/files/post`)}
          />
        )}
      />
    </Routes>
  );
}

Files.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  files: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onSetFiles: PropTypes.func.isRequired,
};

Files.defaultProps = {
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
  files: undefined,
  users: undefined,
};

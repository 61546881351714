import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { LocalizationContext } from '../../../../AppContext';
import Label from '../../../../components/Label';
import getLatest from '../../../../util/getLatest';

import localization from './InfoCityDetails.local';

export default function InfoCityDetails({
  citizens,
  city,
  district,
  electiondate,
  gkz,
  highestOffice,
  members,
  membersAverageAge,
  officials,
  officialsAverageAge,
  officialsFemale,
  officialsMale,
  status,
}) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Paper sx={{ width: '100%', p: 1, pb: 2 }}>
      <Typography variant="h6">{local.title}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Label label={status} value={city} />
          <Label label={local.district} value={district} />
          <Label label={local.citizens} value={getLatest(citizens, 0)} />
          <Label label={local.members} value={getLatest(members, 0)} />
          <Label label={local.officials} value={getLatest(officials, 0)} />
          <Label label={local.officialsFemale} value={`${getLatest(officialsFemale, 0)}`} />
          <Label label={local.officialsFemalePercent} value={`${Math.round((getLatest(officialsFemale, 0) / getLatest(officials, 0)) * 10000) / 100}%`} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Label label={local.gkz} value={gkz} />
          <Label label={local.highestOffice} value={getLatest(highestOffice, '-')} />
          <Label label={local.electiondate} value={electiondate} />
          <Label label={local.membersAverageAge} value={getLatest(membersAverageAge, 0)} />
          <Label label={local.officialsAverageAge} value={getLatest(officialsAverageAge, 0)} />
          <Label label={local.officialsMale} value={`${getLatest(officialsMale, 0)}`} />
          <Label label={local.officialsMalePercent} value={`${Math.round((getLatest(officialsMale, 0) / getLatest(officials, 0)) * 10000) / 100}%`} />
        </Grid>
      </Grid>
    </Paper>
  );
}

InfoCityDetails.propTypes = {
  citizens: PropTypes.shape({}),
  city: PropTypes.string,
  district: PropTypes.string,
  electiondate: PropTypes.string,
  gkz: PropTypes.number,
  highestOffice: PropTypes.shape({}),
  members: PropTypes.shape({}),
  membersAverageAge: PropTypes.shape({}),
  officials: PropTypes.shape({}),
  officialsAverageAge: PropTypes.shape({}),
  officialsFemale: PropTypes.shape({}),
  officialsMale: PropTypes.shape({}),
  status: PropTypes.string,
};

InfoCityDetails.defaultProps = {
  citizens: undefined,
  city: undefined,
  district: undefined,
  electiondate: undefined,
  gkz: undefined,
  highestOffice: undefined,
  members: undefined,
  membersAverageAge: undefined,
  officials: undefined,
  officialsAverageAge: undefined,
  officialsFemale: undefined,
  officialsMale: undefined,
  status: undefined,
};

import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import RestoreIcon from '@mui/icons-material/Restore';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Confirm from '../../../../components/Confirm';
import Snackbar from '../../../../components/Snackbar';
import localization from './AgreementsPatch.local';

export default function AgreementsPatch({
  agreementcategories,
  agreements,
  agreementstatuses,
  onBack,
  onSetAgreements,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const { agreementid } = useParams();
  const [agreement, setAgreement] = useState();
  const [status, setStatus] = useState();
  const [category, setCategory] = useState();
  const [description, setDescription] = useState('');
  const [until, setUntil] = useState(moment().format('DD.MM.YYYY'));
  const [creator, setCreator] = useState();
  const [editor, setEditor] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [alert, setAlert] = useState();
  const [openConfirm, setOpenConfirm] = useState();

  useEffect(() => {
    if (agreements && agreementid) {
      const foundAgreement = agreements.find((item) => item.id === parseInt(agreementid, 10));
      if (foundAgreement) {
        setAgreement(foundAgreement);
      } else {
        onBack();
      }
    }
  }, [agreements, agreementid]);

  useEffect(() => {
    if (agreement) {
      setStatus(agreement.status);
      setCategory(agreement.category);
      setDescription(agreement.description);
      setUntil(agreement.until);
      socket.emit('users.getOneById', { id: agreement.creator }, ({ error, payload }) => (error ? setAlert(error) : setCreator(payload)));
      socket.emit('users.getOneById', { id: agreement.editor }, ({ error, payload }) => (error ? setAlert(error) : setEditor(payload)));
    }
  }, [agreement]);

  const acknowledgeChange = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetAgreements(agreements ? agreements.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
    }
  };

  const onChange = (field, value) => {
    const params = {
      id: agreement.id,
      city: agreement.city,
      district: agreement.district,
      field,
      value,
    };
    socket.emit('agreements.patch', params, acknowledgeChange);
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  const onArchive = () => {
    onChange('active', !agreement.active);
    onBack();
  };

  const acknowledgeDestroy = ({ error, payload }) => {
    if (error) {
      setAlert(error);
    } else {
      onSetAgreements(agreements ? agreements.filter((item) => (item.id !== payload.id)) : []);
      onBack();
    }
  };

  const onDestroy = () => {
    const params = {
      id: agreement.id,
      city: agreement.city,
      district: agreement.district,
    };
    socket.emit('agreements.destroy', params, acknowledgeDestroy);
  };

  return (
    <Paper sx={{ height: '100%', p: 5 }}>
      <Confirm
        title={local.destroyTitle}
        description={local.destroyDescription}
        agree={local.agree}
        disagree={local.disagree}
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={onDestroy}
      />
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h4">{local.title}</Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sx={{ p: 2 }}>
          <Typography>{local.smart}</Typography>
          <Typography>{local.s}</Typography>
          <Typography>{local.m}</Typography>
          <Typography>{local.a}</Typography>
          <Typography>{local.r}</Typography>
          <Typography>{local.t}</Typography>
        </Grid>
        <Grid container item xs={12} md={8} spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              disableClearable
              value={agreementstatuses && status ? agreementstatuses.find((item) => item.id === status) : null}
              options={agreementstatuses || null}
              onChange={(e, selected) => onChange('status', selected.id)}
              getOptionLabel={((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.status}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disableClearable
              value={agreementcategories && category ? agreementcategories.find((item) => item.id === category) : null}
              options={agreementcategories ? agreementcategories.filter((item) => item.active).sort((a, b) => b - a) : []}
              onChange={(e, selected) => onChange('category', selected.id)}
              getOptionLabel={((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.category}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="date"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.until}
              value={until}
              onChange={(e) => onTextChange(setUntil, 'until', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.description}
              value={description}
              onChange={(e) => onTextChange(setDescription, 'description', e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      { agreement && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} />
          <Grid container item xs={12} md={8} spacing={2}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={3}>
                <Typography>{local.createdAt}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{moment(agreement.updatedAt).format('DD.MM.YYYY')}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{local.from}</Typography>
              </Grid>
              <Grid item xs={4}>
                { creator && (
                  <Box display="flex">
                    <Avatar src={creator.avatar} alt={`${creator.firstname} ${creator.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
                    <Typography>{`${creator.firstname} ${creator.lastname}`}</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={3}>
                <Typography>{local.updatedAt}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{moment(agreement.updatedAt).format('DD.MM.YYYY')}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>{local.from}</Typography>
              </Grid>
              <Grid item xs={4}>
                { editor && (
                  <Box display="flex">
                    <Avatar src={editor.avatar} alt={`${editor.firstname} ${editor.lastname}`} sx={{ width: 30, height: 30, mr: 1 }} />
                    <Typography>{`${editor.firstname} ${editor.lastname}`}</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        { agreement && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => setOpenConfirm(true)}
            sx={{ justifyContent: 'flex-start' }}
          >
            <DeleteIcon sx={{ mr: 1 }} />
            <Typography>{local.destroy}</Typography>
          </Fab>
        )}
        { agreement && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={onArchive}
            sx={{ justifyContent: 'flex-start' }}
          >
            {agreement.active ? <ArchiveIcon sx={{ mr: 1 }} /> : <RestoreIcon sx={{ mr: 1 }} />}
            <Typography>{agreement.active ? local.archive : local.restore}</Typography>
          </Fab>
        )}
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

AgreementsPatch.propTypes = {
  agreementcategories: PropTypes.arrayOf(PropTypes.shape({})),
  agreements: PropTypes.arrayOf(PropTypes.shape({})),
  agreementstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSetAgreements: PropTypes.func.isRequired,
};

AgreementsPatch.defaultProps = {
  agreementcategories: undefined,
  agreements: undefined,
  agreementstatuses: undefined,
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

export default function ServicecategoriesTableToolbar({ local, onAddClick, onStatusesClick }) {
  return (
    <GridToolbarContainer>
      <Button variant="text" size="small" color="secondary">{local.categories}</Button>
      <Button variant="text" size="small" color="primary" onClick={onStatusesClick}>{local.statuses}</Button>
      <Button variant="text" size="small" color="primary" onClick={onAddClick}>
        <AddIcon />
        {local.add}
      </Button>
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{ delimiter: ';', utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}

ServicecategoriesTableToolbar.propTypes = {
  local: PropTypes.shape({
    add: PropTypes.string,
    categories: PropTypes.string,
    statuses: PropTypes.string,
  }).isRequired,
  onAddClick: PropTypes.func.isRequired,
  onStatusesClick: PropTypes.func.isRequired,
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import { LocalizationContext } from '../../../../AppContext';
import Label from '../../../../components/Label';

import localization from './InfoCityUsers.local';

export default function InfoCityUsers({
  city,
  district,
  users,
}) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Paper sx={{ width: '100%', p: 1, pb: 2 }}>
      <Typography variant="h6">{local.title}</Typography>
      <Grid container spacing={2}>
        {/* { users.filter((item) => item.control).map((item) => (
          <Grid item key={item.id} xs={12} md={3} sx={{ display: 'flex' }}>
            <Avatar alt={`${item.firstname} ${item.lastname}`} src={item.avatar} sx={{ mr: 1, mt: 1 }} />
            <Label label={local.control} value={`${item.firstname} ${item.lastname}`} />
          </Grid>
        ))} */}
        { users.filter((item) => item.steering.includes(district)).map((item) => (
          <Grid item key={item.id} xs={12} md={3} sx={{ display: 'flex' }}>
            <Avatar alt={`${item.firstname} ${item.lastname}`} src={item.avatar} sx={{ mr: 1, mt: 1 }} />
            <Label label={local.steering} value={`${item.firstname} ${item.lastname}`} />
          </Grid>
        ))}
        { users.filter((item) => item.coaching.includes(city)).map((item) => (
          <Grid item key={item.id} xs={12} md={3} sx={{ display: 'flex' }}>
            <Avatar alt={`${item.firstname} ${item.lastname}`} src={item.avatar} sx={{ mr: 1, mt: 1 }} />
            <Label label={local.coaching} value={`${item.firstname} ${item.lastname}`} />
          </Grid>
        ))}
        { users.filter((item) => item.watching.includes(city)).map((item) => (
          <Grid item key={item.id} xs={12} md={3} sx={{ display: 'flex' }}>
            <Avatar alt={`${item.firstname} ${item.lastname}`} src={item.avatar} sx={{ mr: 1, mt: 1 }} />
            <Label label={local.watching} value={`${item.firstname} ${item.lastname}`} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

InfoCityUsers.propTypes = {
  city: PropTypes.number.isRequired,
  district: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default {
  de: {
    title: 'Neue Datei hinzufügen',
    name: 'Name',
    description: 'Beschreibung',
    category: 'Kategorie',
    upload: 'Datei auswählen',
    submit: 'Datei hochladen',
    back: 'Zurück',
    alerts: {
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

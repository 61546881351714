export default {
  de: {
    title: 'Ziel bearbeiten',
    smart: 'Zieldefinition S.M.A.R.T',
    s: '- Spezifisch',
    m: '- Messbar',
    a: '- Attraktiv',
    r: '- Realisierbar',
    t: '- Terminiert',
    status: 'Status',
    category: 'Kategory',
    until: 'Bis',
    description: 'Beschreibung',
    submit: 'Ziel erstellen',
    createdAt: 'Erstellt am ',
    from: 'von',
    updatedAt: 'Zuletzt verändert am ',
    destroy: 'Löschen',
    archive: 'Archivieren',
    restore: 'Wiederherstellen',
    back: 'Zurück',
    destroyTitle: 'Möchten Sie das Ziel wirklich löschen?',
    destroyDescription: '',
    agree: 'Löschen',
    disagree: 'Abbrechen',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

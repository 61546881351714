import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import OfficialcategoriesPost from './OfficialcategoriesPost';
import OfficialcategoriesPatch from './OfficialcategoriesPatch';
import OfficialcategoriesTable from './OfficialcategoriesTable';

export default function Officialcategories({
  elections,
  officialcategories,
  onSetOfficialcategories,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <OfficialcategoriesPost
            elections={elections}
            officialcategories={officialcategories}
            onBack={() => navigate('/settings/officials/categories')}
            onSetOfficialcategories={onSetOfficialcategories}
          />
        )}
      />
      <Route
        path=":officialcategoryid"
        element={(
          <OfficialcategoriesPatch
            elections={elections}
            officialcategories={officialcategories}
            onBack={() => navigate('/settings/officials/categories')}
            onSetOfficialcategories={onSetOfficialcategories}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <OfficialcategoriesTable
            elections={elections}
            officialcategories={officialcategories}
            onRowClick={(id) => navigate(`/settings/officials/categories/${id}`)}
            onAddClick={() => navigate('/settings/officials/categories/post')}
            onTypesClick={() => navigate('/settings/officials/types')}
          />
        )}
      />
    </Routes>
  );
}

Officialcategories.propTypes = {
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  officialcategories: PropTypes.arrayOf(PropTypes.shape({})),
  onSetOfficialcategories: PropTypes.func.isRequired,
};

Officialcategories.defaultProps = {
  elections: undefined,
  officialcategories: undefined,
};

export default function AgreementsTableRowsMapper(
  local,
  archive,
  cities,
  city,
  district,
  districts,
  servicecategories,
  services,
  servicestatuses,
) {
  let rows;
  if (servicecategories && services && servicestatuses && cities && districts) {
    rows = services
      .filter((service) => {
        const isDistrict = !district || service.availabledistricts.includes(district.id);
        const isCity = !city || service.availablecities.includes(city.id);
        const isActive = archive !== service.active;
        // const serviceCategory = servicecategories.find((item) => item.id === agreement.category);
        // return isActive && isDistrict && isCity && agreementCategory.active;
        return isActive && isDistrict && isCity;
      })
      .map((service) => {
        // const itemCategory = agreementcategories.find((item) => item.id === agreement.category);
        // const itemStatus = agreementstatuses.find((item) => item.id === agreement.status);
        console.log('todo');
        return {
          id: service.id,
          name: service.name,
          description: service.description,
          date: service.date,
          // category: itemCategory ? itemCategory.name : '',
          // status: itemStatus ? itemStatus.name : '',
          creator: service.creator,
          createdAt: service.createdAt,
          editor: service.editor,
          updatedAt: service.updatedAt,
        };
      });
  }
  return rows;
}

import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ServicestatusesPost from './ServicestatusesPost';
import ServicestatusesPatch from './ServicestatusesPatch';
import ServicestatusesTable from './ServicestatusesTable';

export default function Servicestatuses({
  elections,
  servicestatuses,
  onSetServicestatuses,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <ServicestatusesPost
            elections={elections}
            servicestatuses={servicestatuses}
            onBack={() => navigate('/settings/services/statuses')}
            onSetServicestatuses={onSetServicestatuses}
          />
        )}
      />
      <Route
        path=":servicestatusid"
        element={(
          <ServicestatusesPatch
            elections={elections}
            servicestatuses={servicestatuses}
            onBack={() => navigate('/settings/services/statuses')}
            onSetServicestatuses={onSetServicestatuses}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <ServicestatusesTable
            elections={elections}
            servicestatuses={servicestatuses}
            onRowClick={(id) => navigate(`/settings/services/statuses/${id}`)}
            onAddClick={() => navigate('/settings/services/statuses/post')}
            onCategoriesClick={() => navigate('/settings/services/categories')}
          />
        )}
      />
    </Routes>
  );
}

Servicestatuses.propTypes = {
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  servicestatuses: PropTypes.arrayOf(PropTypes.shape({})),
  onSetServicestatuses: PropTypes.func.isRequired,
};

Servicestatuses.defaultProps = {
  elections: undefined,
  servicestatuses: undefined,
};

import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext, UserContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import ColumnDefinition from './CitiesColumnDefinition';
import RowsMapper from './CitiesRowsMapper';
import CitiesToolbar from './CitiesToolbar';
import localization from './Cities.local';

export default function Cities({
  cities,
  citysettingdefinitions,
  citysettings,
  district,
  districts,
  elections,
  gridviews,
  officials,
  parties,
  onSetGridviews,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const user = useContext(UserContext);
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [gridview, setGridview] = useState();
  const [alert, setAlert] = useState();
  const navigate = useNavigate();

  useEffect(() => setColumns(ColumnDefinition(local, citysettingdefinitions, district, user)), [citysettingdefinitions, district, user]);
  useEffect(() => setRows(RowsMapper(
    local,
    cities,
    citysettingdefinitions,
    citysettings,
    districts,
    district,
    elections,
    officials,
    parties,
    user,
  )), [cities, citysettings, districts, district, elections, officials, parties, user]);

  const onChangeGridview = (selectedGridview) => setGridview(selectedGridview || {});

  const acknowledgePost = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetGridviews(gridviews ? [...gridviews, payload] : [payload]);
      setGridview(payload);
    }
  };

  const onPostGridview = (grid, view, global) => {
    socket.emit('gridviews.post', {
      ...gridview, view, grid, global,
    }, acknowledgePost);
  };

  const acknowledgePatch = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetGridviews(gridviews ? gridviews.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
      setGridview(payload);
    }
  };
  const onPatchGridview = (view, global) => {
    socket.emit('gridviews.patch', { ...gridview, view, global }, acknowledgePatch);
  };

  const acknowledgeDelete = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetGridviews(gridviews.filter((item) => item.id !== payload.id));
      setGridview({});
    }
  };
  const onDeleteGridview = () => {
    socket.emit('gridviews.destroy', gridview, acknowledgeDelete);
  };

  return (
    <Paper sx={{ height: '100%' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      { columns && rows && (
      <DataGridPro
        sx={{ height: '100%' }}
        rows={rows}
        columns={columns}
        components={{ Toolbar: CitiesToolbar }}
        componentsProps={{
          toolbar: {
            gridview, gridviews, onChangeGridview, onPostGridview, onPatchGridview, onDeleteGridview,
          },
        }}
        columnVisibilityModel={gridview?.columnVisibilityModel || {}}
        sortModel={gridview?.sortModel || []}
        filterModel={gridview?.filterModel || { items: [] }}
        onRowClick={({ row }) => navigate(`/${row.districtid}/${row.id}`)}
        onColumnVisibilityModelChange={(model) => setGridview({ ...gridview, columnVisibilityModel: model })}
        onSortModelChange={(model) => setGridview({ ...gridview, sortModel: model })}
        onFilterModelChange={(model) => setGridview({ ...gridview, filterModel: model })}
      />
      )}
    </Paper>
  );
}

Cities.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  citysettings: PropTypes.arrayOf(PropTypes.shape({})),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  gridviews: PropTypes.arrayOf(PropTypes.shape({})),
  officials: PropTypes.arrayOf(PropTypes.shape({})),
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  onSetGridviews: PropTypes.func.isRequired,
};

Cities.defaultProps = {
  cities: undefined,
  citysettingdefinitions: undefined,
  citysettings: undefined,
  districts: undefined,
  district: undefined,
  elections: undefined,
  gridviews: undefined,
  officials: undefined,
  parties: undefined,
};

export default function OfficialsRowsMapper(
  local,
  cities,
  city,
  districts,
  district,
  officialcategory,
  officials,
  officialtypes,
  parties,
) {
  let rows;
  if (cities && districts && officialcategory && officials && officialtypes && parties) {
    rows = officials
      .filter((official) => {
        const isDistrict = !district || district.id === official.district;
        const isCity = !city || city.id === official.city;
        const hasCity = official.city;
        const officialType = officialtypes.find((item) => item.id === official.type);
        return hasCity && isDistrict && isCity && officialType.active && officialType.category === officialcategory;
      })
      .map((official) => {
        const officialDistrict = district || districts.find((item) => item.id === official.district);
        const officialCity = city || cities.find((item) => item.id === official.city);
        const officialType = officialtypes.find((item) => item.id === official.type);
        const party = parties.find((item) => item.id === official.party).name;
        return {
          id: official.id,
          district: officialDistrict ? officialDistrict.name : '',
          city: officialCity ? officialCity.name : '',
          gkz: officialCity ? officialCity.gkz : '',
          name: `${official.firstname} ${official.lastname}`,
          party,
          type: officialType ? officialType.name : '',
          birthdate: official.birthdate,
          email: official.email,
          phone: official.phone,
          mail: official.post,
          since: official.since,
        };
      });
  }
  return rows;
}

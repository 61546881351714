export default {
  de: {
    add: 'Bundespräsidenten hinzufügen',
    types: 'Wahltypen',
    parties: 'Parteien',
    presidents: 'Bundespräsidenten',
    name: 'Name',
    party: 'Partei',
    color: 'Farbcode',
    updatedAt: 'zuletzt bearbeitet am',
    editor: 'zuletzt bearbeitet von',
  },
};

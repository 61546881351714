const HHTPCODE_INFO_POST = 101;
const HHTPCODE_INFO_PATCH = 102;
const HHTPCODE_INFO_DESTROY = 103;

export default (socket, onAlert, namespace, list, setList, room) => {
  socket.on(`${namespace}.post`, (response) => {
    if ((room && room === response.room) || !room) {
      setList(list ? [...list, response.payload] : [response.payload]);
      onAlert(HHTPCODE_INFO_POST, namespace, response.payload);
    }
  });
  socket.on(`${namespace}.patch`, (response) => {
    if ((room && room === response.room) || !room) {
      setList(list ? list.map((item) => (item.id === response.payload.id ? response.payload : item)) : [response.payload]);
      onAlert(HHTPCODE_INFO_PATCH, namespace, response.payload);
    }
  });
  socket.on(`${namespace}.destroy`, (response) => {
    if ((room && room === response.room) || !room) {
      setList(list ? list.filter((item) => (item.id !== response.payload.id)) : undefined);
      onAlert(HHTPCODE_INFO_DESTROY, namespace, response.payload);
    }
  });
  return () => {
    socket.off(`${namespace}.post`);
    socket.off(`${namespace}.patch`);
    socket.off(`${namespace}.destroy`);
  };
};

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import { LocalizationContext, SocketContext } from '../../../../../AppContext';
import Snackbar from '../../../../../components/Snackbar';
import localization from './ServicecategoriesPost.local';

export default function ServicecategoriesPost({
  servicecategories,
  onBack,
  onSetServicecategories,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [name, setName] = useState('');
  const [alert, setAlert] = useState();

  const acknowledge = ({ error, payload }) => {
    if (error) {
      setAlert(error);
    } else {
      onSetServicecategories(servicecategories ? [...servicecategories, payload] : [payload]);
      onBack();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const post = {
      name,
    };
    socket.emit('servicecategories.post', post, acknowledge);
  };

  return (
    <Paper component="form" onSubmit={onSubmit} sx={{ height: '100%', p: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h4">{local.title}</Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.name}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
        <Fab
          variant="extended"
          color="primary"
          type="submit"
          size="small"
          sx={{ justifyContent: 'flex-start' }}
        >
          <SaveIcon sx={{ mr: 1 }} />
          <Typography>{local.submit}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

ServicecategoriesPost.propTypes = {
  servicecategories: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSetServicecategories: PropTypes.func.isRequired,
};

ServicecategoriesPost.defaultProps = {
  servicecategories: undefined,
};

import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import { LocalizationContext, SocketContext, UserContext } from '../../AppContext';
import Snackbar from '../../components/Snackbar';
import syn from '../../util/syn';
import get from '../../util/get';
import Profile from './profile/Profile';
import Gemo from './gemo/Gemo';
import Settings from './settings/Settings';
import AuthenticatedBar from './AuthenticatedBar';
import localization from './Authenticated.local';

export default function Authenticated({
  theme, onLogout, onUpdateUser, onThemeChange,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const user = useContext(UserContext);
  const [agreementcategories, setAgreementcategories] = useState();
  const [agreements, setAgreements] = useState();
  const [agreementstatuses, setAgreementstatuses] = useState();
  const [cities, setCities] = useState();
  const [citysettingdefinitions, setCitysettingdefinitions] = useState();
  const [citysettings, setCitysettings] = useState();
  const [districts, setDistricts] = useState();
  const [elections, setElections] = useState();
  const [electiontypes, setElectiontypes] = useState();
  const [files, setFiles] = useState();
  const [gridviews, setGridviews] = useState();
  const [messages, setMessages] = useState();
  const [officialcategories, setOfficialcategories] = useState();
  const [officials, setOfficials] = useState();
  const [officialtypes, setOfficialtypes] = useState();
  const [parties, setParties] = useState();
  const [presidents, setPresidents] = useState();
  const [reportdefinitions, setReportdefinitions] = useState();
  const [reports, setReports] = useState();
  const [reportstatuses, setReportstatuses] = useState();
  const [servicecategories, setServicecategories] = useState();
  const [services, setServices] = useState();
  const [servicestatuses, setServicestatuses] = useState();
  const [states, setStates] = useState();
  const [timelines, setTimelines] = useState();
  const [users, setUsers] = useState();
  const [logs, setLogs] = useState();
  const [alert, setAlert] = useState();

  const onAlert = (code, namespace, payload) => setAlert({ code, props: { namespace, payload } });

  useEffect(() => {
    get(socket, onAlert, 'agreementcategories', agreementcategories, setAgreementcategories);
    get(socket, onAlert, 'agreements', agreements, setAgreements);
    get(socket, onAlert, 'agreementstatuses', agreementstatuses, setAgreementstatuses);
    get(socket, onAlert, 'cities', cities, setCities);
    get(socket, onAlert, 'citysettingdefinitions', citysettingdefinitions, setCitysettingdefinitions);
    get(socket, onAlert, 'citysettings', citysettings, setCitysettings);
    get(socket, onAlert, 'districts', districts, setDistricts);
    get(socket, onAlert, 'elections', elections, setElections);
    get(socket, onAlert, 'electiontypes', electiontypes, setElectiontypes);
    get(socket, onAlert, 'files', files, setFiles);
    get(socket, onAlert, 'gridviews', gridviews, setGridviews);
    get(socket, onAlert, 'messages', messages, setMessages);
    get(socket, onAlert, 'officialcategories', officialcategories, setOfficialcategories);
    get(socket, onAlert, 'officials', officials, setOfficials);
    get(socket, onAlert, 'officialtypes', officialtypes, setOfficialtypes);
    get(socket, onAlert, 'parties', parties, setParties);
    get(socket, onAlert, 'presidents', presidents, setPresidents);
    get(socket, onAlert, 'reportdefinitions', reportdefinitions, setReportdefinitions);
    get(socket, onAlert, 'reports', reports, setReports);
    get(socket, onAlert, 'reportstatuses', reportstatuses, setReportstatuses);
    get(socket, onAlert, 'servicecategories', servicecategories, setServicecategories);
    get(socket, onAlert, 'services', services, setServices);
    get(socket, onAlert, 'servicestatuses', servicestatuses, setServicestatuses);
    get(socket, onAlert, 'states', states, setStates);
    get(socket, onAlert, 'timelines', timelines, setTimelines);
    get(socket, onAlert, 'users', users, setUsers);
    get(socket, onAlert, 'logs', logs, setLogs);
  }, []);

  useEffect(() => syn(socket, onAlert, 'agreementcategories', agreementcategories, setAgreementcategories), [agreementcategories]);
  useEffect(() => syn(socket, onAlert, 'agreements', agreements, setAgreements), [agreements]);
  useEffect(() => syn(socket, onAlert, 'agreementstatuses', agreementstatuses, setAgreementcategories), [agreementstatuses]);
  useEffect(() => syn(socket, onAlert, 'cities', cities, setCities), [cities]);
  useEffect(() => syn(socket, onAlert, 'citysettingdefinitions', citysettingdefinitions, setCitysettingdefinitions), [citysettingdefinitions]);
  useEffect(() => syn(socket, onAlert, 'citysettings', citysettings, setCitysettings), [citysettings]);
  useEffect(() => syn(socket, onAlert, 'districts', districts, setDistricts), [districts]);
  useEffect(() => syn(socket, onAlert, 'elections', elections, setElections), [elections]);
  useEffect(() => syn(socket, onAlert, 'electiontypes', electiontypes, setElectiontypes), [electiontypes]);
  useEffect(() => syn(socket, onAlert, 'files', files, setFiles), [files]);
  useEffect(() => syn(socket, onAlert, 'gridviews', gridviews, setGridviews), [gridviews]);
  useEffect(() => syn(socket, onAlert, 'messages', messages, setMessages), [messages]);
  useEffect(() => syn(socket, onAlert, 'officialcategories', officialcategories, setOfficialcategories), [officialcategories]);
  useEffect(() => syn(socket, onAlert, 'officials', officials, setOfficials), [officials]);
  useEffect(() => syn(socket, onAlert, 'officialtypes', officialtypes, setOfficialtypes), [officialtypes]);
  useEffect(() => syn(socket, onAlert, 'parties', parties, setParties), [parties]);
  useEffect(() => syn(socket, onAlert, 'presidents', presidents, setPresidents), [presidents]);
  useEffect(() => syn(socket, onAlert, 'reportdefinitions', reportdefinitions, setReportdefinitions), [reportdefinitions]);
  useEffect(() => syn(socket, onAlert, 'reports', reports, setReports), [reports]);
  useEffect(() => syn(socket, onAlert, 'reportstatuses', reportstatuses, setReportstatuses), [reportstatuses]);
  useEffect(() => syn(socket, onAlert, 'servicecategories', servicecategories, setServicecategories), [servicecategories]);
  useEffect(() => syn(socket, onAlert, 'services', services, setServices), [services]);
  useEffect(() => syn(socket, onAlert, 'servicestatuses', servicestatuses, setServicestatuses), [servicestatuses]);
  useEffect(() => syn(socket, onAlert, 'states', states, setStates), [states]);
  useEffect(() => syn(socket, onAlert, 'timelines', timelines, setTimelines), [timelines]);
  useEffect(() => syn(socket, onAlert, 'users', users, setUsers), [users]);
  useEffect(() => syn(socket, onAlert, 'logs', logs, setLogs), [logs]);

  return (
    <Grid container>
      <Snackbar alert={alert ? alert.code : undefined} alertProps={alert ? alert.props : undefined} local={local.alerts} onClose={() => setAlert()} />
      <Grid item xs={12}>
        <AuthenticatedBar theme={theme} onLogout={onLogout} onThemeChange={onThemeChange} />
      </Grid>
      <Grid item xs={12}>
        <Routes>
          <Route path="/profile" element={(<Profile onUpdateUser={onUpdateUser} />)} />
          <Route
            path="/:districtid/:cityid/*"
            element={(
              <Gemo
                agreementcategories={agreementcategories}
                agreements={agreements}
                agreementstatuses={agreementstatuses}
                cities={cities}
                citysettingdefinitions={citysettingdefinitions}
                citysettings={citysettings}
                districts={districts}
                elections={elections}
                electiontypes={electiontypes}
                files={files}
                gridviews={gridviews}
                messages={messages}
                officialcategories={officialcategories}
                officials={officials}
                officialtypes={officialtypes}
                parties={parties}
                presidents={presidents}
                reportdefinitions={reportdefinitions}
                reports={reports}
                reportstatuses={reportstatuses}
                servicecategories={servicecategories}
                services={services}
                servicestatuses={servicestatuses}
                states={states}
                timelines={timelines}
                users={users}
                onSetAgreements={setAgreements}
                onSetCities={setCities}
                onSetCitysettings={setCitysettings}
                onSetDistricts={setDistricts}
                onSetElections={setElections}
                onSetFiles={setFiles}
                onSetGridviews={setGridviews}
                onSetMessages={setMessages}
                onSetOfficials={setOfficials}
                onSetReports={setReports}
                onSetServices={setServices}
                onSetStates={setStates}
                onSetTimelines={setTimelines}
                onSetUsers={setUsers}
              />
            )}
          />
          { user.admin && (
            <Route
              path="/settings/*"
              element={(
                <Settings
                  agreementcategories={agreementcategories}
                  agreements={agreements}
                  agreementstatuses={agreementstatuses}
                  cities={cities}
                  citysettingdefinitions={citysettingdefinitions}
                  citysettings={citysettings}
                  districts={districts}
                  elections={elections}
                  electiontypes={electiontypes}
                  officialcategories={officialcategories}
                  officials={officials}
                  officialtypes={officialtypes}
                  parties={parties}
                  presidents={presidents}
                  reportdefinitions={reportdefinitions}
                  reports={reports}
                  reportstatuses={reportstatuses}
                  servicecategories={servicecategories}
                  services={services}
                  servicestatuses={servicestatuses}
                  states={states}
                  users={users}
                  logs={logs}
                  onSetAgreementcategories={setAgreementcategories}
                  onSetAgreementstatuses={setAgreementstatuses}
                  onSetCitysettingdefinitions={setCitysettingdefinitions}
                  onSetCitysettings={setCitysettings}
                  onSetElectiontypes={setElectiontypes}
                  onSetOfficialcategories={setOfficialcategories}
                  onSetOfficialtypes={setOfficialtypes}
                  onSetParties={setParties}
                  onSetPresidents={setPresidents}
                  onSetReportdefinitions={setReportdefinitions}
                  onSetReportstatuses={setReportstatuses}
                  onSetServicecategories={setServicecategories}
                  onSetServicestatuses={setServicestatuses}
                  onSetStates={setStates}
                  onSetUsers={setUsers}
                  onUpdateUser={onUpdateUser}
                />
              )}
            />
          )}
          <Route path="*" element={<Navigate to="/-/-" />} />
        </Routes>
      </Grid>
    </Grid>
  );
}

Authenticated.propTypes = {
  theme: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  onThemeChange: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
};

export default {
  de: {
    users: 'Benutzer',
    agreements: 'Ziele',
    citysettingdefinitions: 'Gemeinde',
    citysettings: 'GE-Mehrfach',
    elections: 'Wahlen',
    officials: 'Funktionen',
    reports: 'Berichte',
    services: 'Leistungen',
    logs: 'Logs',
  },
};

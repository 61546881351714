export default function PresidentsTableRowsMapper(local, parties, presidents) {
  let rows;
  if (parties && presidents) {
    rows = presidents.map((row) => {
      const party = parties.find((item) => item.id === row.party);
      return {
        id: row.id,
        name: row.name,
        party: party ? party.name : undefined,
        color: row.color,
        updatedAt: row.updatedAt,
        editor: row.editor,
      };
    });
  }
  return rows;
}

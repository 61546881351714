export default function UsersTableRowsMapper(
  local,
  cities,
  districts,
  users,
) {
  let rows;
  if (cities && districts && users) {
    return users.map((user) => {
      const steering = user.steering.map((id) => {
        const district = districts.find((item) => item.id === id);
        return district ? district.name : '';
      });
      const coaching = user.coaching.map((id) => {
        const city = cities.find((item) => item.id === id);
        return city ? city.name : '';
      });
      const watching = user.watching.map((id) => {
        const city = cities.find((item) => item.id === id);
        return city ? city.name : '';
      });
      return ({
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        avatar: user.avatar,
        active: user.active,
        admin: user.admin,
        control: user.control,
        services: user.services,
        steering: steering.join(', '),
        coaching: coaching.join(', '),
        watching: watching.join(', '),
      });
    });
  }
  return rows;
}

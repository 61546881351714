export default {
  de: {
    control: 'Steuerung',
    steering: 'Lenkung',
    coaching: 'Coaching',
    watching: 'Beobachtung',
    list: 'Alle Gemeinden:',
    districtFilter: 'Bezirk Filter',
    cityFilter: 'Gemeinde Filter',
  },
};

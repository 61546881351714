import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Electiontypes from './electiontypes/Electiontypes';
import Parties from './parties/Parties';
import Presidents from './presidents/Presidents';

export default function Elections({
  elections,
  electiontypes,
  parties,
  presidents,
  onSetElectiontypes,
  onSetParties,
  onSetPresidents,
}) {
  return (
    <Routes>
      <Route
        path="types/*"
        element={(
          <Electiontypes
            elections={elections}
            electiontypes={electiontypes}
            onSetElectiontypes={onSetElectiontypes}
          />
        )}
      />
      <Route
        path="parties/*"
        element={(
          <Parties
            elections={elections}
            parties={parties}
            onSetParties={onSetParties}
          />
        )}
      />
      <Route
        path="presidents/*"
        element={(
          <Presidents
            parties={parties}
            presidents={presidents}
            onSetPresidents={onSetPresidents}
          />
        )}
      />
      <Route path="*" element={<Navigate to="types" />} />
    </Routes>
  );
}

Elections.propTypes = {
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  electiontypes: PropTypes.arrayOf(PropTypes.shape({})),
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  presidents: PropTypes.arrayOf(PropTypes.shape({})),
  onSetElectiontypes: PropTypes.func.isRequired,
  onSetParties: PropTypes.func.isRequired,
  onSetPresidents: PropTypes.func.isRequired,
};

Elections.defaultProps = {
  elections: undefined,
  electiontypes: undefined,
  parties: undefined,
  presidents: undefined,
};

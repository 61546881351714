export default function OfficialcategoriesTableRowsMapper(local, officialcategories) {
  let rows;
  if (officialcategories) {
    rows = officialcategories.map((row) => ({
      id: row.id,
      name: row.name,
    }));
  }
  return rows;
}

export default function CitiesColumnDefinition(
  local,
  city,
  district,
) {
  const colDef = [{
    type: 'string',
    field: 'type',
    headerName: local.type,
    minWidth: 300,
    flex: 3,
  }, {
    type: 'string',
    field: 'name',
    headerName: local.name,
    minWidth: 250,
    flex: 2,
  }, {
    type: 'string',
    field: 'party',
    headerName: local.party,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'date',
    field: 'birthdate',
    headerName: local.birthdate,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'email',
    headerName: local.email,
    minWidth: 250,
    flex: 2,
  }, {
    type: 'string',
    field: 'phone',
    headerName: local.phone,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'mail',
    headerName: local.mail,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'date',
    field: 'since',
    headerName: local.since,
    minWidth: 150,
    flex: 1,
  }];
  if (!district) {
    colDef.unshift({
      field: 'district',
      type: 'string',
      headerName: local.district,
      minWidth: 180,
      flex: 1,
    });
  }
  if (!city) {
    colDef.unshift({
      field: 'city',
      type: 'string',
      headerName: local.city,
      minWidth: 180,
      flex: 1,
    });
  }
  if (!city) {
    colDef.unshift({
      field: 'gkz',
      type: 'string',
      headerName: local.gkz,
      minWidth: 80,
      flex: 1,
    });
  }
  return colDef;
}

export default {
  de: {
    title: 'Benutzer erstellen',
    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'Email',
    admin: 'Administrator',
    control: 'Steuerung',
    services: 'Leistungen',
    steering: 'Lenkung',
    coaching: 'Coaching',
    watching: 'Beobachtung',
    submit: 'Erstellen',
    back: 'Zurück',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

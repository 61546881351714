export default function CitysettingdefinitionsTableRowsMapper(local, citysettingdefinitions) {
  let rows;
  if (citysettingdefinitions) {
    rows = citysettingdefinitions.map((row) => ({
      id: row.id,
      name: row.name,
      type: local[row.type],
      sort: row.sort,
      active: row.active,
      display: row.display,
      localparty: row.localparty,
      updatedAt: row.updatedAt,
      editor: row.editor,
    }));
  }
  return rows;
}

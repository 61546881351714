import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Chart from 'react-google-charts';

export default function InfoStatisticColumn({ title, labels, data }) {
  return (
    <Paper sx={{ width: '100%', p: 1, pb: 2 }}>
      <Typography variant="h6">{title}</Typography>
      <Box sx={{ maxWidth: 800 }}>
        <Chart
          key={title}
          chartType="ColumnChart"
          height="100%"
          width="500px"
          data={[
            [...labels, { role: 'annotation' }, { role: 'style' }],
            ...Object.keys(data)
              .map((key) => [data[key].name, data[key].value, data[key].value, `color: ${data[key].color}`])
              .sort((a, b) => b[1] - a[1]),
          ]}
          loader={<Skeleton variant="rect" style={{ height: '100%', width: '100%' }} />}
          options={{
            legend: 'none',
            backgroundColor: 'transparent',
          }}
        />
      </Box>
    </Paper>
  );
}

InfoStatisticColumn.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.shape({}),
};

InfoStatisticColumn.defaultProps = {
  title: undefined,
  labels: undefined,
  data: undefined,
};

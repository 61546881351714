const namespaceLocal = {
  de: {
    agreementcategories: 'Ziel Kategorie',
    agreements: 'Ziel',
    agreementstatuses: 'Ziel Status',
    cities: 'Gemeinde',
    citysettingdefinitions: 'Gemeinde Einstellungsdefinition',
    citysettings: 'Gemeinde Einstellung',
    districts: 'Bezirk',
    elections: 'Wahlergebniss',
    electiontypes: 'Wahltyp',
    officialcategories: 'Funktionskategorie',
    officials: 'Funktion',
    officialtypes: 'Funktionstyp',
    parties: 'Partei',
    presidents: 'Bundespräsident',
    reportdefinitions: 'Berichtdefinition',
    reports: 'Bericht',
    reportstatuses: 'Berichtstatus',
    servicecategories: 'Leistungskategorie',
    services: 'Leistung',
    servicestatuses: 'Leistungstatus',
    states: 'Bundesland',
    timelines: 'Historie',
  },
};

export default {
  de: {
    alerts: {
      101: ({ namespace, payload }) => `${namespaceLocal.de[namespace]} ${payload.name || ''} wurde neu angelegt!`,
      102: ({ namespace, payload }) => `${namespaceLocal.de[namespace]} ${payload.name || ''} hat ein update erhalten!`,
      103: ({ namespace, payload }) => `${namespaceLocal.de[namespace]} ${payload.name || ''} wurde gelöscht!`,
    },
  },
};

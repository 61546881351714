export default {
  de: {
    add: 'Status hinzufügen',
    categories: 'Kategorien',
    statuses: 'Status',
    name: 'Name',
    key: 'Schlüsselwert',
    color: 'Farbcode',
    description: 'Beschreibung',
    activeAgreements: 'aktive Ziele',
    inactiveAgreements: 'archivierte Ziele',
    updatedAt: 'zuletzt bearbeitet am',
    editor: 'zuletzt bearbeitet von',
  },
};

export default function UsersTableColumnDefinition(local) {
  return [{
  //   type: 'string',
  //   field: 'avatar',
  //   headerName: local.avatar,
  //   minWidth: 250,
  //   flex: 1,
  // }, {
    type: 'string',
    field: 'firstname',
    headerName: local.firstname,
    minWidth: 250,
    flex: 2,
  }, {
    type: 'string',
    field: 'lastname',
    headerName: local.lastname,
    minWidth: 250,
    flex: 2,
  }, {
    type: 'string',
    field: 'email',
    headerName: local.email,
    minWidth: 300,
    flex: 2,
  }, {
    type: 'boolean',
    field: 'active',
    headerName: local.active,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'boolean',
    field: 'admin',
    headerName: local.admin,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'boolean',
    field: 'control',
    headerName: local.control,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'boolean',
    field: 'services',
    headerName: local.services,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'steering',
    headerName: local.steering,
    minWidth: 400,
    flex: 2,
  }, {
    type: 'string',
    field: 'coaching',
    headerName: local.coaching,
    minWidth: 400,
    flex: 2,
  }, {
    type: 'string',
    field: 'watching',
    headerName: local.watching,
    minWidth: 400,
    flex: 2,
  }];
}

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  MonthView,
  WeekView,
  Toolbar,
  DateNavigator,
  Appointments,
  TodayButton,
  ViewSwitcher,
  AllDayPanel,
} from '@devexpress/dx-react-scheduler-material-ui';

import { LocalizationContext } from '../../../../AppContext';
import DownloadIcsButton from './DownloadIcsButton';
import localization from './CalendarView.local';

export default function CalendarView({
  agreementcategories,
  agreements,
  agreementstatuses,
  cities,
  city,
  district,
  districts,
  users,
  officials,
  onRowClick,
  onAddClick,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [events, setEvents] = React.useState();

  React.useEffect(() => {
    if (agreements && officials) {
      setEvents([
        ...agreements.map((agreement) => ({
          title: agreement.description,
          startDate: `${agreement.until}T00:00`,
          endDate: `${agreement.until}T24:00`,
          allday: true,
        })),
        ...officials.filter((official) => official.type === 1).map((official) => ({
          title: `Bgm. ${official.firstname} ${official.lastname}`,
          startDate: `${moment(official.birthdate, 'DD.MM.YYYY').format('YYYY-MM-DD')}T00:00`,
          endDate: `${moment(official.birthdate, 'DD.MM.YYYY').format('YYYY-MM-DD')}T24:00`,
          rRule: 'RRULE:FREQ=YEARLY',
          allday: true,
        })),
        {
          title: 'Test',
          startDate: '2023-08-02T00:00',
          endDate: '2023-08-02T24:00',
          allday: true,
        },
      ]);
    }
  }, [agreements, officials]);

  console.trace({
    agreementcategories,
    agreementstatuses,
    cities,
    city,
    district,
    districts,
    users,
    onRowClick,
    onAddClick,
    local,
  });
  console.info({
    events,
  });
  return (
    <Paper sx={{ height: '100%' }}>
      <Scheduler data={events} locale="de-AT" firstDayOfWeek={1}>
        <ViewState />
        <MonthView />
        <WeekView />
        <Toolbar />
        <DateNavigator />
        <TodayButton />
        <Appointments />
        <ViewSwitcher />
        <AllDayPanel />
      </Scheduler>
      <DownloadIcsButton events={events} />
    </Paper>
  );
}

CalendarView.propTypes = {
  agreementcategories: PropTypes.arrayOf(PropTypes.shape({})),
  agreements: PropTypes.arrayOf(PropTypes.shape({})),
  agreementstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  officials: PropTypes.arrayOf(PropTypes.shape({})),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
};

CalendarView.defaultProps = {
  agreementcategories: undefined,
  agreements: undefined,
  agreementstatuses: undefined,
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
  users: undefined,
  officials: undefined,
};

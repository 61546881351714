import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import localization from './FilesPost.local';

export default function FilesPost({
  city,
  files,
  onBack,
  onSetFiles,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [file, setFile] = useState();
  const [filename, setFilename] = useState();
  const [size, setSize] = useState();
  const [type, setType] = useState();
  const [alert, setAlert] = useState();

  const acknowledge = ({ error, payload }) => {
    if (error) {
      setAlert(error);
    } else {
      onSetFiles(files ? [...files, payload] : [payload]);
      onBack();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (file) {
      const filePost = {
        city: city.id,
        district: city.district,
        name,
        description,
        file,
        category,
        size,
        type,
      };
      socket.emit('files.post', filePost, acknowledge);
    }
  };

  const onError = () => console.error('Error uploading file');
  const onFileChange = (e) => {
    const uploadedFile = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(uploadedFile);
    fileReader.onload = () => {
      setFile(fileReader.result);
      setFilename(uploadedFile.name);
      setSize(`${Math.round(((uploadedFile.size / 1024) / 1024) * 100) / 100} MB`);
      setType(uploadedFile.type);
      if (name === '') {
        setName(uploadedFile.name);
      }
    };
    fileReader.onerror = (error) => onError('Error: ', error);
  };

  return (
    <Paper component="form" onSubmit={onSubmit} sx={{ height: '100%', p: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h4">{local.title}</Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            variant="outlined"
            color="secondary"
            margin="dense"
            label={local.name}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            color="secondary"
            margin="dense"
            label={local.category}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            variant="outlined"
            color="secondary"
            margin="dense"
            label={local.description}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" component="label">
            {local.upload}
            <input hidden accept="*" type="file" onChange={onFileChange} />
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          {type && type.includes('image') && (
            <img src={file} alt={filename} width="100%" height="100%" />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <p>{filename && (`Datei: ${filename}`)}</p>
          <p>{size && (`Dateigröße: ${size} (max. 100 MB)`)}</p>
          <p>{type && (`Dateityp: ${type}`)}</p>
        </Grid>
      </Grid>
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
        <Fab
          variant="extended"
          color="primary"
          type="submit"
          size="small"
          sx={{ justifyContent: 'flex-start' }}
        >
          <SaveIcon sx={{ mr: 1 }} />
          <Typography>{local.submit}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

FilesPost.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
    district: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  onBack: PropTypes.func.isRequired,
  onSetFiles: PropTypes.func.isRequired,
};

FilesPost.defaultProps = {
  files: undefined,
  city: undefined,
  district: undefined,
};

import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import AgreementcategoriesPost from './AgreementcategoriesPost';
import AgreementcategoriesPatch from './AgreementcategoriesPatch';
import AgreementcategoriesTable from './AgreementcategoriesTable';

export default function Agreementcategories({
  agreementcategories,
  agreements,
  onSetAgreementcategories,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <AgreementcategoriesPost
            agreementcategories={agreementcategories}
            agreements={agreements}
            onBack={() => navigate('/settings/agreements/categories')}
            onSetAgreementcategories={onSetAgreementcategories}
          />
        )}
      />
      <Route
        path=":agreementcategoryid"
        element={(
          <AgreementcategoriesPatch
            agreementcategories={agreementcategories}
            agreements={agreements}
            onBack={() => navigate('/settings/agreements/categories')}
            onSetAgreementcategories={onSetAgreementcategories}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <AgreementcategoriesTable
            agreementcategories={agreementcategories}
            agreements={agreements}
            onRowClick={(id) => navigate(`/settings/agreements/categories/${id}`)}
            onAddClick={() => navigate('/settings/agreements/categories/post')}
            onStatusesClick={() => navigate('/settings/agreements/statuses')}
          />
        )}
      />
    </Routes>
  );
}

Agreementcategories.propTypes = {
  agreements: PropTypes.arrayOf(PropTypes.shape({})),
  agreementcategories: PropTypes.arrayOf(PropTypes.shape({})),
  onSetAgreementcategories: PropTypes.func.isRequired,
};

Agreementcategories.defaultProps = {
  agreements: undefined,
  agreementcategories: undefined,
};

import { dateValueFormatter } from '../../../../util/formatter';

export default function LogsColumnDefinition(local) {
  return [{
    type: 'string',
    field: 'district',
    headerName: local.district,
    minWidth: 70,
    flex: 1,
  }, {
    type: 'string',
    field: 'city',
    headerName: local.city,
    minWidth: 80,
    flex: 1,
  }, {
    type: 'string',
    field: 'service',
    headerName: local.service,
    minWidth: 80,
    flex: 1,
  }, {
    type: 'string',
    field: 'level',
    headerName: local.level,
    minWidth: 50,
    flex: 1,
  }, {
    type: 'date',
    field: 'createdAt',
    headerName: local.createdAt,
    valueFormatter: dateValueFormatter,
    minWidth: 50,
    flex: 1,
  }, {
    type: 'string',
    field: 'message',
    headerName: local.message,
    minWidth: 400,
    flex: 5,
  }];
}

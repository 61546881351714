import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';

import { LocalizationContext } from '../../../../AppContext';

import localization from './Elections.local';

export default function Elections() {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Paper sx={{ height: '100%', p: 5 }}>
      {local.inProgress}
    </Paper>
  );
}

export default function ElectiontypesTableRowsMapper(local, elections, electiontypes) {
  let rows;
  if (elections && electiontypes) {
    rows = electiontypes.map((row) => ({
      id: row.id,
      name: row.name,
      key: row.key,
      description: row.description,
      electionresults: elections.filter((item) => item.type === row.id).length,
      updatedAt: row.updatedAt,
      editor: row.editor,
    }));
  }
  return rows;
}

import { dateValueFormatter } from '../../../../../util/formatter';

export default function PresidentsTableColumnDefinition(local) {
  return [{
    type: 'string',
    field: 'name',
    headerName: local.name,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'party',
    headerName: local.party,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'color',
    headerName: local.color,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'date',
    field: 'updatedAt',
    headerName: local.updatedAt,
    valueFormatter: dateValueFormatter,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'editor',
    headerName: local.editor,
    minWidth: 150,
    flex: 1,
  }];
}

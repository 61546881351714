export default {
  de: {
    add: 'Bericht hinzufügen',
    archive: 'Archiv',
    district: 'Bezirk',
    city: 'Gemeinde',
    status: 'Status',
    definition: 'Bericht',
    exportReport: 'Berichte Exportieren',
    date: 'erstellt am',
    creator: 'erstellt von',
    updatedAt: 'zuletzt bearbeitet am',
    editor: 'zuletzt bearbeitet von',
  },
};

export default function AgreementstatusesTableRowsMapper(local, agreements, agreementstatuses) {
  let rows;
  if (agreements && agreementstatuses) {
    rows = agreementstatuses.map((row) => {
      let activeAgreements = 0;
      let inactiveAgreements = 0;
      agreements.forEach((item) => {
        if (item.category === row.id) {
          if (item.active) {
            activeAgreements += 1;
          } else {
            inactiveAgreements += 1;
          }
        }
      });
      return {
        id: row.id,
        name: row.name,
        key: row.key,
        color: row.color,
        description: row.description,
        activeAgreements,
        inactiveAgreements,
        updatedAt: row.updatedAt,
        editor: row.editor,
      };
    });
  }
  return rows;
}

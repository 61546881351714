import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import localization from './AgreementsPost.local';

export default function AgreementsPost({
  agreementcategories,
  agreements,
  city,
  onBack,
  onSetAgreements,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [activeAgreementcategories, setActiveAgreementcategories] = useState(null);
  const [category, setCategory] = useState(null);
  const [until, setUntil] = useState(moment().format('YYYY-MM-DD'));
  const [description, setDescription] = useState('');
  const [alert, setAlert] = useState();

  useEffect(() => setActiveAgreementcategories(agreementcategories ? agreementcategories.filter((item) => item.active) : null), [agreementcategories]);
  useEffect(() => {
    if (activeAgreementcategories && !category) {
      setCategory(activeAgreementcategories[0]);
    }
  }, [activeAgreementcategories, category]);

  const acknowledge = ({ error, payload }) => {
    if (error) {
      setAlert(error);
    } else {
      onSetAgreements(agreements ? [...agreements, payload] : [payload]);
      onBack();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const agreement = {
      city: city.id,
      district: city.district,
      category: category.id,
      until,
      description,
    };
    socket.emit('agreements.post', agreement, acknowledge);
  };

  return (
    <Paper component="form" onSubmit={onSubmit} sx={{ height: '100%', p: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h4">{local.title}</Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sx={{ p: 2 }}>
          <Typography>{local.smart}</Typography>
          <Typography>{local.s}</Typography>
          <Typography>{local.m}</Typography>
          <Typography>{local.a}</Typography>
          <Typography>{local.r}</Typography>
          <Typography>{local.t}</Typography>
        </Grid>
        <Grid container item xs={12} md={8} spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              required
              disableClearable
              value={category}
              options={agreementcategories ? agreementcategories.filter((item) => item.active).sort((a, b) => b - a) : []}
              onChange={(e, selected) => setCategory(selected)}
              getOptionLabel={((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  required
                  fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.category}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="date"
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.until}
              value={until}
              onChange={(e) => setUntil(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              multiline
              variant="outlined"
              color="secondary"
              margin="dense"
              label={local.description}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
        <Fab
          variant="extended"
          color="primary"
          type="submit"
          size="small"
          sx={{ justifyContent: 'flex-start' }}
        >
          <SaveIcon sx={{ mr: 1 }} />
          <Typography>{local.submit}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

AgreementsPost.propTypes = {
  agreementcategories: PropTypes.arrayOf(PropTypes.shape({})),
  agreements: PropTypes.arrayOf(PropTypes.shape({})),
  agreementstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
    district: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSetAgreements: PropTypes.func.isRequired,
};

AgreementsPost.defaultProps = {
  agreementcategories: undefined,
  agreements: undefined,
  agreementstatuses: undefined,
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
};

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext } from '../../../../../AppContext';
import Toolbar from './ServicecategoriesTableToolbar';
import ColumnDefinition from './ServicecategoriesTableColumnDefinition';
import RowsMapper from './ServicecategoriesTableRowsMapper';
import localization from './ServicecategoriesTable.local';

export default function ServicecategoriesTable({
  servicecategories,
  onRowClick,
  onAddClick,
  onStatusesClick,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();

  useEffect(() => setColumns(ColumnDefinition(local)), []);
  useEffect(() => setRows(RowsMapper(local, servicecategories)), [servicecategories]);

  return (
    <Paper sx={{ height: '100%' }}>
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar }}
          componentsProps={{ toolbar: { local, onAddClick, onStatusesClick } }}
        />
      )}
    </Paper>
  );
}

ServicecategoriesTable.propTypes = {
  servicecategories: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onStatusesClick: PropTypes.func.isRequired,
};

ServicecategoriesTable.defaultProps = {
  servicecategories: undefined,
};

export default {
  de: {
    electionresult: 'Wahlergebnis',
    absolute: 'Absolute Zahlen',
    district: 'Bezirk',
    city: 'Gemeinde',
    type: 'Typ',
    date: 'Datum',
    eligible: 'Wahlberechtigte',
    votes: 'Stimmen',
    valid: 'Gültig',
    invalid: 'Ungültig',
    back: 'Zurück',
    party: 'Partei',
    alerts: {
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};

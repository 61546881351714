import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';

const types = {
  base64: 'base64',
  arraybuffer: 'arraybuffer',
};

export default function ImageUploader({
  id, label, type, shape, file, aspect, showGrid, onError, onReturn,
}) {
  const [src, setSrc] = useState();
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    const image = new Image();
    image.src = src;

    // future Settings
    const rotation = 0;
    const rotRad = (rotation * Math.PI);
    const flip = { horizontal: false, vertical: false };

    // set canvas size to match the bounding box
    const canvas = document.createElement('canvas');
    const bBoxWidth = Math.abs(Math.cos(rotRad) * image.width) + Math.abs(Math.sin(rotRad) * image.height);
    const bBoxHeight = Math.abs(Math.sin(rotRad) * image.width) + Math.abs(Math.cos(rotRad) * image.height);
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // check ctx
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      onError('CTX null');
    } else {
      // translate canvas context to a central location to allow rotating and flipping around the center
      ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
      ctx.rotate(rotRad);
      ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
      ctx.translate(-image.width / 2, -image.height / 2);

      // draw rotated image
      ctx.drawImage(image, 0, 0);

      // croppedAreaPixels values are bounding box relative
      // extract the cropped image using these values
      const data = ctx.getImageData(
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
      );

      // set canvas width to final desired crop size - this will clear existing context
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;

      // paste generated rotate image at the top left corner
      ctx.putImageData(data, 0, 0);

      if (type === types.base64) {
        onReturn(canvas.toDataURL(file));
      } else if (type === types.arraybuffer) {
        canvas.toBlob(async (blob) => {
          const arraybuffer = await blob.arrayBuffer();
          onReturn(arraybuffer);
        }, file);
      }
    }
  };

  const onImageUpload = (e) => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onload = () => {
        setSrc(fileReader.result);
      };
      fileReader.onerror = (error) => onError('Error: ', error);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box position="relative" sx={{ height: 330, mt: 1, backgroundColor: 'background.default' }}>
          <Cropper
            image={src}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            cropShape={shape}
            showGrid={showGrid}
            onCropComplete={onCropComplete}
            onCropChange={(newCrop) => setCrop(newCrop)}
            onZoomChange={(newZoom) => setZoom(newZoom)}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.05}
          disabled={!src}
          onChange={(e, newZoom) => setZoom(newZoom)}
        />
      </Grid>
      <Grid item xs={12}>
        <label htmlFor={id}>
          <Input id={id} type="file" accept="image/*" onChange={onImageUpload} sx={{ display: 'none' }} />
          <Button fullWidth variant="contained" component="span" sx={{ mb: 2, mt: 1 }}>
            {label}
          </Button>
        </label>
      </Grid>
    </Grid>
  );
}

ImageUploader.propTypes = {
  id: PropTypes.string,
  file: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf([types.base64, types.arraybuffer]),
  shape: PropTypes.oneOf(['round', 'rect']),
  showGrid: PropTypes.bool,
  aspect: PropTypes.number,
  onError: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
};

ImageUploader.defaultProps = {
  id: 'contained-button-file',
  file: 'image/jpeg',
  type: types.arraybuffer,
  showGrid: true,
  shape: 'rect',
  aspect: 1,
};

export default {
  de: {
    district: 'Bezirk',
    city: 'Gemeinde',
    service: 'Service',
    level: 'Level',
    createdAt: 'Datum',
    message: 'Nachricht',
  },
};

export default {
  de: {
    gkz: 'GKZ',
    city: 'Gemeinde',
    district: 'Bezirk',
    type: 'Funktion',
    name: 'Name',
    party: 'Partei',
    birthdate: 'Geburtsdatum',
    email: 'Email',
    phone: 'Telefon',
    mail: 'Post',
    since: 'Im Amt Seit',
  },
};

import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';

import { LocalizationContext } from '../../../../AppContext';

import localization from './Swing.local';

export default function Swing() {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Paper>
      {local.inProgress}
    </Paper>
  );
}

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext } from '../../../../../AppContext';
import Toolbar from './PartiesTableToolbar';
import ColumnDefinition from './PartiesTableColumnDefinition';
import RowsMapper from './PartiesTableRowsMapper';
import localization from './PartiesTable.local';

export default function PartiesTable({
  parties,
  onRowClick,
  onAddClick,
  onTypesClick,
  onPresidentsClick,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();

  useEffect(() => setColumns(ColumnDefinition(local)), []);
  useEffect(() => setRows(RowsMapper(local, parties)), [parties]);

  return (
    <Paper sx={{ height: '100%' }}>
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar }}
          componentsProps={{
            toolbar: {
              local,
              onAddClick,
              onTypesClick,
              onPresidentsClick,
            },
          }}
        />
      )}
    </Paper>
  );
}

PartiesTable.propTypes = {
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onTypesClick: PropTypes.func.isRequired,
  onPresidentsClick: PropTypes.func.isRequired,
};

PartiesTable.defaultProps = {
  parties: undefined,
};

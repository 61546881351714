import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext } from '../../../../../AppContext';
import Toolbar from './OfficialcategoriesTableToolbar';
import ColumnDefinition from './OfficialcategoriesTableColumnDefinition';
import RowsMapper from './OfficialcategoriesTableRowsMapper';
import localization from './OfficialcategoriesTable.local';

export default function OfficialcategoriesTable({
  officialcategories,
  // onRowClick,
  onAddClick,
  onTypesClick,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();

  useEffect(() => setColumns(ColumnDefinition(local)), []);
  useEffect(() => setRows(RowsMapper(local, officialcategories)), [officialcategories]);

  return (
    <Paper sx={{ height: '100%' }}>
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          // onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar }}
          componentsProps={{ toolbar: { local, onAddClick, onTypesClick } }}
        />
      )}
    </Paper>
  );
}

OfficialcategoriesTable.propTypes = {
  officialcategories: PropTypes.arrayOf(PropTypes.shape({})),
  // onRowClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onTypesClick: PropTypes.func.isRequired,
};

OfficialcategoriesTable.defaultProps = {
  officialcategories: undefined,
};

export default function OfficialsRowsMapper(
  local,
  archive,
  cities,
  city,
  district,
  districts,
  reportdefinitions,
  reports,
  reportstatuses,
  users,
) {
  let rows;
  if (cities && districts && reportdefinitions && reports && reportstatuses && users) {
    rows = reports
      .filter((row) => {
        const isDistrict = !district || district.id === row.district;
        const isCity = !city || city.id === row.city;
        const isActive = row.active !== archive;
        // const rowReportDefinition = reportdefinitions.find((item) => item.id === reports.definition);
        // && rowReportDefinition.active
        return isActive && isDistrict && isCity;
      })
      .map((row) => {
        const rowDistrict = district || districts.find((item) => item.id === row.district);
        const rowCity = city || cities.find((item) => item.id === row.city);
        const rowDefinition = reportdefinitions.find((item) => item.id === row.definition);
        const rowStatus = reportstatuses.find((item) => item.id === row.status);
        const rowCreator = users.find((item) => item.id === row.creator);
        const rowEditor = users.find((item) => item.id === row.editor);
        return {
          id: row.id,
          district: rowDistrict ? rowDistrict.name : '',
          city: rowCity ? rowCity.name : '',
          status: rowStatus ? rowStatus.name : '',
          definition: rowDefinition ? rowDefinition.name : '',
          date: row.date,
          creator: rowCreator,
          editor: rowEditor,
          updatedAt: row.updatedAt,
        };
      });
  }
  return rows;
}

import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { uuid } from 'uuidv4';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { LocalizationContext, SocketContext } from '../../../../../AppContext';
import Confirm from '../../../../../components/Confirm';
import Snackbar from '../../../../../components/Snackbar';
import ReportdefinitionsPatchView from './ReportdefinitionsPatchView';
import localization from './ReportdefinitionsPatch.local';

export default function ReportdefinitionsPatch({
  citysettingdefinitions,
  reportdefinitions,
  onBack,
  onSetReportdefinitions,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const { reportdefinitionid } = useParams();
  const [reportdefinition, setReportdefinition] = useState();
  const [name, setName] = useState('');
  const [document, setDocument] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [expanded, setExpanded] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (reportdefinitions && reportdefinitionid) {
      const foundReportdefinition = reportdefinitions.find((item) => item.id === parseInt(reportdefinitionid, 10));
      if (foundReportdefinition) {
        setReportdefinition(foundReportdefinition);
      } else {
        onBack();
      }
    }
  }, [reportdefinitions, reportdefinitionid]);

  useEffect(() => {
    if (reportdefinition) {
      setName(reportdefinition.name);
      setDocument(reportdefinition.document);
    }
  }, [reportdefinition]);

  const acknowledgeChange = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetReportdefinitions(reportdefinitions ? reportdefinitions.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
    }
  };

  const onChange = (field, value) => {
    const params = {
      id: reportdefinition.id,
      field,
      value,
    };
    socket.emit('reportdefinitions.patch', params, acknowledgeChange);
  };

  const onTextChange = (setter, field, value) => {
    setter(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(field, value), 1000));
  };

  const acknowledgeDestroy = ({ error, payload }) => {
    if (error) {
      setAlert(error);
    } else {
      onSetReportdefinitions(reportdefinitions ? reportdefinitions.filter((item) => (item.id !== payload.id)) : []);
      onBack();
    }
  };

  const onDestroy = () => {
    const params = { id: reportdefinition.id };
    socket.emit('reportdefinitions.destroy', params, acknowledgeDestroy);
  };

  return (
    <Paper sx={{ height: '100%' }}>
      { !document ? (
        <Skeleton variant="rect" sx={{ height: '100%', width: '100%' }} />
      ) : (
        <Box sx={{ height: '100%', width: '100%', p: 5 }}>
          <Confirm
            title={local.destroyTitle}
            description={local.destroyDescription}
            agree={local.agree}
            disagree={local.disagree}
            open={openConfirm}
            onClose={() => setOpenConfirm(false)}
            onConfirm={onDestroy}
          />
          <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
          <Typography variant="h4">{local.title}</Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <TextField
            fullWidth
            variant="outlined"
            color="secondary"
            margin="dense"
            label={local.name}
            value={name}
            onChange={(e) => onTextChange(setName, 'name', e.target.value)}
          />
          { document.map((view) => (
            <ReportdefinitionsPatchView
              key={view.key}
              citysettingdefinitions={citysettingdefinitions}
              expanded={expanded}
              view={view}
              onChange={(item) => onChange('document', document.map((entry) => (entry.key === item.key ? item : entry)))}
              onDestroy={(item) => onChange('document', document.filter((entry) => entry.key !== item.key))}
              onSetExpanded={setExpanded}
            />
          ))}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 1, mb: 1 }}
            onClick={() => onChange('document', [...document, { name: local.new, key: uuid() }])}
          >
            <AddIcon />
            <Typography sx={{ ml: 1 }}>{local.add}</Typography>
          </Button>
        </Box>
      )}
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        { reportdefinition && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => setOpenConfirm(true)}
            sx={{ justifyContent: 'flex-start' }}
          >
            <DeleteIcon sx={{ mr: 1 }} />
            <Typography>{local.destroy}</Typography>
          </Fab>
        )}
        { reportdefinition && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => onChange('active', !reportdefinition.active)}
            sx={{ justifyContent: 'flex-start' }}
          >
            { reportdefinition.active ? <LockIcon /> : <LockOpenIcon /> }
            <Typography sx={{ ml: 1 }}>{reportdefinition.active ? local.lock : local.unlock}</Typography>
          </Fab>
        )}
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

ReportdefinitionsPatch.propTypes = {
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  reportdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSetReportdefinitions: PropTypes.func.isRequired,
};

ReportdefinitionsPatch.defaultProps = {
  citysettingdefinitions: undefined,
  reportdefinitions: undefined,
};

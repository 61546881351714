export default function ReportdefinitionsTableRowsMapper(local, reportdefinitions) {
  let rows;
  if (reportdefinitions) {
    rows = reportdefinitions.map((row) => ({
      id: row.id,
      name: row.name,
    }));
  }
  return rows;
}

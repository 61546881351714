import { dateValueFormatter } from '../../../../util/formatter';

export default function ServicesTableColumnDefinition(local) {
  const colDef = [{
    type: 'string',
    field: 'name',
    headerName: local.name,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'description',
    headerName: local.description,
    minWidth: 250,
    flex: 1,
  }, {
    type: 'date',
    field: 'date',
    headerName: local.date,
    valueFormatter: dateValueFormatter,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'creator',
    headerName: local.creator,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'date',
    field: 'createdAt',
    headerName: local.createdAt,
    valueFormatter: dateValueFormatter,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'editor',
    headerName: local.editor,
    minWidth: 250,
    flex: 2,
  }, {
    type: 'date',
    field: 'updatedAt',
    headerName: local.updatedAt,
    valueFormatter: dateValueFormatter,
    minWidth: 150,
    flex: 1,
  }];
  return colDef;
}

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Servicecategories from './servicecategories/Servicecategories';
import Servicestatuses from './servicestatuses/Servicestatuses';

export default function Services({
  servicescategories,
  services,
  servicesstatuses,
  onSetServicecategories,
  onSetServicestatuses,
}) {
  return (
    <Routes>
      <Route
        path="categories/*"
        element={(
          <Servicecategories
            servicescategories={servicescategories}
            services={services}
            onSetServicecategories={onSetServicecategories}
          />
        )}
      />
      <Route
        path="statuses/*"
        element={(
          <Servicestatuses
            services={services}
            servicesstatuses={servicesstatuses}
            onSetServicestatuses={onSetServicestatuses}
          />
        )}
      />
      <Route path="*" element={<Navigate to="categories" />} />
    </Routes>
  );
}

Services.propTypes = {
  servicescategories: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  servicesstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  onSetServicecategories: PropTypes.func.isRequired,
  onSetServicestatuses: PropTypes.func.isRequired,
};

Services.defaultProps = {
  servicescategories: undefined,
  services: undefined,
  servicesstatuses: undefined,
};

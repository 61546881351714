export default {
  de: {
    agreements: 'Ziel',
    citysettings: 'Gemeinde Einstellung',
    messages: 'Notiz',
    reports: 'Bericht',
    services: 'Leistung',
    files: 'Datei',
    patch: 'bearbeitet',
    post: 'erstellt',
    destroy: 'gelöscht',
    0: 'Nein',
    1: 'Ja',
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

export default function PresidentsTableToolbar({
  local,
  onAddClick,
  onTypesClick,
  onPartiesClick,
}) {
  return (
    <GridToolbarContainer>
      <Button variant="text" size="small" color="primary" onClick={onTypesClick}>{local.types}</Button>
      <Button variant="text" size="small" color="primary" onClick={onPartiesClick}>{local.parties}</Button>
      <Button variant="text" size="small" color="secondary">{local.presidents}</Button>
      <Button variant="text" size="small" color="primary" onClick={onAddClick}>
        <AddIcon />
        {local.add}
      </Button>
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{ delimiter: ';', utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}

PresidentsTableToolbar.propTypes = {
  local: PropTypes.shape({
    add: PropTypes.string,
    types: PropTypes.string,
    parties: PropTypes.string,
    presidents: PropTypes.string,
  }).isRequired,
  onAddClick: PropTypes.func.isRequired,
  onTypesClick: PropTypes.func.isRequired,
  onPartiesClick: PropTypes.func.isRequired,
};

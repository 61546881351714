import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import InfoCity from './InfoCity';
import infoCityMapping from './infoCityMapping';
import InfoSummary from './InfoSummary';
import infoSummaryMapping from './infoSummaryMapping';

export default function Info({
  agreements,
  agreementstatuses,
  cities,
  city,
  citysettingdefinitions,
  citysettings,
  district,
  elections,
  officials,
  parties,
  reportdefinitions,
  reports,
  reportstatuses,
  users,
  onSetCitysettings,
  onSetElections,
}) {
  const [citiesInfo, setCitiesInfo] = useState();
  const [group, setGroup] = useState(true);

  useEffect(() => {
    if (agreements && agreementstatuses && cities && citysettingdefinitions && elections && officials && parties && reportdefinitions && reports && reportstatuses) {
      setCitiesInfo(city && district
        ? [infoCityMapping(
          agreements,
          agreementstatuses,
          city,
          district,
          elections,
          group,
          officials,
          parties,
          reportdefinitions,
          reports,
          reportstatuses,
        )]
        : infoSummaryMapping(
          agreements,
          agreementstatuses,
          cities,
          citysettingdefinitions,
          citysettings,
          district,
          elections,
          group,
          officials,
          parties,
          reportdefinitions,
          reports,
          reportstatuses,
        ));
    }
  }, [
    agreements,
    agreementstatuses,
    cities,
    city,
    citysettings,
    citysettingdefinitions,
    district, elections,
    group,
    officials,
    parties,
    reportdefinitions,
    reports,
    reportstatuses,
  ]);

  return citiesInfo && citiesInfo.length === 1 ? (
    <InfoCity
      cityInfo={citiesInfo[0]}
      citysettingdefinitions={citysettingdefinitions}
      citysettings={citysettings}
      district={district}
      elections={elections}
      group={group}
      users={users}
      onSetCitysettings={onSetCitysettings}
      onSetGroup={setGroup}
      onSetElections={onSetElections}
    />
  ) : (
    <InfoSummary
      citiesInfo={citiesInfo}
      group={group}
      onSetGroup={setGroup}
    />
  );
}

Info.propTypes = {
  agreements: PropTypes.arrayOf(PropTypes.shape({})),
  agreementstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  citysettings: PropTypes.arrayOf(PropTypes.shape({})),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  officials: PropTypes.arrayOf(PropTypes.shape({})),
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  reportdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  reports: PropTypes.arrayOf(PropTypes.shape({})),
  reportstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onSetCitysettings: PropTypes.func.isRequired,
  onSetElections: PropTypes.func.isRequired,
};

Info.defaultProps = {
  agreements: undefined,
  agreementstatuses: undefined,
  cities: undefined,
  city: undefined,
  citysettingdefinitions: undefined,
  citysettings: undefined,
  district: undefined,
  elections: undefined,
  officials: undefined,
  parties: undefined,
  reportdefinitions: undefined,
  reports: undefined,
  reportstatuses: undefined,
  users: undefined,
};

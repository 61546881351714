export default function AgreementsTableRowsMapper(
  local,
  archive,
  agreementcategories,
  agreements,
  agreementstatuses,
  cities,
  city,
  district,
  districts,
  users,
) {
  let rows;
  if (agreementcategories && agreements && agreementstatuses && cities && districts && users) {
    rows = agreements
      .filter((agreement) => {
        const isDistrict = !district || district.id === agreement.district;
        const isCity = !city || city.id === agreement.city;
        const isActive = archive !== agreement.active;
        const agreementCategory = agreementcategories.find((item) => item.id === agreement.category);
        return isActive && isDistrict && isCity && agreementCategory.active;
      })
      .map((agreement) => {
        const itemDistrict = district || districts.find((item) => item.id === agreement.district);
        const itemCity = city || cities.find((item) => item.id === agreement.city);
        const itemCategory = agreementcategories.find((item) => item.id === agreement.category);
        const itemStatus = agreementstatuses.find((item) => item.id === agreement.status);
        const itemCreator = users.find((item) => item.id === agreement.creator);
        const itemEditor = users.find((item) => item.id === agreement.editor);
        return {
          id: agreement.id,
          district: itemDistrict ? itemDistrict.name : '',
          city: itemCity ? itemCity.name : '',
          until: agreement.until,
          category: itemCategory ? itemCategory.name : '',
          status: itemStatus ? itemStatus.name : '',
          creator: itemCreator,
          createdAt: agreement.createdAt,
          editor: itemEditor,
          updatedAt: agreement.updatedAt,
          description: agreement.description,
        };
      });
  }
  return rows;
}

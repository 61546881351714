import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { LocalizationContext, UserContext } from '../../../AppContext';
import localization from './GemoNavigator.local';

export default function GemoNavigator({ cities, districts }) {
  const local = localization[useContext(LocalizationContext)];
  const user = useContext(UserContext);
  const [districtFilter, setDistrictFilter] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/');
  path.shift();
  const districtid = path.shift();
  const cityid = path.shift();
  const menu = path.shift();

  return (
    <List component="div" disablePadding sx={{ width: 249, overflowY: 'auto' }}>
      { user.control && <Typography sx={{ pl: 2, pt: 2 }}>{local.control}</Typography>}
      { districts && user.steering.length > 0 && (
        <div>
          <Typography sx={{ pl: 2, pt: 2 }}>{local.steering}</Typography>
          { user.steering.map((steer) => {
            const district = districts.find((item) => item.id === steer);
            return district ? (
              <ListItem
                button
                dense
                key={`${user.id}-${district.id}`}
                onClick={() => navigate(`/${district.id}/-/${menu}`)}
                sx={parseInt(districtid, 10) === district.id
                  ? {
                    pl: 2, borderLeft: '6px solid', borderLeftColor: 'primary.dark', backgroundColor: 'action.hover',
                  }
                  : { pl: 2 }}
              >
                <ListItemText
                  primary={district.name}
                  primaryTypographyProps={{ sx: parseInt(districtid, 10) === district.id ? { fontWeight: 700, color: 'primary.light' } : {} }}
                />
              </ListItem>
            ) : (<div />);
          })}
          <Divider sx={{ pt: 1, pb: 1 }} />
        </div>
      )}
      { cities && user.coaching.length > 0 && (
        <div>
          <Typography sx={{ pl: 2, pt: 2 }}>{local.coaching}</Typography>
          { user.coaching.map((coach) => {
            const city = cities.find((item) => item.id === coach);
            return city ? (
              <ListItem
                button
                dense
                key={`${user.id}-${city.id}`}
                onClick={() => navigate(`/${city.district}/${city.id}/${menu}`)}
                sx={parseInt(cityid, 10) === city.id
                  ? {
                    pl: 2, borderLeft: '6px solid', borderLeftColor: 'primary.dark', backgroundColor: 'action.hover',
                  }
                  : { pl: 2 }}
              >
                <ListItemText
                  primary={city.name}
                  primaryTypographyProps={{ sx: parseInt(cityid, 10) === city.id ? { fontWeight: 700, color: 'primary.light' } : {} }}
                />
              </ListItem>
            ) : (<div />);
          })}
          <Divider sx={{ pt: 1, pb: 1 }} />
        </div>
      )}
      { cities && user.watching.length > 0 && (
        <div>
          <Typography sx={{ pl: 2, pt: 2 }}>{local.watching}</Typography>
          { user.watching.map((watch) => {
            const city = cities.find((item) => item.id === watch);
            return city ? (
              <ListItem
                button
                dense
                key={`${user.id}-${city.id}`}
                onClick={() => navigate(`/${city.district}/${city.id}/${menu}`)}
                sx={parseInt(cityid, 10) === city.id
                  ? {
                    pl: 2, borderLeft: '6px solid', borderLeftColor: 'primary.dark', backgroundColor: 'action.hover',
                  }
                  : { pl: 2 }}
              >
                <ListItemText
                  primary={city.name}
                  primaryTypographyProps={{ sx: parseInt(cityid, 10) === city.id ? { fontWeight: 700, color: 'primary.light' } : {} }}
                />
              </ListItem>
            ) : (<div />);
          })}
          <Divider sx={{ pt: 1, pb: 1 }} />
        </div>
      )}
      <Typography />
      <Typography sx={{ pl: 2, pt: 2 }}>{local.list}</Typography>
      <TextField
        fullWidth
        color="secondary"
        margin="dense"
        name="districtFilter"
        label={local.districtFilter}
        value={districtFilter}
        onChange={(e) => setDistrictFilter(e.target.value)}
        sx={{ pl: 1, pr: 1 }}
      />
      <TextField
        fullWidth
        color="secondary"
        margin="dense"
        name="cityFilter"
        label={local.cityFilter}
        value={cityFilter}
        onChange={(e) => setCityFilter(e.target.value)}
        sx={{ pl: 1, pr: 1, mb: 2 }}
      />
      {cities && districts && districts
        .filter((item) => {
          const districtCities = cities.find((city) => city.district === item.id && city.name.toUpperCase().includes(cityFilter.toUpperCase()));
          return (districtCities && item.name.toUpperCase().includes(districtFilter.toUpperCase()));
        })
        .sort((a, b) => a.sort - b.sort)
        .map((district) => (
          <div key={district.id}>
            <ListItem
              button
              dense
              onClick={() => navigate(cityid !== '-'
                ? `/${district.id}/-/${menu}`
                : `/${parseInt(districtid, 10) === district.id ? '-' : district.id}/-/${menu}`)}
              sx={parseInt(districtid, 10) === district.id
                ? {
                  pl: 2, borderLeft: '6px solid', borderLeftColor: 'primary.dark', backgroundColor: 'action.hover',
                }
                : { pl: 2 }}
            >
              <ListItemText
                primary={district.name}
                primaryTypographyProps={{ sx: parseInt(districtid, 10) === district.id ? { fontWeight: 700, color: 'primary.light' } : {} }}
              />
            </ListItem>
            { parseInt(districtid, 10) === district.id && cities && cities
              .filter((item) => item.district === district.id && item.name.toUpperCase().includes(cityFilter.toUpperCase()))
              .map((city) => (
                <ListItem
                  button
                  dense
                  key={city.id}
                  onClick={() => navigate(`/${district.id}/${parseInt(cityid, 10) === city.id ? '-' : city.id}/${menu}`)}
                  sx={{
                    pl: 4,
                    boxSizing: 'border-box',
                    borderLeft: '6px solid',
                    borderLeftColor: 'primary.dark',
                    backgroundColor: parseInt(cityid, 10) === city.id ? 'action.selected' : 'action.hover',
                    '&:hover': {
                      backgroundColor: 'action.selected',
                    },
                  }}
                >
                  <ListItemText
                    primary={city.name}
                    primaryTypographyProps={{ sx: parseInt(cityid, 10) === city.id ? { fontWeight: 700, color: 'primary.light' } : {} }}
                  />
                </ListItem>
              ))}
          </div>
        ))}
    </List>
  );
}

GemoNavigator.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
};

GemoNavigator.defaultProps = {
  cities: undefined,
  districts: undefined,
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { LocalizationContext } from '../../../../AppContext';
import { LabelOfficial } from '../../../../components/Label';

import localization from './InfoCityCouncil.local';

export default function InfoCityCouncil({
  alternates,
  cashiers,
  cityCouncils,
  councils,
  councilsBoards,
  fractions,
  mayor,
  vicemayors,
}) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Paper sx={{ width: '100%', p: 1, pb: 2 }}>
      <Typography variant="h6">{local.title}</Typography>
      { mayor && (
      <LabelOfficial
        label={mayor.peoplesmayor && process.env.REACT_APP_FLAG_PEOPLESMAYOR === '1' ? local.peoplesmayor : local.mayor}
        name={`${mayor.firstname} ${mayor.lastname}`}
        birthdate={mayor.birthdate}
        color={mayor.party.color}
        party={mayor.party.name}
      />
      )}
      { vicemayors?.length === 1 && (
        <LabelOfficial
          label={local.vicemayor}
          name={`${vicemayors[0].firstname} ${vicemayors[0].lastname}`}
          birthdate={vicemayors[0].birthdate}
          color={vicemayors[0].party.color}
          party={vicemayors[0].party.name}
        />
      )}
      { vicemayors?.length > 1 && vicemayors.filter((item) => item.party.name !== mayor.party.name).map((item) => (
        <LabelOfficial
          key={item.id}
          label={local.vicemayor1}
          name={`${item.firstname} ${item.lastname}`}
          birthdate={item.birthdate}
          color={item.party.color}
          party={item.party.name}
        />
      ))}
      { vicemayors?.length > 1 && vicemayors.filter((item) => item.party.name === mayor.party.name).map((item) => (
        <LabelOfficial
          key={item.id}
          label={local.vicemayor2}
          name={`${item.firstname} ${item.lastname}`}
          birthdate={item.birthdate}
          color={item.party.color}
          party={item.party.name}
        />
      ))}
      { cashiers && cashiers.length > 0 && cashiers.map((item, index) => (
        <LabelOfficial
          key={item.id}
          label={index === 0 ? local.cashier : undefined}
          name={`${item.firstname} ${item.lastname}`}
          birthdate={item.birthdate}
          color={item.party.color}
          party={item.party.name}
        />
      ))}
      { process.env.REACT_APP_FLAG_FRACTION === '1' && fractions && fractions.length > 0 && fractions.map((item, index) => (
        <LabelOfficial
          key={item.id}
          label={index === 0 ? local.fraction : undefined}
          name={`${item.firstname} ${item.lastname}`}
          birthdate={item.birthdate}
          color={item.party.color}
          party={item.party.name}
        />
      ))}
      { cityCouncils && cityCouncils.length > 0 && cityCouncils.map((item, index) => (
        <LabelOfficial
          key={item.id}
          label={index === 0 ? local.cityCouncil : undefined}
          name={`${item.firstname} ${item.lastname}`}
          birthdate={item.birthdate}
          color={item.party.color}
          party={item.party.name}
        />
      ))}
      { councilsBoards && councilsBoards.length > 0 && councilsBoards.map((item, index) => {
        const label = process.env.REACT_APP_FLAG_EXECUTIVE_COUNCIL === '1' ? local.executiveCouncil : local.councilBoard;
        return (
          <LabelOfficial
            key={item.id}
            label={index === 0 ? label : undefined}
            name={`${item.firstname} ${item.lastname}`}
            birthdate={item.birthdate}
            color={item.party.color}
            party={item.party.name}
          />
        );
      })}
      { councils && councils.length > 0 && councils.map((item, index) => (
        <LabelOfficial
          key={item.id}
          label={index === 0 ? local.council : undefined}
          name={`${item.firstname} ${item.lastname}`}
          birthdate={item.birthdate}
          color={item.party.color}
          party={item.party.name}
        />
      ))}
      { process.env.REACT_APP_FLAG_ALTERNATE === '1' && alternates && alternates.length > 0 && alternates.map((item, index) => (
        <LabelOfficial
          key={item.id}
          label={index === 0 ? local.alternate : undefined}
          name={`${item.firstname} ${item.lastname}`}
          birthdate={item.birthdate}
          color={item.party.color}
          party={item.party.name}
        />
      ))}
    </Paper>
  );
}

InfoCityCouncil.propTypes = {
  alternates: PropTypes.arrayOf(PropTypes.shape({})),
  cashiers: PropTypes.arrayOf(PropTypes.shape({})),
  cityCouncils: PropTypes.arrayOf(PropTypes.shape({})),
  councils: PropTypes.arrayOf(PropTypes.shape({})),
  councilsBoards: PropTypes.arrayOf(PropTypes.shape({})),
  fractions: PropTypes.arrayOf(PropTypes.shape({})),
  mayor: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    birthdate: PropTypes.string,
    peoplesmayor: PropTypes.bool,
    party: PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
    }),
  }),
  vicemayors: PropTypes.arrayOf(PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    birthdate: PropTypes.string,
    peoplesmayor: PropTypes.bool,
    party: PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
    }),
  })),
};

InfoCityCouncil.defaultProps = {
  alternates: undefined,
  cashiers: undefined,
  cityCouncils: undefined,
  councils: undefined,
  councilsBoards: undefined,
  fractions: undefined,
  mayor: undefined,
  vicemayors: undefined,
};

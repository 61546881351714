import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { UserContext } from '../../../../AppContext';
import hasWriteAccess from '../../../../util/hasWriteAccess';

export default function AgreementsTableToolbar({
  local, archive, city, onAddClick, onSetArchive,
}) {
  const user = useContext(UserContext);
  return (
    <GridToolbarContainer>
      <Button
        variant="text"
        size="small"
        color={archive ? 'primary' : 'secondary'}
        onClick={() => onSetArchive(!archive)}
      >
        {local.archive}
      </Button>
      { city && !archive && hasWriteAccess(user, city.district, city.id) && (
        <Button
          variant="text"
          size="small"
          color="primary"
          onClick={onAddClick}
        >
          {local.add}
        </Button>
      )}
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{ delimiter: ';', utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}

AgreementsTableToolbar.propTypes = {
  local: PropTypes.shape({
    add: PropTypes.string,
    archive: PropTypes.string,
  }).isRequired,
  archive: PropTypes.bool,
  city: PropTypes.shape({
    id: PropTypes.number,
    district: PropTypes.number,
  }),
  onAddClick: PropTypes.func.isRequired,
  onSetArchive: PropTypes.func.isRequired,
};

AgreementsTableToolbar.defaultProps = {
  archive: false,
  city: undefined,
};

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext } from '../../../../../AppContext';
import Toolbar from './ReportstatusesTableToolbar';
import ColumnDefinition from './ReportstatusesTableColumnDefinition';
import RowsMapper from './ReportstatusesTableRowsMapper';
import localization from './ReportstatusesTable.local';

export default function ReportstatusesTable({
  reportstatuses,
  // onRowClick,
  onAddClick,
  onDefinitionsClick,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();

  useEffect(() => setColumns(ColumnDefinition(local)), []);
  useEffect(() => setRows(RowsMapper(local, reportstatuses)), [reportstatuses]);

  return (
    <Paper sx={{ height: '100%' }}>
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          // onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar }}
          componentsProps={{ toolbar: { local, onAddClick, onDefinitionsClick } }}
        />
      )}
    </Paper>
  );
}

ReportstatusesTable.propTypes = {
  reportstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  // onRowClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onDefinitionsClick: PropTypes.func.isRequired,
};

ReportstatusesTable.defaultProps = {
  reportstatuses: undefined,
};

import React, { useContext, useState } from 'react';
import { Chart } from 'react-google-charts';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import DeleteIcon from '@mui/icons-material/Delete';

import { LocalizationContext, SocketContext, UserContext } from '../../../../AppContext';
import Label, { LabelPie } from '../../../../components/Label';
import Snackbar from '../../../../components/Snackbar';
import hasWriteAccess from '../../../../util/hasWriteAccess';

import localization from './InfoCityMandates.local';

export default function InfoCityMandates({
  absolute,
  cityid,
  councilElection,
  councilParties,
  districtid,
  elections,
  mandates,
  onSetElections,
}) {
  const local = localization[useContext(LocalizationContext)];
  const user = useContext(UserContext);
  const socket = useContext(SocketContext);
  const [edit, setEdit] = useState(false);
  const [alert, setAlert] = useState(false);

  const acknowledgePatch = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetElections(elections.map((item) => (item.id === payload.id ? payload : item)));
    }
  };

  const onCoalitionChange = (party, value) => {
    const params = {
      id: councilElection.id,
      district: districtid,
      city: cityid,
      field: 'parties',
      value: councilElection.parties.map((item) => (item.party === party.id ? { ...item, coalition: value } : item)),
    };
    socket.emit('elections.patch', params, acknowledgePatch);
  };

  return (
    <Paper sx={{ width: '100%', p: 1, pb: 2 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Box display="flex">
        <Typography variant="h6">{local.title}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        { !absolute && hasWriteAccess(user, districtid, cityid) && (
          <Button color="secondary" size="small" variant="text" onClick={() => setEdit(!edit)}>
            {edit ? local.cancel : local.edit}
          </Button>
        )}
      </Box>
      { councilParties ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Chart
              chartType="PieChart"
              height="100%"
              width="300px"
              data={[
                [local.party, local.mandates],
                ['filler', 100],
                ...councilParties
                  .sort((a, b) => b.mandate - a.mandates)
                  .map((item) => ([
                    `${item.name} (${item.mandates})`,
                    Math.round((item.mandates / mandates) * 10000) / 100,
                  ])),
              ]}
              loader={<Skeleton variant="rect" style={{ height: '100%', width: '100%' }} />}
              options={{
                enableInteractivity: false,
                chartArea: { width: '100%', height: '100%' },
                legend: 'none',
                tooltip: { position: 'none' },
                backgroundColor: 'transparent',
                pieSliceText: 'label',
                pieHole: 0.4,
                pieStartAngle: 90,
                slices: [{ color: 'transparent' }, ...councilParties.map((item) => ({ color: item.color || 'grey' }))],
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label label={local.mandatesTotal} value={mandates} />
            { absolute ? (
              <LabelPie label={local.absolute} value={absolute.name} color={absolute.color} />
            ) : (
              <Box>
                <Label label={local.coalition} />
                { councilParties.filter((party) => party.coalition).sort((a, b) => b.mandates - a.mandates).map((party) => (edit ? (
                  <Box key={party.id} display="flex">
                    <Box sx={{ mt: 1, minWidth: '70px' }}>
                      <LabelPie
                        value={party.name}
                        color={party.color}
                      />
                    </Box>
                    <IconButton color="secondary" size="small" onClick={() => onCoalitionChange(party, false)}>
                      <DeleteIcon sx={{ ml: 1 }} />
                    </IconButton>
                  </Box>
                ) : (
                  <LabelPie
                    key={party.id}
                    value={party.name}
                    color={party.color}
                  />
                ))) }
                { edit && (
                  <Autocomplete
                    disableClearable
                    value={null}
                    options={councilParties.filter((party) => !party.coalition) || null}
                    onChange={(e, selected) => onCoalitionChange(selected, true)}
                    getOptionLabel={((option) => option.name)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                      // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        variant="outlined"
                        color="secondary"
                        margin="dense"
                        label={local.coalition}
                      />
                    )}
                  />
                )}
              </Box>
            )}
            { councilParties.sort((a, b) => b.mandates - a.mandates).map((party) => (
              <LabelPie
                key={party.id}
                label={`${party.name} ${local.mandates}`}
                value={party.mandates}
                color={party.color}
              />
            )) }
          </Grid>
        </Grid>
      ) : (
        <Typography>{local.noCouncilParties}</Typography>
      )}
    </Paper>
  );
}

InfoCityMandates.propTypes = {
  absolute: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  }),

  cityid: PropTypes.number.isRequired,
  councilElection: PropTypes.shape({
    id: PropTypes.number,
    parties: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  councilParties: PropTypes.arrayOf(PropTypes.shape({})),
  districtid: PropTypes.number.isRequired,
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  mandates: PropTypes.number.isRequired,
  onSetElections: PropTypes.func.isRequired,
};

InfoCityMandates.defaultProps = {
  absolute: undefined,
  councilElection: undefined,
  councilParties: undefined,
  elections: undefined,
};

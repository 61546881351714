import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Font, Document, Page, View, Text, Image, PDFViewer,
} from '@react-pdf/renderer';
import Box from '@mui/material/Box';

import { LocalizationContext } from '../../../../AppContext';
import localization from './ReportsPatchPdf.local';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: `${process.env.PUBLIC_URL}/fonts/Roboto/Thin.ttf`, fontWeight: 100 },
    { src: `${process.env.PUBLIC_URL}/fonts/Roboto/ThinItalic.ttf`, fontWeight: 100, fontStyle: 'italic' },
    { src: `${process.env.PUBLIC_URL}/fonts/Roboto/Light.ttf`, fontWeight: 300 },
    { src: `${process.env.PUBLIC_URL}/fonts/Roboto/LightItalic.ttf`, fontWeight: 300, fontStyle: 'italic' },
    { src: `${process.env.PUBLIC_URL}/fonts/Roboto/Normal.ttf`, fontWeight: 400 },
    { src: `${process.env.PUBLIC_URL}/fonts/Roboto/NormalItalic.ttf`, fontWeight: 400, fontStyle: 'italic' },
    { src: `${process.env.PUBLIC_URL}/fonts/Roboto/Medium.ttf`, fontWeight: 500 },
    { src: `${process.env.PUBLIC_URL}/fonts/Roboto/MediumItalic.ttf`, fontWeight: 500, fontStyle: 'italic' },
    { src: `${process.env.PUBLIC_URL}/fonts/Roboto/Bold.ttf`, fontWeight: 700 },
    { src: `${process.env.PUBLIC_URL}/fonts/Roboto/BoldItalic.ttf`, fontWeight: 700, fontStyle: 'italic' },
    { src: `${process.env.PUBLIC_URL}/fonts/Roboto/Heavy.ttf`, fontWeight: 900 },
    { src: `${process.env.PUBLIC_URL}/fonts/Roboto/HeavyItalic.ttf`, fontWeight: 900, fontStyle: 'italic' },
  ],
});

export default function ReportsPatchPdf({
  city,
  definition,
  report,
}) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Box sx={{ height: '90%' }}>
      <PDFViewer style={{ width: '100%', height: '100%' }}>
        <Document title={`${definition ? definition.name : 'loading'}.pdf`}>
          <Page>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Image
                src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_LOGO}`}
                style={{
                  marginTop: '5cm',
                  textAlign: 'center',
                  height: `${process.env.REACT_APP_LOGO_HEIGHT}cm`,
                  width: `${process.env.REACT_APP_LOGO_WIDTH}cm`,
                }}
              />
            </View>
            <View style={{ marginTop: '10px' }}>
              <Text style={{ fontSize: 36, fontFamily: 'Roboto', textAlign: 'center' }}>{definition ? definition.name : ''}</Text>
              <Text style={{ fontSize: 24, fontFamily: 'Roboto', textAlign: 'center' }}>{city ? `${city.status} ${city.name}` : ''}</Text>
              <Text style={{ fontSize: 24, fontFamily: 'Roboto', textAlign: 'center' }}>{report ? moment(report.date).format('DD.MM.YYYY') : ''}</Text>
            </View>
          </Page>
          <Page style={{ padding: '2cm' }}>
            <Text
              fixed
              style={{
                fontFamily: 'Roboto',
                fontSize: 10,
                position: 'absolute',
                top: '1cm',
                left: '1cm',
              }}
            >
              {report ? moment(report.date).format('DD.MM.YYYY') : ''}
            </Text>
            <Text
              fixed
              style={{
                fontFamily: 'Roboto',
                fontSize: 10,
                position: 'absolute',
                top: '1cm',
                left: 0,
                right: 0,
                textAlign: 'center',
              }}
            >
              {definition ? definition.name : ''}
            </Text>
            <Image
              fixed
              src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_LOGO}`}
              style={{
                height: `${process.env.REACT_APP_LOGO_HEIGHT / 5}cm`,
                width: `${process.env.REACT_APP_LOGO_WIDTH / 5}cm`,
                position: 'absolute',
                top: '0.5cm',
                right: '0.5cm',
              }}
            />
            <Text
              fixed
              style={{
                fontFamily: 'Roboto',
                fontSize: 10,
                position: 'absolute',
                bottom: '1cm',
                left: '1cm',
              }}
            >
              {city ? `${city.status} ${city.name}` : ''}
            </Text>
            <Text
              fixed
              style={{
                fontFamily: 'Roboto',
                fontSize: 10,
                position: 'absolute',
                bottom: '1cm',
                left: 0,
                right: 0,
                textAlign: 'center',
              }}
            >
              {process.env.REACT_APP_COPYRIGHT}
            </Text>
            <Text
              fixed
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              style={{
                fontFamily: 'Roboto',
                fontSize: 10,
                position: 'absolute',
                bottom: '1cm',
                right: '1cm',
              }}
            />
            {definition && definition.document && definition.document.map((view, pageindex) => (
              <View key={view.key}>
                { view.values && view.values.map((element, index) => {
                  let value;
                  if (report && report.document && report.document[view.key] && report.document[view.key][element.key]) {
                    if (element.type === 'BOOLEAN') {
                      value = report.document[view.key][element.key] ? local.true : localStorage.false;
                    } else if (element.type === 'DATE') {
                      value = moment(report.document[view.key][element.key]).format('DD.MM.YYYY');
                    } else if (element.type === 'NUMBER') {
                      value = report.document[view.key][element.key];
                    } else if (element.type === 'TEXT') {
                      value = report.document[view.key][element.key];
                    }
                  }
                  return (
                    <View key={element.key} wrap={false}>
                      { index === 0 && (
                        <Text style={{
                          marginTop: pageindex === 0 ? '0cm' : '1cm',
                          marginBottom: '5mm',
                          color: 'red',
                          fontSize: '20pt',
                          fontFamily: 'Roboto',
                        }}
                        >
                          {view.name}
                        </Text>
                      )}
                      <View>
                        <Text style={{
                          marginBottom: '5mm',
                          fontSize: '14pt',
                          fontFamily: 'Roboto',
                        }}
                        >
                          {element.text}
                        </Text>
                        {element.list && element.list.map((bullet) => (
                          <View key={bullet} style={{ display: 'flex', flexDirection: 'row' }}>
                            <Text style={{
                              height: '2mm',
                              width: '2mm',
                              backgroundColor: 'red',
                              borderRadius: '25px',
                              marginTop: '1.5mm',
                            }}
                            />
                            <Text style={{
                              fontSize: '12pt',
                              marginLeft: '3mm',
                              fontStyle: 'italic',
                              fontFamily: 'Roboto',
                            }}
                            >
                              {bullet}
                            </Text>
                          </View>
                        ))}
                      </View>
                      { element.type !== 'DROPDOWN' && element.type !== 'BOOLEAN' && (
                        <View style={{
                          padding: '5mm', border: '1px solid black', marginTop: element.list ? '5mm' : '0mm', marginBottom: '1cm',
                        }}
                        >
                          <Text style={{
                            fontSize: '12pt',
                            fontFamily: 'Roboto',
                            minHeight: element.type === 'TEXT' ? '3cm' : '0.5cm',
                          }}
                          >
                            {value || ''}
                          </Text>
                        </View>
                      )}
                      { element.type === 'DROPDOWN' && (
                        <View style={{ padding: '5mm', marginTop: element.list ? '5mm' : '0mm', marginBottom: '1cm' }}>
                          { element.options && element.options.map((option) => (
                            <View key={option.id} style={{ display: 'flex', flexDirection: 'row', marginBottom: '1mm' }}>
                              <Text style={{
                                height: '5mm',
                                width: '5mm',
                                backgroundColor: (
                                  report
                                  && report.document
                                  && report.document[view.key]
                                  && report.document[view.key][element.key]
                                  && report.document[view.key][element.key] === option.id.toString()
                                    ? 'red'
                                    : undefined
                                ),
                                border: '1px solid black',
                                borderRadius: '25px',
                              }}
                              />
                              <Text style={{
                                fontSize: '12pt',
                                marginLeft: '3mm',
                                fontFamily: 'Roboto',
                              }}
                              >
                                {option.name}
                              </Text>
                            </View>
                          ))}
                        </View>
                      )}
                      { element.type === 'BOOLEAN' && (
                        <View
                          style={{
                            padding: '5mm',
                            marginTop: element.list ? '5mm' : '0mm',
                            marginBottom: '1cm',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <Text style={{
                              height: '5mm',
                              width: '5mm',
                              backgroundColor: (
                                report
                                && report.document
                                && report.document[view.key]
                                && report.document[view.key][element.key]
                                  ? 'red'
                                  : undefined
                              ),
                              border: '1px solid black',
                            }}
                            />
                            <Text
                              style={{
                                fontSize: '12pt',
                                marginLeft: '3mm',
                                fontFamily: 'Roboto',
                              }}
                            >
                              {local.true}
                            </Text>
                          </View>
                          <View style={{ display: 'flex', flexDirection: 'row', marginLeft: '20px' }}>
                            <Text style={{
                              height: '5mm',
                              width: '5mm',
                              backgroundColor: (
                                report
                                && report.document
                                && report.document[view.key]
                                && report.document[view.key][element.key] === false
                                  ? 'red'
                                  : undefined
                              ),
                              border: '1px solid black',
                            }}
                            />
                            <Text
                              style={{
                                fontSize: '12pt',
                                marginLeft: '3mm',
                                fontFamily: 'Roboto',
                              }}
                            >
                              {local.false}
                            </Text>
                          </View>
                        </View>
                      )}
                    </View>
                  );
                })}
              </View>
            ))}
          </Page>
        </Document>
      </PDFViewer>
    </Box>
  );
}

ReportsPatchPdf.propTypes = {
  city: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
  }),
  definition: PropTypes.shape({
    name: PropTypes.string,
    document: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  report: PropTypes.shape({
    document: PropTypes.shape({}),
    date: PropTypes.string,
  }),
};

ReportsPatchPdf.defaultProps = {
  city: undefined,
  definition: undefined,
  report: undefined,
};

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext, SocketContext } from '../../../../../AppContext';
import Snackbar from '../../../../../components/Snackbar';
import Toolbar from './ReportdefinitionsTableToolbar';
import ColumnDefinition from './ReportdefinitionsTableColumnDefinition';
import RowsMapper from './ReportdefinitionsTableRowsMapper';
import localization from './ReportdefinitionsTable.local';

export default function ReportdefinitionsTable({
  reportdefinitions,
  onRowClick,
  onSetReportdefinitions,
  onStatusesClick,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => setColumns(ColumnDefinition(local)), []);
  useEffect(() => setRows(RowsMapper(local, reportdefinitions)), [reportdefinitions]);

  const onAddClick = () => {
    socket.emit('reportdefinitions.post', { name: local.newDefinition }, ({ error, payload }) => {
      setAlert(error || 200);
      if (!error) {
        onSetReportdefinitions([...reportdefinitions, payload]);
      }
    });
  };

  return (
    <Paper sx={{ height: '100%' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar }}
          componentsProps={{ toolbar: { local, onAddClick, onStatusesClick } }}
        />
      )}
    </Paper>
  );
}

ReportdefinitionsTable.propTypes = {
  reportdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func.isRequired,
  onSetReportdefinitions: PropTypes.func.isRequired,
  onStatusesClick: PropTypes.func.isRequired,
};

ReportdefinitionsTable.defaultProps = {
  reportdefinitions: undefined,
};

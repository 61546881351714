import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';

import ServicesPatch from './AgreementsPatch';
import ServicesTable from './ServicesTable';

export default function Services({
  cities,
  city,
  district,
  districts,
  servicecategories,
  services,
  servicestatuses,
  onSetServices,
}) {
  const navigate = useNavigate();

  return (
    <Paper sx={{ height: '100%' }}>
      <Routes>
        <Route
          path=":serviceid"
          element={(
            <ServicesPatch
              cities={cities}
              city={city}
              district={district}
              districts={districts}
              servicecategories={servicecategories}
              services={services}
              servicestatuses={servicestatuses}
              onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/agreements`)}
              onSetServices={onSetServices}
            />
          )}
        />
        <Route
          path="/"
          element={(
            <ServicesTable
              cities={cities}
              city={city}
              district={district}
              districts={districts}
              servicecategories={servicecategories}
              services={services}
              servicestatuses={servicestatuses}
              onRowClick={(id) => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/agreements/${id}`)}
              onSetServices={onSetServices}
            />
          )}
        />
      </Routes>
    </Paper>
  );
}

Services.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  servicecategories: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  servicestatuses: PropTypes.arrayOf(PropTypes.shape({})),
  onSetServices: PropTypes.func.isRequired,
};

Services.defaultProps = {
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
  servicecategories: undefined,
  services: undefined,
  servicestatuses: undefined,
};

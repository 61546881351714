import React, { useContext, useState, useEffect } from 'react';
import {
  useNavigate, useLocation, Routes, Route, Navigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';

import { UserContext } from '../../../AppContext';
import Copyright from '../../../components/Copyright';
import hasReadAccess from '../../../util/hasReadAccess';

import Agreements from './agreements/Agreements';
import Calendar from './calendar/Calendar';
import Cities from './cities/Cities';
import Districts from './districts/Districts';
import Elections from './elections/Elections';
import Electionresults from './electionresults/Electionresults';
import Info from './info/Info';
import Messages from './messages/Messages';
import Officials from './officials/Officials';
import Reports from './reports/Reports';
import Services from './services/Services';
import Swing from './swing/Swing';
import Timelines from './timelines/Timelines';
import Files from './files/Files';
import GemoMenu from './GemoMenu';
import GemoNavigator from './GemoNavigator';

export default function Gemo({
  agreementcategories,
  agreements,
  agreementstatuses,
  cities,
  citysettingdefinitions,
  citysettings,
  districts,
  elections,
  electiontypes,
  files,
  gridviews,
  messages,
  officialcategories,
  officials,
  officialtypes,
  parties,
  presidents,
  reportdefinitions,
  reports,
  reportstatuses,
  servicecategories,
  services,
  servicestatuses,
  timelines,
  users,
  onSetAgreements,
  onSetCitysettings,
  onSetElections,
  onSetFiles,
  onSetGridviews,
  onSetMessages,
  onSetReports,
  onSetServices,
}) {
  const user = useContext(UserContext);
  const [drawerToggle, toggleDrawer] = useState(false);
  const [city, setCity] = useState();
  const [district, setDistrict] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/');
  path.shift();
  const districtid = path.shift();
  const cityid = path.shift();
  const menu = path.join('/');

  useEffect(() => setDistrict(districts && districtid ? districts.find((item) => item.id === parseInt(districtid, 10)) : undefined), [districts, districtid]);
  useEffect(() => setCity(cities && cityid ? cities.find((item) => item.id === parseInt(cityid, 10)) : undefined), [cities, cityid]);

  return (
    <Box display="flex">
      <Hidden lgDown>
        <Drawer
          anchor="left"
          variant="permanent"
          sx={{
            width: 250,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 250,
              boxSizing: 'border-box',
            },
          }}
        >
          <Toolbar />
          <GemoNavigator cities={cities} districts={districts} />
        </Drawer>
      </Hidden>
      <Hidden xlUp>
        <SwipeableDrawer
          open={drawerToggle}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
          sx={{
            width: 250,
            '& .MuiDrawer-paper': { width: 250, boxSizing: 'border-box' },
          }}
        >
          <Toolbar />
          <GemoNavigator cities={cities} districts={districts} />
        </SwipeableDrawer>
      </Hidden>
      <Container sx={{ pt: 8, height: '100vh', display: 'table' }} maxWidth={false}>
        <Box display="flex" alignItems="center">
          <Hidden lgUp>
            <IconButton onClick={() => toggleDrawer(true)}><MenuIcon /></IconButton>
            <Divider orientation="vertical" flexItem sx={{ mt: 1, mr: 1, mb: 1 }} />
          </Hidden>
          <Breadcrumbs>
            <Button onClick={() => navigate(`/-/-/${menu}`)} size="small" color={district || city ? undefined : 'primary'}>{process.env.REACT_APP_NAME}</Button>
            { district && <Button onClick={() => navigate(`/${districtid}/-/${menu}`)} size="small" color={city ? undefined : 'primary'}>{district.name}</Button>}
            { city && <Button onClick={() => navigate(`/${districtid}/${cityid}/${menu}`)} size="small" color="primary">{city.name}</Button>}
          </Breadcrumbs>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <GemoMenu sx={{ display: 'table-row', height: 70 }} />
        <Box sx={{ display: 'table-row', height: '100%', mt: 1 }}>
          <Box sx={{ height: '100%', pt: 1 }}>
            <Routes>
              { hasReadAccess(user, districtid, cityid) && (
                <Route
                  path="agreements/*"
                  element={(
                    <Agreements
                      agreementcategories={agreementcategories}
                      agreements={agreements}
                      agreementstatuses={agreementstatuses}
                      cities={cities}
                      city={city}
                      district={district}
                      districts={districts}
                      users={users}
                      onSetAgreements={onSetAgreements}
                    />
                  )}
                />
              )}
              { !district && (
              <Route
                path="districts/*"
                element={(
                  <Districts
                    cities={cities}
                    districts={districts}
                    elections={elections}
                    officials={officials}
                  />
                )}
              />
              )}
              { !city && (
                <Route
                  path="cities/*"
                  element={(
                    <Cities
                      cities={cities}
                      citysettingdefinitions={citysettingdefinitions}
                      citysettings={citysettings}
                      districts={districts}
                      district={district}
                      elections={elections}
                      gridviews={gridviews}
                      officials={officials}
                      parties={parties}
                      onSetGridviews={onSetGridviews}
                    />
                  )}
                />
              )}
              <Route
                path="elections/*"
                element={(
                  <Elections
                    cities={cities}
                    city={city}
                    district={district}
                    districts={districts}
                    elections={elections}
                    electiontypes={electiontypes}
                    parties={parties}
                    presidents={presidents}
                  />
                )}
              />
              <Route
                path="electionresults/*"
                element={(
                  <Electionresults
                    cities={cities}
                    city={city}
                    district={district}
                    districts={districts}
                    elections={elections}
                    electiontypes={electiontypes}
                    presidents={presidents}
                    parties={parties}
                  />
                )}
              />
              <Route
                path="info/*"
                element={(
                  <Info
                    agreements={agreements}
                    agreementstatuses={agreementstatuses}
                    cities={cities}
                    city={city}
                    citysettingdefinitions={citysettingdefinitions}
                    citysettings={citysettings}
                    district={district}
                    districts={districts}
                    elections={elections}
                    officials={officials}
                    officialtypes={officialtypes}
                    parties={parties}
                    reportdefinitions={reportdefinitions}
                    reports={reports}
                    reportstatuses={reportstatuses}
                    onSetCitysettings={onSetCitysettings}
                    onSetElections={onSetElections}
                    users={users}
                  />
                )}
              />
              { hasReadAccess(user, districtid, cityid) && (
                <Route
                  path="messages/*"
                  element={(
                    <Messages
                      cities={cities}
                      city={city}
                      district={district}
                      districts={districts}
                      messages={messages}
                      users={users}
                      onSetMessages={onSetMessages}
                    />
                  )}
                />
              )}
              { hasReadAccess(user, districtid, cityid) && (
                <Route
                  path="officials/*"
                  element={(
                    <Officials
                      cities={cities}
                      city={city}
                      district={district}
                      districts={districts}
                      officialcategories={officialcategories}
                      officials={officials}
                      officialtypes={officialtypes}
                      parties={parties}
                    />
                  )}
                />
              )}
              { hasReadAccess(user, districtid, cityid) && (
                <Route
                  path="reports/*"
                  element={(
                    <Reports
                      cities={cities}
                      city={city}
                      citysettingdefinitions={citysettingdefinitions}
                      citysettings={citysettings}
                      district={district}
                      districts={districts}
                      reportdefinitions={reportdefinitions}
                      reports={reports}
                      reportstatuses={reportstatuses}
                      users={users}
                      onSetCitysettings={onSetCitysettings}
                      onSetReports={onSetReports}
                    />
                  )}
                />
              )}
              { hasReadAccess(user, districtid, cityid) && (
                <Route
                  path="services/*"
                  element={(
                    <Services
                      city={city}
                      cities={cities}
                      district={district}
                      districts={districts}
                      servicecategories={servicecategories}
                      services={services}
                      servicestatuses={servicestatuses}
                      onSetServices={onSetServices}
                    />
                  )}
                />
              )}
              <Route
                path="swing/*"
                element={(
                  <Swing
                    city={city}
                    district={district}
                  />
                )}
              />
              { hasReadAccess(user, districtid, cityid) && (
                <Route
                  path="timeline/*"
                  element={(
                    <Timelines
                      agreements={agreements}
                      cities={cities}
                      city={city}
                      citysettingdefinitions={citysettingdefinitions}
                      citysettings={citysettings}
                      district={district}
                      districts={districts}
                      files={files}
                      messages={messages}
                      reports={reports}
                      reportdefinitions={reportdefinitions}
                      services={services}
                      timelines={timelines}
                      users={users}
                    />
                  )}
                />
              )}
              { hasReadAccess(user, districtid, cityid) && (
                <Route
                  path="files/*"
                  element={(
                    <Files
                      cities={cities}
                      city={city}
                      district={district}
                      districts={districts}
                      files={files}
                      users={users}
                      onSetFiles={onSetFiles}
                    />
                  )}
                />
              )}
              { hasReadAccess(user, districtid, cityid) && (
                <Route
                  path="calendar/*"
                  element={(
                    <Calendar
                      agreementcategories={agreementcategories}
                      agreements={agreements}
                      agreementstatuses={agreementstatuses}
                      cities={cities}
                      city={city}
                      district={district}
                      districts={districts}
                      officials={officials}
                      users={users}
                      onSetAgreements={onSetAgreements}
                    />
                  )}
                />
              )}
              <Route path="*" element={<Navigate to="info" />} />
            </Routes>
          </Box>
        </Box>
        <Copyright sx={{ display: 'table-row', height: 36 }} />
      </Container>
    </Box>
  );
}

Gemo.propTypes = {
  agreementcategories: PropTypes.arrayOf(PropTypes.shape({})),
  agreements: PropTypes.arrayOf(PropTypes.shape({})),
  agreementstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  citysettings: PropTypes.arrayOf(PropTypes.shape({})),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  electiontypes: PropTypes.arrayOf(PropTypes.shape({})),
  files: PropTypes.arrayOf(PropTypes.shape({})),
  gridviews: PropTypes.arrayOf(PropTypes.shape({})),
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  officialcategories: PropTypes.arrayOf(PropTypes.shape({})),
  officials: PropTypes.arrayOf(PropTypes.shape({})),
  officialtypes: PropTypes.arrayOf(PropTypes.shape({})),
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  presidents: PropTypes.arrayOf(PropTypes.shape({})),
  reportdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  reports: PropTypes.arrayOf(PropTypes.shape({})),
  reportstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  servicecategories: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  servicestatuses: PropTypes.arrayOf(PropTypes.shape({})),
  timelines: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onSetAgreements: PropTypes.func.isRequired,
  onSetCitysettings: PropTypes.func.isRequired,
  onSetElections: PropTypes.func.isRequired,
  onSetFiles: PropTypes.func.isRequired,
  onSetGridviews: PropTypes.func.isRequired,
  onSetMessages: PropTypes.func.isRequired,
  onSetReports: PropTypes.func.isRequired,
  onSetServices: PropTypes.func.isRequired,
};

Gemo.defaultProps = {
  agreementcategories: undefined,
  agreements: undefined,
  agreementstatuses: undefined,
  cities: undefined,
  citysettingdefinitions: undefined,
  citysettings: undefined,
  districts: undefined,
  elections: undefined,
  electiontypes: undefined,
  files: undefined,
  gridviews: undefined,
  messages: undefined,
  officialcategories: undefined,
  officials: undefined,
  officialtypes: undefined,
  parties: undefined,
  presidents: undefined,
  reportdefinitions: undefined,
  reports: undefined,
  reportstatuses: undefined,
  servicecategories: undefined,
  services: undefined,
  servicestatuses: undefined,
  timelines: undefined,
  users: undefined,
};

import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import PresidentsPost from './PresidentsPost';
import PresidentsPatch from './PresidentsPatch';
import PresidentsTable from './PresidentsTable';

export default function Presidents({
  parties,
  presidents,
  onSetPresidents,
}) {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <PresidentsPost
            parties={parties}
            presidents={presidents}
            onBack={() => navigate('/settings/elections/presidents')}
            onSetPresidents={onSetPresidents}
          />
        )}
      />
      <Route
        path=":presidentid"
        element={(
          <PresidentsPatch
            parties={parties}
            presidents={presidents}
            onBack={() => navigate('/settings/elections/presidents')}
            onSetPresidents={onSetPresidents}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <PresidentsTable
            parties={parties}
            presidents={presidents}
            onRowClick={(id) => navigate(`/settings/elections/presidents/${id}`)}
            onAddClick={() => navigate('/settings/elections/presidents/post')}
            onTypesClick={() => navigate('/settings/elections/types')}
            onPartiesClick={() => navigate('/settings/elections/parties')}
          />
        )}
      />
    </Routes>
  );
}

Presidents.propTypes = {
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  presidents: PropTypes.arrayOf(PropTypes.shape({})),
  onSetPresidents: PropTypes.func.isRequired,
};

Presidents.defaultProps = {
  parties: undefined,
  presidents: undefined,
};

export default function FilesTableRowsMapper(
  local,
  cities,
  city,
  district,
  districts,
  files,
  users,
) {
  let rows;
  if (files && users && cities && districts) {
    rows = files
      .filter((file) => {
        const isDistrict = !district || district.id === file.district;
        const isCity = !city || city.id === file.city;
        return isDistrict && isCity;
      })
      .map((file) => {
        const itemDistrict = district || districts.find((item) => item.id === file.district);
        const itemCity = city || cities.find((item) => item.id === file.city);
        const itemCreator = users.find((item) => item.id === file.creator);
        const itemEditor = users.find((item) => item.id === file.editor);
        return {
          id: file.id,
          district: itemDistrict ? itemDistrict.name : '',
          city: itemCity ? itemCity.name : '',
          name: file.name,
          type: file.type,
          size: file.size,
          creator: itemCreator,
          createdAt: file.createdAt,
          editor: itemEditor,
          updatedAt: file.updatedAt,
          description: file.description,
        };
      });
  }
  return rows;
}

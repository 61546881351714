import moment from 'moment';

import { sortByDate } from '../../../../util/sort';
import { filterByCity, filterByCityAndElectiontypeCouncil } from '../../../../util/filter';

function mapAgreementsToStatus(city, agreements, agreementstatuses) {
  const cityList = agreements.filter((item) => filterByCity(item, city) && item.active);
  const result = agreementstatuses
    .map((status) => ({ name: status.name, length: cityList.filter((item) => item.status === status.id).length }))
    .filter((item) => item.length > 0);
  return result.length > 0 ? result : undefined;
}

const OFFICIALTYPES = {
  MAYOR: 1,
  VICEMAYOR: 2,
  FRACTION: 3,
  CITYCOUNCIL: 4,
  COUNCILBOARD: 5,
  COUNCIL: 6,
  ALTERNATE: 7,
  CASHIER: 35,
};

function mapParty(item, parties, group) {
  const itemParty = parties.find(({ id }) => id === item.party);
  return group && itemParty.parent ? parties.find(({ id }) => id === itemParty.parent) : itemParty;
}

function mapOfficials(city, officials, group, parties) {
  const cityOfficials = officials.filter((official) => filterByCity(official, city));
  const filterTypeAndMapParty = (type) => cityOfficials
    .filter((official) => official.type === type)
    .map((official) => ({ ...official, party: mapParty(official, parties, group) }));

  const alternates = filterTypeAndMapParty(OFFICIALTYPES.ALTERNATE);
  const cashiers = filterTypeAndMapParty(OFFICIALTYPES.CASHIER);
  const cityCouncils = filterTypeAndMapParty(OFFICIALTYPES.CITYCOUNCIL);
  const councils = filterTypeAndMapParty(OFFICIALTYPES.COUNCIL);
  const councilsBoards = filterTypeAndMapParty(OFFICIALTYPES.COUNCILBOARD);
  const fractions = filterTypeAndMapParty(OFFICIALTYPES.FRACTION);
  const mayors = filterTypeAndMapParty(OFFICIALTYPES.MAYOR);
  const vicemayors = filterTypeAndMapParty(OFFICIALTYPES.VICEMAYOR);

  const mayor = mayors.length > 0 ? mayors[0] : undefined;
  if (mayor) {
    const isCouncil = councils.find((official) => official.personid === mayor.personid);
    const isCouncilBoard = councilsBoards.find((official) => official.personid === mayor.personid);
    mayor.peoplesmayor = !isCouncilBoard && !isCouncil;
  }

  return [alternates, cashiers, cityCouncils, councils, councilsBoards, fractions, mayor, vicemayors];
}

function mapElectionresult(city, electionresults, group, parties) {
  let absolute;
  let councilParties;
  let electiondate;
  let mandates = 0;

  const cityCouncilElectionresults = electionresults.filter((item) => filterByCityAndElectiontypeCouncil(item, city)).sort(sortByDate);
  if (cityCouncilElectionresults[0]) {
    electiondate = moment(cityCouncilElectionresults[0].date).format('DD.MM.YYYY');
    cityCouncilElectionresults[0].parties.filter((item) => item.mandates).forEach((item) => {
      const party = mapParty(item, parties, group);
      mandates += item.mandates;
      if (councilParties && party) {
        const councilPartiesIndex = councilParties.findIndex((councilPartiesItem) => councilPartiesItem.id === party.id);
        if (councilPartiesIndex >= 0) {
          councilParties[councilPartiesIndex].mandates += item.mandates;
        } else {
          councilParties.push({ ...party, mandates: item.mandates, coalition: item.coalition });
        }
      } else if (party) {
        councilParties = [{ ...party, mandates: item.mandates, coalition: item.coalition }];
      }
    });
  }
  if (councilParties) {
    councilParties.forEach((item) => {
      if (item.mandates / mandates >= 0.5) {
        absolute = item;
      }
    });
  }
  return [cityCouncilElectionresults[0], absolute, councilParties, electiondate, mandates];
}

function mapReportsToDefinitionAndStatus(city, reportdefinitions, reports, reportstatuses) {
  const cityList = reports.filter((item) => filterByCity(item, city));
  const definitions = reportdefinitions
    .filter((item) => item.active).map((definition) => {
      const result = { name: definition.name, total: 0, status: [] };
      reportstatuses.forEach((status) => {
        const { length } = cityList.filter((item) => item.active && item.definition === definition.id && item.status === status.id);
        if (length > 0) {
          result.total += length;
          result.status.push({ name: status.name, length });
        }
      });
      return result;
    }).filter((item) => item.total > 0);
  return definitions.length > 0 ? definitions : undefined;
}

export default function infoCityMapping(
  agreements,
  agreementstatuses,
  city,
  district,
  electionresults,
  group,
  officials,
  parties,
  reportdefinitions,
  reports,
  reportstatuses,
) {
  const cityAgreements = mapAgreementsToStatus(city, agreements, agreementstatuses);
  const [alternates, cashiers, cityCouncils, councils, councilsBoards, fractions, mayor, vicemayors] = mapOfficials(city, officials, group, parties);
  const [councilElection, absolute, councilParties, electiondate, mandates] = mapElectionresult(city, electionresults, group, parties);
  const cityReports = mapReportsToDefinitionAndStatus(city, reportdefinitions, reports, reportstatuses);

  return {
    ...city,
    absolute,
    agreements: cityAgreements,
    alternates,
    cashiers,
    cityCouncils,
    councils,
    councilsBoards,
    councilElection,
    councilParties,
    electiondate,
    fractions,
    mandates,
    mayor,
    reports: cityReports,
    vicemayors,
  };
}

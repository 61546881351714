import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import {
  SocketContext,
  LocalizationContext,
  UserContext,
} from './AppContext';
import dark from './themes/gemo-dark';
import light from './themes/gemo-light';
import Unauthenticated from './pages/unauthenticated/Unauthenticated';
import Authenticated from './pages/authenticated/Authenticated';

const unauthenticatedSocket = io(process.env.REACT_APP_SOCKET_URL);
const themes = {
  light, dark,
};
export default function AppContainer() {
  const [language] = useState(localStorage.getItem('language') || 'de');
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : undefined);
  const [socket, setSocket] = useState();
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const navigate = useNavigate();

  useEffect(() => {
    if (token && !user) {
      unauthenticatedSocket.emit('verifySessionToken', token, ({ error, payload }) => {
        if (error) {
          localStorage.removeItem('user');
        } else {
          sessionStorage.setItem('user', JSON.stringify(payload));
          setUser(payload);
        }
      });
    }
  }, [token]);

  useEffect(() => {
    if (!token || !user) {
      unauthenticatedSocket.connect();
      setSocket();
      return () => {
        unauthenticatedSocket.disconnect();
      };
    }
    const newAuthenticatedSocket = io(`${process.env.REACT_APP_SOCKET_URL}/authenticated`, { auth: { token } });
    setSocket(newAuthenticatedSocket);
    return () => {
      newAuthenticatedSocket.disconnect();
    };
  }, [user, token]);

  const onLogin = (payload) => {
    localStorage.setItem('token', payload.token);
    sessionStorage.setItem('user', JSON.stringify(payload.user));
    setUser(payload.user);
    setToken(payload.token);
  };

  const onLogout = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('user');
    setToken();
    setUser();
    navigate('/');
  };

  const onUpdateUser = (patch) => {
    setUser(patch);
    sessionStorage.setItem('user', JSON.stringify(patch));
  };

  const onThemeChange = () => {
    localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light');
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <ThemeProvider theme={themes[theme]}>
      <CssBaseline />
      <LocalizationContext.Provider value={language}>
        <UserContext.Provider value={user}>
          { socket && user ? (
            <SocketContext.Provider value={socket}>
              <Authenticated
                theme={theme}
                onUpdateUser={onUpdateUser}
                onLogout={onLogout}
                onThemeChange={onThemeChange}
              />
            </SocketContext.Provider>
          ) : (
            <SocketContext.Provider value={unauthenticatedSocket}>
              <Unauthenticated initialised onLogin={onLogin} />
            </SocketContext.Provider>
          )}
        </UserContext.Provider>
      </LocalizationContext.Provider>
    </ThemeProvider>
  );
}

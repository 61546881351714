import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function Label({ label, value, sx }) {
  return (
    <Box sx={sx}>
      <Typography variant="caption" sx={{ color: 'primary.main' }}>{label}</Typography>
      <Typography>{value}</Typography>
    </Box>
  );
}

Label.propTypes = {
  sx: PropTypes.shape({}),
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Label.defaultProps = {
  sx: undefined,
  label: '',
  value: '',
};

export function LabelOfficial({
  label, name, party, birthdate, color,
}) {
  return (
    <Box>
      <Typography variant="caption" sx={{ color: 'primary.main' }}>{label}</Typography>
      <Grid container>
        <Grid item xs={3}>
          <Box display="flex">
            <Box
              sx={{
                backgroundColor: color, width: 20, height: 20, mr: 1, borderRadius: 25,
              }}
            />
            <Typography>{party}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography>{name}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{birthdate}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

LabelOfficial.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  party: PropTypes.string,
  birthdate: PropTypes.string,
  color: PropTypes.string,
};

LabelOfficial.defaultProps = {
  label: '',
  name: '',
  party: '',
  birthdate: '',
  color: 'grey',
};

export function LabelPie({ label, value, color }) {
  return (
    <Box>
      <Typography variant="caption" sx={{ color: 'primary.main' }}>{label}</Typography>
      <Box display="flex">
        <Box
          sx={{
            backgroundColor: color, width: 20, height: 20, mr: 1, borderRadius: 25,
          }}
        />
        <Typography>{value}</Typography>
      </Box>
    </Box>
  );
}

LabelPie.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

LabelPie.defaultProps = {
  label: '',
  value: '',
  color: 'grey',
};

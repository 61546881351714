import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import localization from './ReportsPost.local';

export default function ReportsPost({
  city,
  reportdefinitions,
  reports,
  onBack,
  onSetReports,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [reportdefinitionsActive, setReportdefinitionsActive] = useState(null);
  const [definition, setDefinition] = useState(null);
  const [alert, setAlert] = useState();

  useEffect(
    () => setReportdefinitionsActive(reportdefinitions ? reportdefinitions.filter((item) => item.active).sort((a, b) => b.name - a.name) : null),
    [reportdefinitions],
  );
  useEffect(() => {
    if (reportdefinitionsActive && !definition) {
      setDefinition(reportdefinitionsActive[0]);
    }
  }, [reportdefinitionsActive, definition]);

  const acknowledge = ({ error, payload }) => {
    if (error) {
      setAlert(error);
    } else {
      onSetReports(reports ? [...reports, payload] : [payload]);
      onBack();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const report = {
      city: city.id,
      district: city.district,
      definition: definition.id,
      date: moment().format('YYYY-MM-DD'),
    };
    socket.emit('reports.post', report, acknowledge);
  };

  return (
    <Paper component="form" onSubmit={onSubmit} sx={{ height: '100%', p: 5 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Typography variant="h4">{local.title}</Typography>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Autocomplete
        required
        disableClearable
        value={definition}
        options={reportdefinitionsActive}
        onChange={(e, selected) => setDefinition(selected)}
        getOptionLabel={((option) => option.name)}
        renderInput={(params) => (
          <TextField
            required
            fullWidth
              // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            color="secondary"
            margin="dense"
            label={local.definition}
          />
        )}
      />
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
        <Fab
          variant="extended"
          color="primary"
          type="submit"
          size="small"
          sx={{ justifyContent: 'flex-start' }}
        >
          <SaveIcon sx={{ mr: 1 }} />
          <Typography>{local.submit}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

ReportsPost.propTypes = {
  city: PropTypes.shape({
    id: PropTypes.number,
    district: PropTypes.number,
  }),
  reportdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  reports: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSetReports: PropTypes.func.isRequired,
};

ReportsPost.defaultProps = {
  reportdefinitions: undefined,
  reports: undefined,
  city: undefined,
};
